//#region IMPORT

import { environment } from "src/environments/environment";

//#endregion


//#region CLASS

export class WebAddressConstant
{
	//#region IPADDRESS

	static STRING_WEBADDRESS_WEBSITE_IPADRESS_BRINS: string = "www.brins.co.id";

	//#endregion


	//#region URL API

	static STRING_URL_USER_API: string = environment.URL_USER_API;
	static STRING_URL_PRODUCT_API: string = environment.URL_PRODUCT_API;
	static STRING_URL_POLICY_API: string = environment.URL_POLICY_API;
	static STRING_URL_BROADCAST_API: string = environment.URL_BROADCAST_API;
	static STRING_URL_CLAIM_API: string = environment.URL_CLAIM_API;

	//#endregion


	//#region URL WEBSITE SOCKET

	/*
	URL_USER_WEBSITESOCKET: String = BuildConfig.SCHEMA_WEBSITESOCKET + BuildConfig.HOST_USER_API + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + BuildConfig.PORT_USER;
	URLT_PRODUCT_WEBSITESOCKET: String = BuildConfig.SCHEMA_WEBSITESOCKET + BuildConfig.HOST_PRODUCT_API + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + BuildConfig.PORT_PRODUCT;
	URL_POLICY_WEBSITESOCKET: String = BuildConfig.SCHEMA_WEBSITESOCKET + BuildConfig.HOST_POLICY_API + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + BuildConfig.PORT_POLICY;
	*/

	//#endregion


	//#region HANDSHAKE SERVICE

	static STRING_PATH_CONTROLLER_HANDSHAKE: string = "/Handshake";

	static STRING_URL_HANDSHAKE_GENERATETOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/generateToken`;
	static STRING_URL_HANDSHAKE_REFRESHTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/refreshToken`;
	static STRING_URL_HANDSHAKE_SELECTUSERBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/selectUserByToken`;
	static STRING_URL_HANDSHAKE_REFRESHTOKENFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/refreshTokenForCustomer`;

	//#endregion


	//#region BRANCH CONTROLLER

	static STRING_PATH_CONTROLLER_BRANCH: string = "/Branch";

	static STRING_URL_BRANCH_SELECTBRANCH: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranch`;
	static STRING_URL_BRANCH_SELECTBRANCHFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranchForCustomer`;
	static STRING_URL_BRANCH_SELECTBRANCHBYLOCATIONFORWEBSITECUSTOMER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranchByLocationForWebsiteCustomer`;

	//#endregion


	//#region DIVISION

	static STRING_PATH_CONTROLLER_DIVISION: string = "/Division";

	static STRING_URL_DIVISION_SELECTDIVISION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DIVISION}/selectDivision`;

	//#endregion


	//#region CURRENCY

	static STRING_PATH_CONTROLLER_CURRENCY: string = "/Currency";

	static STRING_URL_CURRENCY_SELECTCURRENCY: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CURRENCY}/selectCurrency`;

	//#endregion


	//#region PRODUCT GENERAL CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTGENERAL: string = "/ProductGeneral";

	static STRING_URL_PRODUCTGENERAL_SELECTPRODUCTGENERALREFERANCENUMBERBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTGENERAL}/selectProductGeneralReferenceNumberByToken`;

	//#endregion


	//#region PRODUCT ASRI CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTASRI: string = "/ProductAsri";

	static STRING_URL_PRODUCTASRI_SELECTCALCULATEPREMIUMASRIFORSELFSERVICEMONITORING: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/calculatePremiumForSelfServiceMonitoring`;
	static STRING_URL_PRODUCTASRI_INSERTPRODUCTASRIFORSELFSERVICEMONITORING: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/insertProductAsriForSelfServiceMonitoring`;
	static STRING_URL_PRODUCTASRI_UPDATEINSUREDASRIFORSELFSERVICEMONITORING: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/updateInsuredForSelfServiceMonitoring`;
	static STRING_URL_PRODUCTASRI_UPLOADPRODUCTASRIFORSELFSERVICEMONITORING: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/uploadProductAsriForSelfServiceMonitoring`;
	static STRING_URL_PRODUCTASRI_SAVEPRODUCTASRIFORSELFSERVICEMONITORING: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/saveProductAsriForSelfServiceMonitoring`;
	static STRING_URL_PRODUCTASRI_SENDSPPABYEMAILASRIFORSELFSERVICEMONITORING: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/sendSPPAByEmailForSelfServiceMonitoring`;
	static STRING_URL_PRODUCTASRI_CONFIRMPAYMENTASRIFORSELFSERVICEMONITORING: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/confirmPaymentForSelfServiceMonitoring`;
	static STRING_URL_PRODUCTASRI_SELECTPRODUCTBYTOKENFORSELFSERVICEMONITORING: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/selectProductByTokenForSelfServiceMonitoring`;
	static STRING_URL_PRODUCTASRI_GENERAPAYMENTBRIVA: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/generatePaymentBRIVAForSelfServiceMonitoring`;
	static STRING_URL_PRODUCTASRI_SHAREPAYMENTFORSELFSERVICEMONITORING: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/sharePaymentForSelfServiceMonitoring`;
	static STRING_URL_PRODUCTASRI_ASRICONFIRMPAYMENT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/confirmPaymentForSelfServiceMonitoring`;

	//#endregion


	//#region PRODUCT OTO CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTOTO: string = "/ProductOto";

	static STRING_URL_PRODUCTOTO_SELECTCALCULATEPREMIUMOTOFORSELFSERVICEMONITORING: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/calculatePremiumForSelfServiceMonitoring`;
	static STRING_URL_PRODUCTOTO_INSERTPRODUCTOTOFORSELFSERVICEMONITORING: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/insertProductOtoForSelfServiceMonitoring`;
	static STRING_URL_PRODUCTOTO_UPDATEINSUREDOTOFORSELFSERVICEMONITORING: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/updateInsuredForSelfServiceMonitoring`;
	static STRING_URL_PRODUCTOTO_UPLOADPRODUCTOTOFORSELFSERVICEMONITORING: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/uploadProductOtoForSelfServiceMonitoring`;
	static STRING_URL_PRODUCTOTO_SAVEPRODUCTOTOFORSELFSERVICEMONITORING: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/saveProductOtoForSelfServiceMonitoring`;
	static STRING_URL_PRODUCTOTO_SENDSPPABYEMAILOTOFORSELFSERVICEMONITORING: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/sendSPPAByEmailForSelfServiceMonitoring`;
	static STRING_URL_PRODUCTOTO_CONFIRMPAYMENTOTOFORSELFSERVICEMONITORING: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/confirmPaymentForSelfServiceMonitoring`;
	static STRING_URL_PRODUCTOTO_GENERATEPAYMENTFORBRIVA: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/generatePaymentBRIVAForSelfServiceMonitoring`;
	static STRING_URL_PRODUCTOTO_SHAREPAYMENTFORSELFSERVICEMONITORING: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/sharePaymentForSelfServiceMonitoring`;
	static STRING_URL_PRODUCTOTO_SELECTPRODUCTBYTOKENFORSELFSERVICEMONITORING: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/selectProductByTokenForSelfServiceMonitoring`;

	//#endregion


	//#region PRODUCT BICYCLE CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTBICYCLE: string = "/ProductBicycle";

	static STRING_URL_PRODUCTBICYCLE_SELECTCALCULATEPREMIUMBICYCLEFORSELFSERVICEMONITORING: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTBICYCLE}/calculatePremiumForSelfServiceMonitoring`;
	static STRING_URL_PRODUCTBICYCLE_INSERTPRODUCTBICYCLEFORSELFSERVICEMONITORING: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTBICYCLE}/insertProductBicycleForSelfServiceMonitoring`;
	static STRING_URL_PRODUCTBICYCLE_UPDATEINSUREDBICYCLEFORSELFSERVICEMONITORING: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTBICYCLE}/updateInsuredForSelfServiceMonitoring`;
	static STRING_URL_PRODUCTBICYCLE_UPLOADPRODUCTBICYCLEFORSELFSERVICEMONITORING: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTBICYCLE}/uploadProductBicycleForSelfServiceMonitoring`;
	static STRING_URL_PRODUCTBICYCLE_SAVEPRODUCTBICYCLEFORSELFSERVICEMONITORING: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTBICYCLE}/saveProductBicycleForSelfServiceMonitoring`;
	static STRING_URL_PRODUCTBICYCLE_SENDSPPABYEMAILBICYCLEFORSELFSERVICEMONITORING: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTBICYCLE}/sendSPPAByEmailForSelfServiceMonitoring`;
	static STRING_URL_PRODUCTBICYCLE_CONFIRMPAYMENTBICYCLEFORSELFSERVICEMONITORING: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTBICYCLE}/confirmPaymentForSelfServiceMonitoring`;
	static STRING_URL_PRODUCTBICYCLE_GENERATEPAYMENTFORBRIVA: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTBICYCLE}/generatePaymentBRIVAForSelfServiceMonitoring`;
	static STRING_URL_PRODUCTBICYCLE_SHAREPAYMENTFORSELFSERVICEMONITORING: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTBICYCLE}/sharePaymentForSelfServiceMonitoring`;
	static STRING_URL_PRODUCTBICYCLE_SELECTPRODUCTBYTOKENFORSELFSERVICEMONITORING: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTBICYCLE}/selectProductByTokenForSelfServiceMonitoring`;

	//#endregion


	//#region PRODUCT PERSONALACCIDENT CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT: string = "/ProductPersonalAccident";

	static STRING_URL_PRODUCTPERSONALACCIDENT_SELECTCALCULATEPREMIUMPERSONALACCIDENTFORSELFSERVICEMONITORING: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT}/calculatePremiumForSelfServiceMonitoring`;
	static STRING_URL_PRODUCTPERSONALACCIDENT_INSERTPRODUCTPERSONALACCIDENTFORSELFSERVICEMONITORING: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT}/insertProductPersonalAccidentForSelfServiceMonitoring`;
	static STRING_URL_PRODUCTPERSONALACCIDENT_UPDATEINSUREDPERSONALACCIDENTFORSELFSERVICEMONITORING: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT}/updateInsuredForSelfServiceMonitoring`;
	static STRING_URL_PRODUCTPERSONALACCIDENT_UPDATEBENEFICIARIESPERSONALACCIDENTFORSELFSERVICEMONITORING: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT}/updateBeneficiariesForSelfServiceMonitoring`;
	static STRING_URL_PRODUCTPERSONALACCIDENT_UPDATEQUESTIONNAIREPERSONALACCIDENTFORSELFSERVICEMONITORING: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT}/updateQuestionnaireForSelfServiceMonitoring`;
	static STRING_URL_PRODUCTPERSONALACCIDENT_SAVEPRODUCTPERSONALACCIDENTFORSELFSERVICEMONITORING: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT}/saveProductPersonalAccidentForSelfServiceMonitoring`;
	static STRING_URL_PRODUCTPERSONALACCIDENT_SENDSPPABYEMAILPERSONALACCIDENTFORSELFSERVICEMONITORING: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT}/sendSPPAByEmailForSelfServiceMonitoring`;
	static STRING_URL_PRODUCTPERSONALACCIDENT_CONFIRMPAYMENTPERSONALACCIDENTFORSELFSERVICEMONITORING: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT}/confirmPaymentForSelfServiceMonitoring`;
	static STRING_URL_PRODUCTPERSONALACCIDENT_GENERATEPAYMENTFORBRIVA: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT}/generatePaymentBRIVAForSelfServiceMonitoring`;
	static STRING_URL_PRODUCTPERSONALACCIDENT_SHAREPAYMENTFORSELFSERVICEMONITORING: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT}/sharePaymentForSelfServiceMonitoring`;
	static STRING_URL_PRODUCTPERSONALACCIDENT_SELECTPRODUCTBYTOKENFORSELFSERVICEMONITORING: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT}/selectProductByTokenForSelfServiceMonitoring`;

	//#endregion


	//#region VEHICLE BRAND CONTROLLER

	static STRING_PATH_CONTROLLER_VEHICLEBRAND: string = "/VehicleBrand";

	static STRING_URL_VEHICLEBRAND_SELECTVEHICLEBRANDFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLEBRAND}/selectVehicleBrandForCustomer`;

	//#endregion


	//#region VEHICLE MODEL CONTROLLER

	static STRING_PATH_CONTROLLER_VEHICLEMODEL: string = "/VehicleModel";

	static STRING_URL_VEHICLEMODEL_SELECTVEHICLEMODELBYVEHICLEBRANDFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLEMODEL}/selectVehicleModelByVehicleBrandForCustomer`;

	//#endregion


	//#region VEHICLE LOCATION CONTROLLER

	static STRING_PATH_CONTROLLER_VEHICLELOCATION: string = "/VehicleLocation";

	static STRING_URL_VEHICLELOCATION_SELECTVEHICLELOCATIONFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLELOCATION}/selectVehicleLocationForCustomer`;
	static STRING_URL_VEHICLELOCATION_SELECTVEHICLELOCATION: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLELOCATION}/selectVehicleLocation`;

	//#endregion


	//#region VEHICLE MANUFACTURE YEAR  CONTROLLER

	static STRING_PATH_CONTROLLER_VEHICLEMANUFACTUREYEAR: string = "/VehicleManufactureYear";

	static STRING_URL_VEHICLEMANUFACTUREYEAR_SELECTVEHICLEMANUFACTUREYEARFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLEMANUFACTUREYEAR}/selectVehicleManufactureYearForCustomer`;

	//#endregion


	//#region VEHICLE REGION CONTROLLER

	static STRING_PATH_CONTROLLER_VEHICLEREGION: string = "/VehicleRegion";

	static STRING_URL_VEHICLEREGION_SELECTVEHICLEREGIONFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLEREGION}/selectVehicleRegionForCustomer`;

	//#endregion


	//#region VEHICLE SUBMODEL CONTROLLER

	static STRING_PATH_CONTROLLER_VEHICLSUBMODEL: string = "/VehicleSubModel";

	static STRING_URL_VEHICLESUBMODEL_SELECTVEHICLESUBMODELFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLSUBMODEL}/selectVehicleSubModelByVehicleModelForCustomer`;

	//#endregion


	//#region VEHICLE COLOR CONTROLLER

	static STRING_PATH_CONTROLLER_VEHICLCOLOR: string = "/VehicleColor";

	static STRING_URL_VEHICLECOLOR_SELECTVEHICLECOLORFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLCOLOR}/selectVehicleColorForCustomer`;


	//#region VEHICLE FUNCTION CONTROLLER

	static STRING_PATH_CONTROLLER_VEHICLEFUNCTION: string = "/VehicleFunction";

	static STRING_URL_VEHICLEFUNCTION_SELECTVEHICLEFUNCTIONFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLEFUNCTION}/selectVehicleFunctionForCustomer`;

	//#endregion


	//#region POSTAL

	static STRING_PATH_CONTROLLER_POSTAL: string = "/Postal";

	static STRING_URL_POSTAL_SELECTLOCATIONBYPOSTALCODE: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTAL}/selectLocationByPostalCodeForCustomer`;

	//#endregion


	//#region DISCOUNT

	static STRING_PATH_CONTROLLER_DISCOUNT: string = "/Discount";
	static STRING_URL_DISCOUNT_VERIFYDISCOUNTCODE: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DISCOUNT}/verifyDiscountCode`;

	//#endregion


	//#region OCCUPATION CONTROLLER

	static STRING_PATH_CONTROLLER_OCCUPATION: string = "/Occupation";

	static STRING_URL_OCCUPATION_SELECTOCCUPATION: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OCCUPATION}/selectOccupationForCustomer`;

	//#endregion


	//#region LICENSE PLATE PREFIX

	static STRING_PATH_CONTROLLER_LICENSEPLATEPREFIX: string = "/LicensePlatePrefix";
	static STRING_URL_LICENSEPLATEPREFIX_SELECTLICENSEPLATEPREFIXFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LICENSEPLATEPREFIX}/selectLicensePlatePrefixPublic`;

	//#endregion


	//#region RELATIONSHIP CONTROLLER

	static STRING_PATH_CONTROLLER_RELATIONSHIP: string = "/Relationship";

	static STRING_URL_RELATIONSHIP_SELECTRELATIONSHIPPUBLIC: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RELATIONSHIP}/selectRelationshipForCustomerPublic`;

	//#endregion


	//#region POLICY GENERAL CONTROLLER

	static STRING_PATH_CONTROLLER_POLICYGENERAL: string = "/PolicyGeneral";

	static STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYPOLICYNUMBER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYGENERAL}/selectPolicyGeneralByPolicyNumber`;
	static STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYATTRIBUTESFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYGENERAL}/selectPolicyGeneralByAttributesForCustomer`;
	static STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYLICENSEPLATE: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYGENERAL}/selectPolicyGeneralByLicensePlate`;
	static STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYPOLICYHOLDERATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYGENERAL}/selectPolicyGeneralByPolicyholderAttributes`;
	static STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYPROFILEID: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYGENERAL}/selectPolicyGeneralByProfileID`;
	static STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYGENERAL}/selectPolicyGeneralByAttributes`;
	static STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYACCEPTANCENUMBER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYGENERAL}/selectPolicyGeneralByAcceptanceNumber`;

	//#endregion


	//#region BRANCH COVERAGE CONTROLLER

	static STRING_PATH_CONTROLLER_BRANCHCOVERAGE: string = "/BranchCoverage";

	static STRING_URL_BRANCHCOVERAGE_SELECTBRANCHCOVERAGE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCHCOVERAGE}/selectBranchCoverageForWebsiteCustomer`;

	//#endregion


	//#region CLAIM PROPOSAL CONTROLLER

	static STRING_PATH_CONTROLLER_CLAIM_PROPOSAL: string = "/ClaimProposal";

	static STRING_URL_CLAIM_PROPOSAL_INSERTCLAIMPROPOSALFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/insertClaimProposalForCustomer`;
	static STRING_URL_CLAIM_PROPOSAL_INSERTCLAIMPROPOSALNONMOTORVEHICLEFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/insertClaimProposalNonMotorVehicleForCustomer`;
	static STRING_URL_CLAIM_PROPOSAL_UPLOADCLAIMPROPOSALFORCUSTOMERPUBLIC: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/uploadClaimProposalForWebsiteCustomerPublic`;

	//#endregion
}

//#endregion
