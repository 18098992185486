//#region CONSTANT

export class StringConstant
{
	//#region REGULUAR_EXPRESSION

	static STRING_REGULAREXPRESSION_PERSONNAME: string = "^[A-Za-z\\s]{1,}[\\.]{0," + "1}[A-Za-z\\s]{0,}$";
	static STRING_REGULAREXPRESSION_ALPHABETWITHOUTEMOJI: string = "^\\S[a-zA-Z\\s]*$";
	static STRING_REGULAREXPRESSION_ALPHABET: string = "^[A-Za-z]";
	static STRING_REGULAREXPRESSION_NUMERIC: string = "^[0-9]*$";
	static STRING_REGULAREXPRESSION_ALPHABETNUMERIC: string = "^[a-zA-Z0-9]+$";
	static STRING_REGULAREXPRESSION_ALPHABETNUMERICWITHSPACE: string = "^[a-zA-Z0-9]";
	static STRING_REGULAREXPRESSION_EMAIL: string = "^[a-za-zA-Z0-9._%+-]+@[a-za-zA-Z0-9.-]+\\.[a-za-zA-Z]{2,4}$";
	static STRING_REGULAREXPRESSION_MOBILEPHONEWITHPLUS: string = "^[+]?[0-9]{10,15}$";
	static STRING_REGULAREXPRESSION_DATEISO8601: string = "[0-9]{4}-[0-9]{2}-[0-9]{1,2}";
	static STRING_REGULAREXPRESSION_CURRENCY: string = "[^0-9]";
	static STRING_REGULAREXPRESSION_HTMLTAG: string = "<[^>]+>";
	static STRING_REGULAREXPRESSION_ADDRESS: string = "^\\S[a-zA-Z0-9.,/\\s]+$";
	static STRING_REGULAREXPRESSION_EMAILFORBRIMO: string = "^[a-za-zA-Z0-9._%+-]+@[a-za-zA-Z0-9.-]+\\.[a-za-zA-Z]{2,4}$";
	static STRING_REGULAREXPRESSION_ALPHABETNUMERICWITHOUTEMOJI: string = "^\\S[a-zA-Z0-9\\s]+$";
	static STRING_REGULAREXPRESSION_ALPHABETNUMERICWITHHYPEN: string = "^[a-zA-Z0-9-]+$";
	static STRING_REGULAREXPRESSION_ALPHABETNUMERICWITHOUTTAGEMOJI: string = "^\\S[a-zA-Z0-9.,/\\s]+$";
	static STRING_REGULAREXPRESSION_ALPHABETNUMERICFORINVOICE: string = "^[a-zA-Z0-9-./]+$";
	static STRING_REGULAREXPRESSION_SPACE: RegExp = /\s/g;
	static STRING_REGULAREXPRESSION_ALPHABET_WITHSPACE: string = "^[a-zA-Z ]+$";

	//#endregion


	//#region LANGUAGE_CULTURE

	static STRING_CULTURE_INDONESIAN: string = "id-ID";
	static STRING_CULTURE_ENGLISH: string = "en-US";

	static STRING_LANGUAGE_INDONESIAN: string = "ID";
	static STRING_LANGUAGE_ENGLISH: string = "EN";

	//#endregion


	//#region CHARACTER

	static STRING_CHARACTER_SEPARATOR_DATE_VIEW: string = "/";
	static STRING_CHARACTER_SEPARATOR_DATE_SERVER: string = "-";
	static STRING_CHARACTER_SEPARATOR_DATETIME_SERVER: string = "'T'";
	static STRING_CHARACTER_SEPARATOR_TIME_VIEW: string = ":";
	static STRING_CHARACTER_SEPARATOR_TIMEZONE_VIEW: string = "Z";
	static STRING_CHARACTER_SEPARATOR_DECIMAL: string = ";";
	static STRING_CHARACTER_SEPARATOR_THOUSAND: string = ".";
	static STRING_CHARACTER_DASH: string = " ";
	static STRING_CHARACTER_EMPTY: string = "-";

	static STRING_CHARACTER_SEPARATOR_FORMAT: string = ".";
	static STRING_CHARACTER_SEPARATOR_DIRECTORY: string = "/";
	static STRING_CHARACTER_SEPARATOR_FILENAME: string = "_";

	//#endregion


	//#region TAG

	static STRING_TAG_VERIFY_IDENTITYCARD: string = "PRODUCTASRI_";
	static STRING_TAG_VERIFY_SELFIEIDENTITYCARD: string = "PRODUCTOTO_";

	//#endregion


	//#region DATE

	static STRING_DATE_SERVER_DEFAULT: string = "0000" + StringConstant.STRING_CHARACTER_SEPARATOR_DATE_SERVER + "00" + StringConstant.STRING_CHARACTER_SEPARATOR_DATE_SERVER + "00";
	static STRING_TIME_SERVER_DEFAULT: string = "00" + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + "00" + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + "00" + StringConstant.STRING_CHARACTER_SEPARATOR_TIMEZONE_VIEW;
	static STRING_DATETIME_SERVER_DEFAULT: string = StringConstant.STRING_DATE_SERVER_DEFAULT + StringConstant.STRING_CHARACTER_SEPARATOR_DATETIME_SERVER + StringConstant.STRING_TIME_SERVER_DEFAULT;
	static NUMBER_TIME_SERVER_COMPONENT: number = 3;

	static STRING_DATE_SERVER_FORMAT: string = "yyyy-MM-dd";
	static STRING_TIME_SERVER_FORMAT: string = "HH:mm:ss";
	static STRING_DATETIME_SERVER_FORMAT: string = StringConstant.STRING_DATE_SERVER_FORMAT + StringConstant.STRING_CHARACTER_SEPARATOR_DATETIME_SERVER + StringConstant.STRING_TIME_SERVER_FORMAT + StringConstant.STRING_CHARACTER_SEPARATOR_TIMEZONE_VIEW;
	static STRING_DATETIME_SERVER_FORMATWITHOUTTIMEZONE: string = StringConstant.STRING_DATE_SERVER_FORMAT + StringConstant.STRING_CHARACTER_SEPARATOR_DATETIME_SERVER + StringConstant.STRING_TIME_SERVER_FORMAT;

	static STRING_DATE_VIEW_FORMAT: string = "dd/MM/yyyy";
	static STRING_TIME_VIEW_FORMAT: string = "HH:mm:ss";
	static STRING_TIME_VIEW_FORMATSHORT: string = "HH:mm";
	static STRING_DATETIME_VIEW_FORMAT: string = StringConstant.STRING_DATE_VIEW_FORMAT + " " + StringConstant.STRING_TIME_VIEW_FORMAT;
	static STRING_DATETIME_VIEW_FORMATSHORT: string = StringConstant.STRING_DATE_VIEW_FORMAT + " " + StringConstant.STRING_TIME_VIEW_FORMATSHORT;
	static STRING_TIMEZONE_VIEW_FORMAT: string = "GMT+07:00";

	static STRING_CURRENCY_IDR_CODE: string = "IDR";
	static STRING_CURRENCY_IDR_PREFIX: string = "Rp ";
	static STRING_CURRENCY_THOUSANDS: string = "K. ";
	static STRING_CURRENCY_KILO: string = " Km";

	static STRING_DATE_SHORT_FORMAT = "d-M-yyyy";
	static STRING_DATE_MEDIUM_FORMAT = "d MMMM y";
	static STRING_DATE_LONG_FORMAT = "EEEE, d MMMM y";

	static STRING_TIME_SHORT_FORMAT = "HH:mm";
	static STRING_TIME_MEDIUM_FORMAT = "HH:mm:ss";
	static STRING_TIME_LONG_FORMAT = "HH:mm:ss z";

	static STRING_DATETIME_COMPLETE_FORMAT = "EEEE, d MMMM y | HH:mm:ss";

	//#endregion


	//#region KEY_VALUE

	static STRING_VALUE_YEAR_MINIMUM: string = "1900";
	static STRING_VALUE_APPLICATION_VERSION: string = "1.0.16";
	static STRING_VALUE_CLIENT_VERSION: string = "1.1:10";
	static STRING_VALUE_PRODUCT_ASRI: string = "0117";
	static STRING_VALUE_PRODUCT_OTO: string = "0206";
	static STRING_VALUE_PRODUCT_BICYCLE: string = "1216";
	static STRING_VALUE_PRODUCT_PERSONALACCIDENT: string = "1001";
	static STRING_VALUE_PACKAGE_ASRISILVER: string = "ASRI-01-V3";
	static STRING_VALUE_PACKAGE_ASRIGOLD: string = "ASRI-02-V3";
	static STRING_VALUE_PACKAGE_ASRIPLATINUM: string = "ASRI-03-V3";
	static STRING_VALUE_PACKAGE_OTOBRONZE: string = "OTO-01-V3";
	static STRING_VALUE_PACKAGE_OTOSILVER: string = "OTO-02-V3";
	static STRING_VALUE_PACKAGE_OTOGOLD: string = "OTO-03-V3";
	static STRING_VALUE_PACKAGE_OTOPLATINUM: string = "OTO-04-V3";
	static STRING_VALUE_PACKAGE_PERSONALACCIDENTBM: string = "PA-BM";
	static STRING_VALUE_PRODUCT_ASRI_SYARIAH: string = "0164";
	static STRING_VALUE_PRODUCT_OTO_SYARIAH: string = "0256";
	static STRING_VALUE_PACKAGE_BICYCLESILVER: string = "1216-01-V3";
	static STRING_VALUE_PACKAGE_BICYCLEGOLD: string = "1216-02-V3";
	static STRING_VALUE_PACKAGE_BICYCLEPLATINUM: string = "1216-03-V3";
	static STRING_VALUE_PACKAGE_MYMOTORCYCLE: string = "OTO-07";
	static STRING_VALUE_PRODUCT_PERSONALACCIDENT_SYARIAH: string = "1051";
	static STRING_VALUE_PACKAGE_ASRISILVER_SYARIAH: string = "ASRI-01-S";
	static STRING_VALUE_PACKAGE_ASRIGOLD_SYARIAH: string = "ASRI-02-S";
	static STRING_VALUE_PACKAGE_ASRIPLATINUM_SYARIAH: string = "ASRI-03-S";
	static STRING_VALUE_PACKAGE_OTOBRONZE_SYARIAH: string = "OTO-01-S";
	static STRING_VALUE_PACKAGE_OTOSILVER_SYARIAH: string = "OTO-02-S";
	static STRING_VALUE_PACKAGE_OTOGOLD_SYARIAH: string = "OTO-03-S";
	static STRING_VALUE_PACKAGE_OTOPLATINUM_SYARIAH: string = "OTO-04-S";
	static STRING_VALUE_PACKAGE_PERSONALACCIDENTBM_SYARIAH: string = "PA-BM-S";
	static STRING_VALUE_PACKAGE_YEARLY_PERSONALACCIDENT: string = "PA-BM-V3";
	static STRING_VALUE_PACKAGE_PERSONALACCIDENTDAILY: string = "PAYG-F-V3";

	static STRING_VALUE_FILENAME_CALCULATEPREMIUM: string = "calculatepremium";
	static STRING_VALUE_FILENAME_SPPA: string = "sppa";
	static STRING_VALUE_FILENAME_POLICYOUTSTANDING: string = "policyoutstanding";
	static STRING_VALUE_FILENAME_POLICYRENEWAL: string = "policyrenewal";

	static STRING_VALUE_PRINTJOB_PDFPREVIEW: string = "Anjani - PDF Preview";

	static STRING_VALUE_COUNTRYCODE_INDONESIA: string = "+62";
	static STRING_VALUE_COUNTRYCODE_INDONESIAWITHOUTPLUS: string = "62";
	static STRING_VALUE_COUNTRYCODE_VIETNAM: string = "+84";
	static STRING_VALUE_COUNTRYCODE_NEWJERSEY: string = "+856";
	static STRING_VALUE_COUNTRYCODE_SINGAPORE: string = "+65";
	static STRING_VALUE_COUNTRYCODE_MALAYSIA: string = "+60";
	static STRING_VALUE_COUNTRYCODE_BRUNEI: string = "+673";
	static STRING_VALUE_COUNTRYCODE_THAILAND: string = "+66";
	static STRING_VALUE_COUNTRYCODE_MYANMAR: string = "+95";
	static STRING_VALUE_COUNTRYCODE_PHILIPPINES: string = "+63";
	static STRING_VALUE_COUNTRYCODE_CAMBODIA: string = "+855";
	static STRING_VALUE_TELEPHONE_BRINS_PRIMARY: string = "(21)79170477";
	static STRING_VALUE_TELEPHONE_BRINS_SECONDARY: string = "(21)79170478";
	static STRING_VALUE_TELEPHONE_CALLCENTER: string = "14081";

	static ARRAY_VALUE_COUNTRYCODE: Array<string> = [
		StringConstant.STRING_VALUE_COUNTRYCODE_INDONESIA, StringConstant.STRING_VALUE_COUNTRYCODE_VIETNAM ,StringConstant.STRING_VALUE_COUNTRYCODE_NEWJERSEY, StringConstant.STRING_VALUE_COUNTRYCODE_SINGAPORE,
		StringConstant.STRING_VALUE_COUNTRYCODE_MALAYSIA, StringConstant.STRING_VALUE_COUNTRYCODE_BRUNEI, StringConstant.STRING_VALUE_COUNTRYCODE_THAILAND, StringConstant.STRING_VALUE_COUNTRYCODE_MYANMAR,
		StringConstant.STRING_VALUE_COUNTRYCODE_PHILIPPINES, StringConstant.STRING_VALUE_COUNTRYCODE_CAMBODIA];

	static ARRAY_VALUE_TOC_FIRE: Array<string> = [ "0101", "0102", "0103", "0104", "0105", "0106", "0107", "0108", "0109", "0110", "0111", "0112", "0113", "0114", "0115", "0116", "0117", "0118", "0119", "0120", "0121", "0122", "0151", "0152", "0153", "0154", "0155", "0156", "0157", "0158", "0159", "0160", "0161", "0162", "0163", "0164", "0165", "0166", "0167" ];
	static ARRAY_VALUE_TOC_ENGINEERING: Array<string> = [ "0801", "0802", "0803", "0804", "0805", "0806", "0807", "0808", "0809", "0851", "0852", "0853", "0854", "0855", "0856", "0857", "0858" ];
	static ARRAY_VALUE_TOC_MV: Array<string> = ["0201", "0202", "0203", "0205", "0206", "0208", "0209", "0251", "0252", "0253", "0255", "0256", "0805", "0855"];
	// eslint-disable-next-line max-len
	static ARRAY_VALUE_TOC_VARIA: Array<string> = [ "0253", "0301", "0302", "0303", "0304", "0305", "0306", "0307", "0309", "0310", "0311", "0312", "0313", "0314", "0315", "0316", "0351", "0352", "0353", "0354", "0355", "0356", "0357", "0358", "0359", "0360", "0361", "0362", "0401", "0402", "0403", "0404", "0451", "0452", "0501", "0502", "0503", "0551", "0601", "0701", "0702", "0711", "0712", "0752", "0901", "0902", "0903", "0904", "0905", "0906", "0907", "0908", "0909", "0910", "0911", "0912", "0913", "0914", "0951", "0952", "0953", "0956", "0957", "0958", "1001", "1002", "1003", "1004", "1005", "1006", "1007", "1008", "1009", "1010", "1011", "1012", "1013", "1014", "1015", "1016", "1017", "1051", "1052", "1053", "1054", "1210", "1211", "1212", "1213", "1214", "1215", "1216", "1217", "1251", "1252", "1253", "1254", "1255", "1256", "1257", "1258", "1260", "1301", "1351"];
	// eslint-disable-next-line max-len
	static ARRAY_VALUE_TOC_FINANSIALLINE: Array<string> = [ "1101", "1102", "1103", "1104", "1105", "1106", "1107", "1108", "1109", "1110", "1111", "1112", "1151", "1152", "1153", "1154", "1155", "1156", "1157", "1158", "1159", "1160", "1202", "1203", "1204", "1208", "1212", "1217", "1251", "1252", "1253", "1254", "1258", "1260", "1401", "1402", "1403", "1404", "1405", "1406", "1407", "1408", "1409", "1410", "1411", "1412", "1413", "1414", "1415", "1416", "1451", "1452"];

	static STRING_COB_MOTOVEHICLE: string = "02";
	static STRING_CHART_PIE_LEGENDPOSITION: string = "below";
	static STRING_CHART_BAR_LEGENDPOSITION: string = "right";
	static STRING_FIELD_EMAIL_BRINS: string = "@brins.co.id";
	static STRING_FIELD_EMAIL_TSI_BRINS: string = "tsi@brins.co.id";
	static STRING_FIELD_EMAIL_INFO_BRINS: string = "callcenter@brins.co.id";
	static STRING_FIELD_BRANCHCODE_HEADQUARTER: string = "115";
	static STRING_FIELD_MODELVEHICLEBYVEHICLEBRAND: string = "ADI";
	static STRING_FIELD_BRANCHCODE_HEADOFFICE: string = "100";

	//#endregion


	//#region IMAGE

	static STRING_FORMAT_IMAGE_JPG: string = "jpg";
	static STRING_FORMAT_COMPRESS_ZIP: string = "zip";
	static STRING_FORMAT_DOCUMENT_PDF: string = "pdf";
	static STRING_FORMAT_COMPRESS_RAR: string = "rar";
	static STRING_FORMAT_IMAGE_PNG: string = "png";
	static STRING_FORMAT_IMAGE_JPEG: string = "jpeg";
	static STRING_FORMAT_DOCUMENT_WORD: string = "doc";
	static STRING_FORMAT_DOCUMENT_WORDX: string = "docx";
	static STRING_FORMAT_DOCUMENT_EXCEL: string = "xls";
	static STRING_FORMAT_DOCUMENT_EXCELX: string = "xlsx";
	static STRING_FORMAT_DOCUMENT_CSV: string = "csv";

	static ARRAY_VALUE_FORMAT: Array<string> = [
		StringConstant.STRING_FORMAT_IMAGE_JPG, StringConstant.STRING_FORMAT_DOCUMENT_PDF, StringConstant.STRING_FORMAT_IMAGE_PNG, StringConstant.STRING_FORMAT_IMAGE_JPEG, StringConstant.STRING_FORMAT_DOCUMENT_WORD, StringConstant.STRING_FORMAT_DOCUMENT_WORDX, StringConstant.STRING_FORMAT_DOCUMENT_EXCEL, StringConstant.STRING_FORMAT_DOCUMENT_EXCELX, StringConstant.STRING_FORMAT_DOCUMENT_CSV];

	static ARRAY_VALUE_IMAGE: Array<string> = [
		StringConstant.STRING_FORMAT_IMAGE_JPG, StringConstant.STRING_FORMAT_IMAGE_PNG, StringConstant.STRING_FORMAT_IMAGE_JPEG];

	//#endregion


	//#region CONVERTION

	static STRING_HASH_SHA256: string = "SHA-256";

	//#endregion


	//#region HTTP

	static STRING_HTTP_HEADER_AUTHORIZEDKEY: string = "AuthorizedKey";

	//#endregion


	//#region ROUTING

	static STRING_ROUTING_KEY_TOKEN: string = "token";
	static STRING_ROUTING_KEY_TOKEN_PRODUCT: string = "tokenproduct";
	static STRING_ROUTING_POLICYNUMBER: string = "policynumber";
	static STRING_ROUTING_CLAIMNUMBER: string = "claimnumber";
	static STRING_ROUTING_PAYMENTANO: string = "ano";
	static STRING_ROUTING_PRODUCT: string = "product";
	static STRING_ROUTING_PUBLIC: string = "public";
	static STRING_ROUTING_TICKET: string = "ticket";
	static STRING_ROUTING_SIGNIN: string = "signin";

	//#endregion


	//#region LOCAL STORAGE

	static STRING_LOCALSTORAGE_KEY_HANDSHAKEMODEL: string = "HandshakeModel";
	static STRING_LOCALSTORAGE_KEY_USERSESSION: string = "UserSession";
	static STRING_LOCALSTORAGE_KEY_BASEMODEL: string = "BaseModel";
	static STRING_LOCALSTORAGE_KEY_TABLEMODELFORAPPROVALASRILIST: string = "TableModelForApprovalAsriList";
	static STRING_LOCALSTORAGE_KEY_TABLEMODELFORAPPROVALOTOLIST: string = "TableModelForApprovalOtoList";
	static STRING_LOCALSTORAGE_KEY_TABLEMODELFORPURCHASEASRILIST: string = "TableModelForPurchaseAsriList";
	static STRING_LOCALSTORAGE_KEY_TABLEMODELFORPURCHASEOTOLIST: string = "TableModelForPurchaseOtoList";
	static STRING_LOCALSTORAGE_KEY_TABLEMODELFORPURCHASEPERSONALACCIDENTLIST: string = "TableModelForPurchasePersonalAccidentList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORPACKAGELIST: string = "TableModelForPackageList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORPACKAGEOPTIONLIST: string = "TableModelForPackageOptionList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORCHATREPORTLIST: string = "TableModelForChatReportList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORBANREPORTLIST: string = "TableModelForBanReportList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORADVERTISINGDAILYLIST: string = "TableModelForAdvertisingDailyList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORADVERTISINGCARDLIST: string = "TableModelForAdvertisingCardList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORACCOUNTLIST: string = "TableModelForAccountList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORACCOUNTOPTIONLIST: string = "TableModelForAccountOptionList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORSEEKERAPPROVALLIST: string = "TableModelForSeekerApprovalList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORSEEKERPROFILEREPORTLIST: string = "TableModelForSeekerProfileReportList";

	//#endregion


	//#region CHECK URL

	static STRING_URL_PATH_INSERT: string = "insert";

	//#endregion


	//#region PRODUCT ASRI GRUANTEE RISK

	static STRING_ASRI_GUARANTEERISK_ONE: string = "Kebakaran, Petir, Ledakan, Kejatuhan Pesawat Terbang, Asap";
	static STRING_ASRI_GUARANTEERISK_TWO: string = "Tanggung Jawab Hukum Pihak Ketiga";
	static STRING_ASRI_GUARANTEERISK_THREE: string = "Banjir, Angin Topan, Badai, Kerusakan Akibat Air";
	static STRING_ASRI_GUARANTEERISK_FOUR: string = "Kerusuhan, Pemogokan, Penghalangan Bekerja, Perbuatan Jahat, Huru-hara, Dan Penjarahan Yang Tercantum Dalam Polis (RSMDCC)";
	static STRING_ASRI_GUARANTEERISK_FIVE: string = "Terorisme Dan Sabotase";
	static STRING_ASRI_GUARANTEERISK_SIX: string = "Kebongkaran";
	static STRING_ASRI_GUARANTEERISK_SEVEN: string = "Santunan Duka Meninggal Dunia Dan Cacat Tetap Akibat Kecelakaan";
	static STRING_ASRI_GUARANTEERISK_EIGHT: string = "Santunan Biaya Pengobatan Atau Perawatan Medis";
	static STRING_ASRI_GUARANTEERISK_NINE: string = "Bantuan Sewa Rumah Tinggal";
	static STRING_ASRI_GUARANTEERISK_TEN: string = "Gempa Bumi, Tsunami, Letusan Gunung Berapi";

	//#endregion


	//#region PRODUCT OTO GRUANTEE RISK

	static STRING_OTO_GUARANTEERISK_ONE: string = "Kerugian Total";
	static STRING_OTO_GUARANTEERISK_TWO: string = "Kerugian Sebagian";
	static STRING_OTO_GUARANTEERISK_THREE: string = "Tanggung Jawab Hukum Pihak Ketiga";
	static STRING_OTO_GUARANTEERISK_FOUR: string = "Angin Topan, Badai, Kerusakan Akibat Air";
	static STRING_OTO_GUARANTEERISK_FIVE: string = "Kerusuhan, Pemogokan, Penghalangan Bekerja, Perbuatan Jahat, Huru-Hara, dan Penjarahan Yang Tercantum Dalam Polis (RSMDCC)";
	static STRING_OTO_GUARANTEERISK_SIX: string = "Terorisme Dan Sabotase";
	static STRING_OTO_GUARANTEERISK_SEVEN: string = "Gempa Bumi, Tsunami Dan Letusan Gunung Berapi";
	static STRING_OTO_GUARANTEERISK_EIGHT: string = "Kecelakaan Pengemudi";
	static STRING_OTO_GUARANTEERISK_NINE: string = "Kecelakaan Penumpang";

	//#endregion


	//#region PRODUCT BICYCLE GRUANTEE RISK

	static STRING_BICYCLE_GUARANTEERISK_ONE: string = "Kerugian Total";
	static STRING_BICYCLE_GUARANTEERISK_TWO: string = "Pencurian Dengan Kekerasan";
	static STRING_BICYCLE_GUARANTEERISK_THREE: string = "Bencana Alam";
	static STRING_BICYCLE_GUARANTEERISK_FOUR: string = "Kerusuhan, pemogokan, penghalangan bekerja, perbuatan jahat, huru-hara, dan penjarahan yang tercantum dalam polis (RSMDCC)";
	static STRING_BICYCLE_GUARANTEERISK_FIVE: string = "Terorisme Dan Sabotase";

	//#endregion


	//#region PRODUCT PERSONALACCIDENT GRUANTEE RISK

	static STRING_PERSONALACCIDENT_GUARANTEERISK_ONE: string = "Kematian";
	static STRING_PERSONALACCIDENT_GUARANTEERISK_TWO: string = "Cacat Tetap Sebagian atau Seluruh Tubuh";
	static STRING_PERSONALACCIDENT_GUARANTEERISK_THREE: string = "Biaya Perobatan atau Pengobatan";

	//#endregion


	//#region PRODUCT SPPA

	static ARRAY_STRING_SPPA: Array<string> =
		[
			"Segala informasi yang diberikan benar, berlaku, dan sah.",
			"Setuju dengan SPPA (Surat Permohonan Penutupan Asuransi) Elektronik yang terdapat dalam aplikasi ini.",
			"Data yang diisi dalam aplikasi ini adalah data yang sebenarnya. Apabila dikemudian hari terdapat keterangan/informasi yang bertentangan dengan keadaan sebenarnya tetapi tidak dinyatakan /dikemukakan dalam Surat Permohonan Penutupan Asuransi ini, dimana dalam hal apabila keterangan/informasi yang sebenarnya diberikan sejak awal maka pertanggungan Asuransi tidak akan ditutup atau dipertanggungkan dengan syarat dan ketentuan yang sama, maka Penanggung berhak untuk membatalkan Polis dan / atau menolak klaim yang diajukan.",
			"Menyetujui Pertanggungan ini mulai berlaku sejak masa pertanggungan yang tercantum dalam polis yang diterbitkan oleh Penanggung.",
			"Dengan mengisi dan melengkapi data diri yang dipersyaratkan untuk mengikuti program asuransi ini, saya mengajukan permohonan asuransi kepada BRINS (Penanggung) yang mana dalam hal ini saya bertindak sebagai calon pemegang polis dan menyatakan bahwa: Saya telah memenuhi syarat dan ketentuan yang dipersyaratkan oleh Penanggung. Semua data, pernyataan dan jawaban yang saya berikan untuk keikutsertaan saya dalam program asuransi berlaku sebagai Surat Permintaan Asuransi adalah benar dan akan menjadi dasar bagi berlakunya Kontrak Asuransi yang akan disetujui dan dikeluarkan oleh pihak Penanggung. Apabila dikemudian hari terdapat keterangan / informasi yang bertentangan dengan keadaan yang sebenarnya tetapi tidak dinyatakan/dikemukakan dalam Surat Permintaan Asuransi ini, dimana dalam hal apabila keterangan / informasi yang sebenarnya.",
			"Saya telah memenuhi syarat dan ketentuan yang yang dipersyaratkan oleh Penanggung. Semua data, pernyataan dan jawaban yang saya berikan untuk keikutsertaan saya dalam program asuransi berlaku sebagai Surat Permintaan Asuransi adalah benar dan akan menjadi dasar bagi berlakunya kontrak Asuransi yang akan disetujui dan dikeluarkan oleh pihak Penanggung. Apabila dikemudian hari terdapat keterangan / informasi yang bertentangan dengan keadaan sebenarnya tetapi tidak dinyatakan/dikemukakan dalam Surat Permintaan Asuransi ini, dimana dalam hal apabila keterangan / informasi sebenarnya diberikan sejak awal maka pertanggungan asuransi tidak akan ditutup atau dipertanggungkan dengan syarat dan ketentuan yang sama, maka Penanggung berhak untuk membatalkan Polis dan / atau menolak klaim yang diajukan. Menyetujui Pertanggungan ini mulai berlaku sejak masa pertanggungan yang tercantum dalam Polis yang diterbitkan oleh penanggung.",
			"Dengan ini saya menyatakan bahwa informasi yang saya berikan adalah benar, tepat, dan tidak menyesatkan. Saya memahami bahwa polis saya dapat dibatalkan oleh perusahaan asuransi apabila informasi yang saya sediakan ternyata salah, tidak tepat, atau menyesatkan.",
			"Saya dengan ini memastikan bahwa saya telah membaca, memahami, dan menyetujui manfaat manfaat, ketentuan-ketentuan, pengecualian-pengecualian, dan aspek lainnya dari polis asuransi yang saya ajukan, bahwa saya memahami bahwa pembayaran ini bukan merupakan suatu persetujuan atau secara otomatis menghasilkan persetujuan dari perusahaan asuransi untuk menjamin resiko yang diajukan, menyediakan perlindungan, atau menerbitkan polis.",
			"Persetujuan atas pengajuan polis asuransi yang Anda lakukan melalui website BRINS, sepenuhnya merupakan wewenang Perusahaan Asuransi. Perusahaan Asuransi merupakan penanggung atas risiko yang diasuransikan dan merupakan pihak yang berwenang memutuskan untuk menerima atau menolak suatu pertanggungan.",
			"Pihak BRI Insurance sewaktu-waktu dapat melakukan survey jika diperlukan untuk kepentingan verifikasi data.",
			"Pastikan bahwa Anda telah memenuhi kelengkapan dokumen yang disyaratkan oleh Peraturan Otoritas Jasa Keuangan No. 12/POJK.01/2017 Tentang Penerapan Program Anti Pencucian Uang dan Pencegahan.",
			"Saya/Kami dengan ini mengizinkan Penanggung untuk menggunakan data atau informasi mengenai Saya/Kami sebagaimana tertera dalam SPPA ini untuk kepentingan BRI Group.",
			"Segala informasi yang diberikan benar, berlaku, dan Tertanggung wajib untuk mengunggah dokumen pendukung dalam pengajuan asuransi sesuai dengan ketentuan yang diberlaku. Jika dokumen yang diunggah tidak sesuai, maka BRINS berhak untuk menolak apabila tertanggung mengajukan klaim."
		];

	//#endregion


	//#region POLICY HARDCOPY

	static ARRAY_STRING_POLICY_HARDCOPY: Array<string> =
		[
			"Polis fisik (Hardcopy) akan dikirimkan ke alamat yang telah anda lengkapi pada formulir sebelumnya.",
			"Pengiriman polis fisik (hardcopy) akan menyesuaikan waktu pengiriman dari perusahaan ekspedisi."
		];

	//#endregion


	//#region PAYMENT BRIMO

	static ARRAY_STRING_PAYMENT_BRIMO: Array<string> =
		[
			"Login pada aplikasi BRIMO.",
			"Pilih fitur lainnya.",
			"Klik Asuransi.",
			"Klik pembayaran baru.",
			"Pilih BRI Insurance pada jenis asuransi.",
			"Masukan nomor virtual account BRIVA.",
			"Pilih sumber dana yang akan digunakan untuk pembayaran.",
			"Klik bayar untuk memproses pembayaran."
		];

	//#endregion


	//#region PAYMENT ATM BRI

	static ARRAY_STRING_PAYMENT_ATM_BRI: Array<string> =
		[
			"Nasabah melakukan pembayaran melalui ATM.",
			"Pilih Menu Transaksi Lain.",
			"Pilih Menu Pembayaran.",
			"Pilih Menu Lainnya.",
			"Pilih Menu BRIVA.",
			"Masukan 15 digit Nomor Virtual Account BRIVA.",
			"Masukan nominal yang ingin Anda transfer.",
			"Proses pembayaran (Ya/Tidak).",
		];

	//#endregion


	//#region PRODUCT PACKAGE

	static STRING_PRODUCT_PACKAGE_BRONZE: string = "Bronze";
	static STRING_PRODUCT_PACKAGE_SILVER: string = "Silver";
	static STRING_PRODUCT_PACKAGE_GOLD: string = "Gold";
	static STRING_PRODUCT_PACKAGE_PLATINUM: string = "Platinum";

	//#endregion


	//#region PRODUCT NAME

	static STRING_PRODUCT_NAME_ASRI: string = "asri";
	static STRING_PRODUCT_NAME_OTO: string = "oto";
	static STRING_PRODUCT_NAME_BICYCLE: string = "bicycle";
	static STRING_PRODUCT_NAME_PERSONALACCIDENT: string = "personalaccident";

	//#endregion


	//#region MODEL

	static STRING_MODULE_CLAIMPROPOSAL: string = "Claim Proposal.";

	//#endregion


	//#region PLACEHOLDER

	static STRING_PLACEHOLDER_VEHICLELOCATION = "Pilih Lokasi Kendaraan";
	static STRING_PLACEHOLDER_BRANCH = "Pilih Cabang BRINS";
	static STRING_PLACEHOLDER_BRANCHCOVERAGE = "Pilih Cakupan Cabang";

	//#endregion


	//#region CLAIM PROPOSAL MESSAGE CONTENT

	static STRING_MESSAGECONTENT_CLAIMPROPOSAL_LOCATIONISBLOCKED = "Location is blocked.";

	//#endregion


	//#region SOURCE OF INCOME

	static ARRAY_STRING_SOURCE_OF_INCOME: Array<string> =
		[
			"Gaji",
			"Hasil Usaha",
			"Pensiun",
			"Pengembalian Investasi",
			"Dividen",
			"Warisan",
			"Lainnya",
		];

	//#endregionv


	//#region INCOME PER YEAR

	static ARRAY_STRING_INCOME_PER_YEAR: Array<string> =
		[
			"< 50 juta",
			"50 juta - 250 juta",
			"250 juta - 500 juta",
			"500 juta - 1 milliar",
			"> 1 milliar",
		];

	//#endregion


	//#region CITIZENSHIP

	static STRING_CITIZENSHIP_INDONESIA = "Warga Negara Indonesia";
	static STRING_CITIZENSHIP_FOREIGN = "Warga Negara Asing";

	//#endregion

}

//#endregion
