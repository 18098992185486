import { ENUM_PHOTO_TYPE } from "../constants/enum.constant";
import { UserModel } from "./user.model";
import { BaseModel } from "./bases/base.model";
//#region CLASS

export class CustomerModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for Customer model, used for listpackage  and link to product.
		Author: Andri Septiawan and Ibrahim Aziz.
		Created on : Tuesday, 29 December 2020. 			Updated on : Wednesday, 6 January 2021.
		Created by : Andri Septiawan.						Updated by : Ibrahim Aziz.
		Version : 1.0:2.
	*/


	ID?: number;
	CoreID?: string;
	LeadID?: number;

	UserID?: number;
	OwnerID?: string;
	ReferenceID?: string;
	ReferenceName?: string;
	DeviceID?: string;
	Culture?: string;
	Token?: string;
	PhotoURL?: string;

	modelUser?: UserModel;

	/* Attribute - END */


	//#region  FUNCTION

	setInterfaceDocument(): Array<number>
	{
		const enumPhotoType = ENUM_PHOTO_TYPE;
		let arrayModelGalleryModel: Array<number> = Array();

		arrayModelGalleryModel =
		[
			enumPhotoType.KTP,
			enumPhotoType.DrivingLicense,
			enumPhotoType.STNK,
			enumPhotoType.KirReport,
			enumPhotoType.VehicleFront,
			enumPhotoType.VehicleLeft,
			enumPhotoType.VehicleRight,
			enumPhotoType.VehicleBack
		];
		// if(b === 0 || b === 1 || b === 3 || b === 4)
		// {
		// 	arrayModelGalleryModel =
		// 	[
		// 		enumPhotoType.KTP,
		// 		enumPhotoType.ClaimLetter,
		// 		enumPhotoType.ObjectOfLoss,
		// 		enumPhotoType.Other,
		// 	];
		// }
		// else if (b === 2 )
		// {
		// }
		// else
		// {

		// }

		return arrayModelGalleryModel;
	}

	//#endregion
}

//#endregion