/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-shadow */
//#region IMPORT

import { Location } from "@angular/common";
import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BaseAuthourizeDetailComponent } from "src/app/components/bases/baseauthourizedetail.component";
import { ENUM_SPPA_STATUS } from "src/app/constants/enum.constant";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { StringConstant } from "src/app/constants/string.constant";
import { ProductAsriModel } from "src/app/models/productasri.model";
import { ProductBicycleModel } from "src/app/models/productbicycle.model";
import { ProductGeneralModel } from "src/app/models/productgeneral.model";
import { ProductOtoModel } from "src/app/models/productoto.model";
import { ProductPersonalAccidentModel } from "src/app/models/productpersonalaccident.model";
import { ResponseModel } from "src/app/models/response.model";
import { VirtualAccountModel } from "src/app/models/virtualaccount.model";
import { ProductasriService } from "src/app/services/productasri.service";
import { ProductbicycleService } from "src/app/services/productbicycle.service";
import { ProductgeneralService } from "src/app/services/productgeneral.service";
import { ProductotoService } from "src/app/services/productoto.service";
import { ProductpersonalaccidentService } from "src/app/services/productpersonalaccident.service";
import { SessionService } from "src/app/services/session.service";

//#endregion


//#region COMPONENT

@Component({
	selector: "app-payment",
	templateUrl: "./payment.component.html",
	styleUrls: ["./payment.component.sass"]
})

//#endregion


//#region CLASS

export class PaymentComponent extends BaseAuthourizeDetailComponent
{
	//#region DECLARATION

	public _modelProductGeneral: ProductGeneralModel;
	public _modelProductGeneralResponse: ProductGeneralModel;
	public _modelProductGeneralRequest: ProductGeneralModel;
	public _modelProductGeneralDiscount: ProductGeneralModel;
	public _modelProductAsri: ProductAsriModel;
	public _modelProductOto: ProductOtoModel;
	public _modelProductBicycle: ProductBicycleModel;
	public _modelProductPersonalAccident: ProductPersonalAccidentModel;
	public _modelVirtualAccount: VirtualAccountModel;

	public _stringConstant = StringConstant;
	public _stringBrivaNumber: string = "77704020401234";
	public _stringToken: any;
	public _stringPaymentTitle: string;
	public _stringPaymentContent: string;
	public _stringTokenProduct: string;

	public _booleanTabPaymentMethod: boolean;

	public _enumSPPAStatus = ENUM_SPPA_STATUS;

	public _date: any;

	public _intervalIDCountDown: any;
	public _intervalIDCountDown2: any;

	public _numberVirtualAccount: any;
	public _numberBrivaNumber: any;
	public _numberAccountNumber: any;
	public _numberDateTimeVADistance: number;
	public _numberDateTimeThirdPartyDistance: number;
	public _numberHours: any;
	public _numberMinutes: any;
	public _numberSeconds: any;
	public _numberVATimeExpired: number;
	public _numberThirdPartyTimeExpired: number;
	public _numberDateTimeNow: any;

	public _arrayStringURL: Array<string>;

	//#endregion


	//#region CONSTRUCTOR

	constructor(routeActivated: ActivatedRoute, location: Location, serviceSession: SessionService, public router: Router, private _serviceProductGeneral: ProductgeneralService, private _serviceProductAsri: ProductasriService, private _serviceProductOto: ProductotoService, private _serviceProductBicycle: ProductbicycleService, private _serviceProductPersonalAccident: ProductpersonalaccidentService)
	{
		super(routeActivated, location, serviceSession, router);

		this._modelProductGeneral = new ProductGeneralModel();
		this._modelProductGeneralResponse = new ProductGeneralModel();
		this._modelProductGeneralRequest = new ProductGeneralModel();
		this._modelProductGeneralDiscount = new ProductGeneralModel();
		this._modelProductAsri = new ProductAsriModel();
		this._modelProductOto = new ProductOtoModel();
		this._modelProductBicycle = new ProductBicycleModel();
		this._modelProductPersonalAccident = new ProductPersonalAccidentModel();
		this._modelVirtualAccount = new VirtualAccountModel();

		this._stringPaymentTitle = "";
		this._stringPaymentContent = "";
		this._stringTokenProduct = "";

		this._booleanTabPaymentMethod = true;

		this._numberDateTimeVADistance = 0;
		this._numberDateTimeThirdPartyDistance = 0;
		this._numberHours = 0;
		this._numberMinutes = 0;
		this._numberSeconds = 0;
		this._numberDateTimeNow = new Date().getTime();
		this._numberVATimeExpired = 0;
		this._numberThirdPartyTimeExpired = 0;

		this._arrayStringURL = [];
	}

	//#endregion


	//#region INITIALIZE

	ngOnInit(): void
	{
		const stringURL: string = window.location.pathname;
		this._arrayStringURL = stringURL.split("/");

		const stringProductName: string | null = this._arrayStringURL[(this._arrayStringURL.length - 3)];
		this._stringTokenProduct = this._arrayStringURL[(this._arrayStringURL.length - 2)];
		const stringToken: string | null = this.getParameterFromRouter();

		if (stringProductName !== null && stringToken !== null)
		{
			this._modelProductGeneralRequest.Token = stringToken;

			if(stringProductName === StringConstant.STRING_PRODUCT_NAME_ASRI)
			{
				this.callSelectProductAsriByTokenForSelfServiceMonitoring(this);
			}
			else if(stringProductName === StringConstant.STRING_PRODUCT_NAME_OTO)
			{
				this.callSelectProductOtoByTokenForSelfServiceMonitoring(this);
			}
			else if(stringProductName === StringConstant.STRING_PRODUCT_NAME_BICYCLE)
			{
				this.callSelectProductBicycleByTokenForSelfServiceMonitoring(this);
			}
			else if(stringProductName === StringConstant.STRING_PRODUCT_NAME_PERSONALACCIDENT)
			{
				this.callSelectProductPersonalAccidentByTokenForSelfServiceMonitoring(this);
			}
			else
			{
			}
		}
		else
		{

		}
	}

	//#endregion


	//#region WEB SERVICE

	public callSelectProductGeneralReferenceNumberByToken(componentCurrent: PaymentComponent): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneral = new ProductGeneralModel();

		componentCurrent._modelProductGeneral.Token = componentCurrent._modelProductGeneralRequest.Token;

		componentCurrent._serviceProductGeneral.selectProductGeneralReferenceNumberByToken
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						const modelProductGeneralResponse: ProductGeneralModel = new ProductGeneralModel();
						modelProductGeneralResponse.setModelFromString(modelResponse.Data);

						componentCurrent._modelProductGeneral.ReferenceNumber = modelProductGeneralResponse.ReferenceNumber;
					}
					else
					{
					}
					componentCurrent._functionUserInterface.updateLoadingProgress();
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				componentCurrent._functionUserInterface.updateLoadingProgress();
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, componentCurrent._modelProductGeneral);
	}

	//#endregion


	//#region WEB SERVICE ASRI

	private callSelectProductAsriByTokenForSelfServiceMonitoring(componentCurrent: PaymentComponent): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductAsri = new ProductAsriModel();

		componentCurrent._modelProductAsri.Token = componentCurrent._stringTokenProduct;

		this._serviceProductAsri.selectProductByTokenForSelfServiceMonitoring
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelProductGeneralResponse.setModelFromString(modelResponse.Data);
						componentCurrent.callGeneratePaymentBrivaAsriForSelfServiceMonitoring(componentCurrent);
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () =>
				{
					componentCurrent.callSelectProductAsriByTokenForSelfServiceMonitoring(componentCurrent);
				});
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, this._modelProductAsri);
	};

	public callGeneratePaymentBrivaAsriForSelfServiceMonitoring(componentCurrent: PaymentComponent): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneral = new ProductGeneralModel();

		componentCurrent._modelProductGeneral.Token = componentCurrent._modelProductGeneralRequest.Token;

		componentCurrent._serviceProductAsri.generatePaymentForSelfServiceMonitoring
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelProductGeneral = JSON.parse(modelResponse.Data);
					}
					else
					{
					}

					componentCurrent.callSharePaymentAsri(componentCurrent);
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, componentCurrent._modelProductGeneral);
	}

	private callSharePaymentAsri(componentCurrent: PaymentComponent): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(2);

		componentCurrent._modelProductGeneral = new ProductGeneralModel();

		componentCurrent._modelProductGeneral.Token = componentCurrent._modelProductGeneralRequest.Token;

		this._serviceProductAsri.sharePayementForSelfServiceMonitoring
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelProductGeneral = new ProductGeneralModel();
						componentCurrent._modelProductGeneral.setModelFromString(modelResponse.Data);

						componentCurrent._modelProductGeneral.setModelProductAsri();

						if (componentCurrent._modelProductGeneral.modelVirtualAccount !== null && componentCurrent._modelProductGeneral.modelVirtualAccount !== undefined)
						{
							componentCurrent._modelProductGeneral.setModelVirtualAccount();

							if (componentCurrent._modelProductGeneral.modelVirtualAccount !== null && componentCurrent._modelProductGeneral.modelVirtualAccount !== undefined && componentCurrent._modelProductGeneral.modelVirtualAccount.ExpiredDate !== null && componentCurrent._modelProductGeneral.modelVirtualAccount.ExpiredDate !== undefined)
							{
								componentCurrent._numberVATimeExpired = new Date(componentCurrent._modelProductGeneral.modelVirtualAccount.ExpiredDate).initiateWithoutUTC().getTime();
								componentCurrent._stringPaymentTitle = "Nomor Virtual Account";
								componentCurrent._stringPaymentContent = "" + componentCurrent._modelProductGeneral.modelVirtualAccount.BRIVANumber + componentCurrent._modelProductGeneral.modelVirtualAccount.AccountNumber;
								componentCurrent._numberDateTimeVADistance = componentCurrent._numberVATimeExpired - componentCurrent._numberDateTimeNow;
								componentCurrent._functionUserInterface.updateLoadingProgress();

								componentCurrent.setIntervalDateTime(componentCurrent._intervalIDCountDown, componentCurrent._numberDateTimeVADistance, componentCurrent._numberVATimeExpired);

								setTimeout(function()
								{
									componentCurrent.callSendSPPAEmailForAsri(componentCurrent);
									componentCurrent.callSelectProductGeneralReferenceNumberByToken(componentCurrent);
								}, 1000);

								if (componentCurrent._numberDateTimeVADistance <= 0)
								{
									clearInterval(componentCurrent._intervalIDCountDown);
									componentCurrent._stringPaymentTitle = "Waktu Pembayaran";
									componentCurrent._stringPaymentContent = "Waktu pembayaran anda kadaluarsa, silahkan ganti metode pembayaran!";
								}
								else
								{

								}
							}
							else
							{

							}
						}
						else
						{
						}
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}

				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.callSharePaymentAsri(componentCurrent); });
				componentCurrent._functionUserInterface.updateLoadingProgress();
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, this._modelProductGeneral);
	}

	private callSendSPPAEmailForAsri(componentCurrent: PaymentComponent): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneral = new ProductGeneralModel();

		this._modelProductGeneral.Token = this._modelProductGeneralRequest.Token;
		this._modelProductGeneral.PolicyholderEmail = componentCurrent._modelProductGeneralResponse.PolicyholderEmail;

		this._serviceProductAsri.sendSPPAByEmailAsriForSelfServiceMonitoring
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					componentCurrent._functionUserInterface.updateLoadingProgress();
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
					}
				}
				else
				{
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.callSendSPPAEmailForAsri(componentCurrent); });
				componentCurrent._functionUserInterface.updateLoadingProgress();
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, this._modelProductGeneral);
	}

	public callConfirmPaymentASRI(componentCurrent: PaymentComponent): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		if (componentCurrent._modelProductGeneral.modelProductAsri !== null && componentCurrent._modelProductGeneral.modelProductAsri !== undefined)
		{
			componentCurrent._modelProductAsri.Token = componentCurrent._stringTokenProduct;
		}
		else
		{
		}

		this._serviceProductAsri.confirmPayment
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					alert(modelResponse.MessageContent = "Pembayaran Anda telah berhasil dan sebentar lagi polis Anda akan aktif.");
				}
				else
				{
					alert(modelResponse.MessageContent = "Pembayaran Anda belum berhasil. Silahkan lakukan pembayaran terlebih dahulu.");
				}
				componentCurrent._functionUserInterface.updateLoadingProgress();
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callConfirmPaymentASRI(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () =>
				{
					componentCurrent.signOut();
				});
			}
		}, this._modelProductAsri);

	}

	//#endregion


	//#region WEB SERVICE OTO

	private callSelectProductOtoByTokenForSelfServiceMonitoring(componentCurrent: PaymentComponent): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductOto = new ProductOtoModel();

		componentCurrent._modelProductOto.Token = componentCurrent._stringTokenProduct;

		this._serviceProductOto.selectProductByTokenForSelfServiceMonitoring
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelProductGeneralResponse.setModelFromString(modelResponse.Data);
						componentCurrent.callGeneratePaymentBrivaOtoForSelfServiceMonitoring(componentCurrent);
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () =>
				{
					componentCurrent.callSelectProductOtoByTokenForSelfServiceMonitoring(componentCurrent);
				});
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, this._modelProductOto);
	};

	public callGeneratePaymentBrivaOtoForSelfServiceMonitoring(componentCurrent: PaymentComponent): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneral = new ProductGeneralModel();

		componentCurrent._modelProductGeneral.Token = componentCurrent._modelProductGeneralRequest.Token;

		componentCurrent._serviceProductOto.generatePaymentForSelfServiceMonitoring
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelProductGeneral = JSON.parse(modelResponse.Data);
					}
					else
					{
					}

					componentCurrent.callSharePaymentOto(componentCurrent);
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, componentCurrent._modelProductGeneral);
	}

	private callSharePaymentOto(componentCurrent: PaymentComponent): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(2);

		componentCurrent._modelProductGeneral = new ProductGeneralModel();

		componentCurrent._modelProductGeneral.Token = componentCurrent._modelProductGeneralRequest.Token;

		this._serviceProductOto.sharePayementForSelfServiceMonitoring
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelProductGeneral = new ProductGeneralModel();
						componentCurrent._modelProductGeneral.setModelFromString(modelResponse.Data);

						componentCurrent._modelProductGeneral.setModelProductOto();

						if (componentCurrent._modelProductGeneral.modelVirtualAccount !== null && componentCurrent._modelProductGeneral.modelVirtualAccount !== undefined)
						{
							componentCurrent._modelProductGeneral.setModelVirtualAccount();

							if (componentCurrent._modelProductGeneral.modelVirtualAccount !== null && componentCurrent._modelProductGeneral.modelVirtualAccount !== undefined && componentCurrent._modelProductGeneral.modelVirtualAccount.ExpiredDate !== null && componentCurrent._modelProductGeneral.modelVirtualAccount.ExpiredDate !== undefined)
							{
								componentCurrent._numberVATimeExpired = new Date(componentCurrent._modelProductGeneral.modelVirtualAccount.ExpiredDate).initiateWithoutUTC().getTime();
								componentCurrent._stringPaymentTitle = "Nomor Virtual Account";
								componentCurrent._stringPaymentContent = "" + componentCurrent._modelProductGeneral.modelVirtualAccount.BRIVANumber + componentCurrent._modelProductGeneral.modelVirtualAccount.AccountNumber;
								componentCurrent._numberDateTimeVADistance = componentCurrent._numberVATimeExpired - componentCurrent._numberDateTimeNow;
								componentCurrent._functionUserInterface.updateLoadingProgress();

								componentCurrent.setIntervalDateTime(componentCurrent._intervalIDCountDown, componentCurrent._numberDateTimeVADistance, componentCurrent._numberVATimeExpired);

								setTimeout(function()
								{
									componentCurrent.callSendSPPAEmailForOto(componentCurrent);
									componentCurrent.callSelectProductGeneralReferenceNumberByToken(componentCurrent);
								}, 1000);

								if (componentCurrent._numberDateTimeVADistance <= 0)
								{
									clearInterval(componentCurrent._intervalIDCountDown);
									componentCurrent._stringPaymentTitle = "Waktu Pembayaran";
									componentCurrent._stringPaymentContent = "Waktu pembayaran anda kadaluarsa, silahkan ganti metode pembayaran!";
								}
								else
								{

								}
							}
							else
							{

							}
						}
						else
						{
						}
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}

				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.callSharePaymentOto(componentCurrent); });
				componentCurrent._functionUserInterface.updateLoadingProgress();
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, this._modelProductGeneral);
	}

	private callSendSPPAEmailForOto(componentCurrent: PaymentComponent): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneral = new ProductGeneralModel();

		this._modelProductGeneral.Token = this._modelProductGeneralRequest.Token;
		this._modelProductGeneral.PolicyholderEmail = componentCurrent._modelProductGeneralResponse.PolicyholderEmail;

		this._serviceProductOto.sendSPPAByEmailOtoForSelfServiceMonitoring
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					componentCurrent._functionUserInterface.updateLoadingProgress();
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
					}
				}
				else
				{
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.callSendSPPAEmailForOto(componentCurrent); });
				componentCurrent._functionUserInterface.updateLoadingProgress();
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, this._modelProductGeneral);
	}

	public callConfirmPaymentOTO(componentCurrent: PaymentComponent): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		if (componentCurrent._modelProductGeneral.modelProductOto !== null && componentCurrent._modelProductGeneral.modelProductOto !== undefined)
		{
			componentCurrent._modelProductOto.Token = componentCurrent._stringTokenProduct;
		}
		else
		{
		}

		this._serviceProductOto.confirmPaymentForSelfServiceMonitoring
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					alert(modelResponse.MessageContent = "Pembayaran Anda telah berhasil dan sebentar lagi polis Anda akan aktif.");
				}
				else
				{
					alert(modelResponse.MessageContent = "Pembayaran Anda belum berhasil. Silahkan lakukan pembayaran terlebih dahulu.");
				}
				componentCurrent._functionUserInterface.updateLoadingProgress();
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callConfirmPaymentOTO(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () =>
				{
					componentCurrent.signOut();
				});
			}
		}, this._modelProductOto);

	}

	//#endregion


	//#region WEB SERVICE BICYCLE

	private callSelectProductBicycleByTokenForSelfServiceMonitoring(componentCurrent: PaymentComponent): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductBicycle = new ProductBicycleModel();

		componentCurrent._modelProductBicycle.Token = componentCurrent._stringTokenProduct;

		this._serviceProductBicycle.selectProductByTokenForSelfServiceMonitoring
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelProductGeneralResponse.setModelFromString(modelResponse.Data);
						componentCurrent.callGeneratePaymentBrivaBicycleForSelfServiceMonitoring(componentCurrent);
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () =>
				{
					componentCurrent.callSelectProductBicycleByTokenForSelfServiceMonitoring(componentCurrent);
				});
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, this._modelProductBicycle);
	};

	public callGeneratePaymentBrivaBicycleForSelfServiceMonitoring(componentCurrent: PaymentComponent): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneral = new ProductGeneralModel();

		componentCurrent._modelProductGeneral.Token = componentCurrent._modelProductGeneralRequest.Token;

		componentCurrent._serviceProductBicycle.generatePaymentForSelfServiceMonitoring
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelProductGeneral = JSON.parse(modelResponse.Data);
					}
					else
					{
					}

					componentCurrent.callSharePaymentBicycle(componentCurrent);
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, componentCurrent._modelProductGeneral);
	}

	private callSharePaymentBicycle(componentCurrent: PaymentComponent): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(2);

		componentCurrent._modelProductGeneral = new ProductGeneralModel();

		componentCurrent._modelProductGeneral.Token = componentCurrent._modelProductGeneralRequest.Token;

		this._serviceProductBicycle.sharePayementForSelfServiceMonitoring
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelProductGeneral = new ProductGeneralModel();
						componentCurrent._modelProductGeneral.setModelFromString(modelResponse.Data);

						componentCurrent._modelProductGeneral.setModelProductBicycle();

						if (componentCurrent._modelProductGeneral.modelVirtualAccount !== null && componentCurrent._modelProductGeneral.modelVirtualAccount !== undefined)
						{
							componentCurrent._modelProductGeneral.setModelVirtualAccount();

							if (componentCurrent._modelProductGeneral.modelVirtualAccount !== null && componentCurrent._modelProductGeneral.modelVirtualAccount !== undefined && componentCurrent._modelProductGeneral.modelVirtualAccount.ExpiredDate !== null && componentCurrent._modelProductGeneral.modelVirtualAccount.ExpiredDate !== undefined)
							{
								componentCurrent._numberVATimeExpired = new Date(componentCurrent._modelProductGeneral.modelVirtualAccount.ExpiredDate).initiateWithoutUTC().getTime();
								componentCurrent._stringPaymentTitle = "Nomor Virtual Account";
								componentCurrent._stringPaymentContent = "" + componentCurrent._modelProductGeneral.modelVirtualAccount.BRIVANumber + componentCurrent._modelProductGeneral.modelVirtualAccount.AccountNumber;
								componentCurrent._numberDateTimeVADistance = componentCurrent._numberVATimeExpired - componentCurrent._numberDateTimeNow;
								componentCurrent._functionUserInterface.updateLoadingProgress();

								componentCurrent.setIntervalDateTime(componentCurrent._intervalIDCountDown, componentCurrent._numberDateTimeVADistance, componentCurrent._numberVATimeExpired);

								setTimeout(function()
								{
									componentCurrent.callSendSPPAEmailForBicycle(componentCurrent);
									componentCurrent.callSelectProductGeneralReferenceNumberByToken(componentCurrent);
								}, 1000);

								if (componentCurrent._numberDateTimeVADistance <= 0)
								{
									clearInterval(componentCurrent._intervalIDCountDown);
									componentCurrent._stringPaymentTitle = "Waktu Pembayaran";
									componentCurrent._stringPaymentContent = "Waktu pembayaran anda kadaluarsa, silahkan ganti metode pembayaran!";
								}
								else
								{

								}
							}
							else
							{

							}
						}
						else
						{
						}
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}

				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.callSharePaymentBicycle(componentCurrent); });
				componentCurrent._functionUserInterface.updateLoadingProgress();
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, this._modelProductGeneral);
	}

	private callSendSPPAEmailForBicycle(componentCurrent: PaymentComponent): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneral = new ProductGeneralModel();

		this._modelProductGeneral.Token = this._modelProductGeneralRequest.Token;
		this._modelProductGeneral.PolicyholderEmail = componentCurrent._modelProductGeneralResponse.PolicyholderEmail;

		this._serviceProductBicycle.sendSPPAByEmailBicycleForSelfServiceMonitoring
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					componentCurrent._functionUserInterface.updateLoadingProgress();
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
					}
				}
				else
				{
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.callSendSPPAEmailForBicycle(componentCurrent); });
				componentCurrent._functionUserInterface.updateLoadingProgress();
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, this._modelProductGeneral);
	}

	public callConfirmPaymentBicycle(componentCurrent: PaymentComponent): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		if (componentCurrent._modelProductGeneral.modelProductBicycle !== null && componentCurrent._modelProductGeneral.modelProductBicycle !== undefined)
		{
			componentCurrent._modelProductBicycle.Token = componentCurrent._stringTokenProduct;
		}
		else
		{
		}

		this._serviceProductBicycle.confirmPaymentForSelfServiceMonitoring
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					alert(modelResponse.MessageContent = "Pembayaran Anda telah berhasil dan sebentar lagi polis Anda akan aktif.");
				}
				else
				{
					alert(modelResponse.MessageContent = "Pembayaran Anda belum berhasil. Silahkan lakukan pembayaran terlebih dahulu.");
				}
				componentCurrent._functionUserInterface.updateLoadingProgress();
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callConfirmPaymentBicycle(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () =>
				{
					componentCurrent.signOut();
				});
			}
		}, this._modelProductBicycle);

	}

	//#endregion


	//#region WEB SERVICE PERSONAL ACCIDENT

	private callSelectProductPersonalAccidentByTokenForSelfServiceMonitoring(componentCurrent: PaymentComponent): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductPersonalAccident = new ProductPersonalAccidentModel();

		componentCurrent._modelProductPersonalAccident.Token = componentCurrent._stringTokenProduct;

		this._serviceProductPersonalAccident.selectProductByTokenForSelfServiceMonitoring
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelProductGeneralResponse.setModelFromString(modelResponse.Data);
						componentCurrent.callGeneratePaymentBrivaPersonalAccidentForSelfServiceMonitoring(componentCurrent);
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () =>
				{
					componentCurrent.callSelectProductPersonalAccidentByTokenForSelfServiceMonitoring(componentCurrent);
				});
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, this._modelProductPersonalAccident);
	};

	public callGeneratePaymentBrivaPersonalAccidentForSelfServiceMonitoring(componentCurrent: PaymentComponent): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneral = new ProductGeneralModel();

		componentCurrent._modelProductGeneral.Token = componentCurrent._modelProductGeneralRequest.Token;

		componentCurrent._serviceProductPersonalAccident.generatePaymentForSelfServiceMonitoring
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelProductGeneral = JSON.parse(modelResponse.Data);
					}
					else
					{
					}

					componentCurrent.callSharePaymentPersonalAccident(componentCurrent);
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, componentCurrent._modelProductGeneral);
	}

	private callSharePaymentPersonalAccident(componentCurrent: PaymentComponent): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(2);

		componentCurrent._modelProductGeneral = new ProductGeneralModel();

		componentCurrent._modelProductGeneral.Token = componentCurrent._modelProductGeneralRequest.Token;

		this._serviceProductPersonalAccident.sharePayementForSelfServiceMonitoring
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelProductGeneral = new ProductGeneralModel();
						componentCurrent._modelProductGeneral.setModelFromString(modelResponse.Data);

						componentCurrent._modelProductGeneral.setModelProductPersonalAccident();

						if (componentCurrent._modelProductGeneral.modelVirtualAccount !== null && componentCurrent._modelProductGeneral.modelVirtualAccount !== undefined)
						{
							componentCurrent._modelProductGeneral.setModelVirtualAccount();

							if (componentCurrent._modelProductGeneral.modelVirtualAccount !== null && componentCurrent._modelProductGeneral.modelVirtualAccount !== undefined && componentCurrent._modelProductGeneral.modelVirtualAccount.ExpiredDate !== null && componentCurrent._modelProductGeneral.modelVirtualAccount.ExpiredDate !== undefined)
							{
								componentCurrent._numberVATimeExpired = new Date(componentCurrent._modelProductGeneral.modelVirtualAccount.ExpiredDate).initiateWithoutUTC().getTime();
								componentCurrent._stringPaymentTitle = "Nomor Virtual Account";
								componentCurrent._stringPaymentContent = "" + componentCurrent._modelProductGeneral.modelVirtualAccount.BRIVANumber + componentCurrent._modelProductGeneral.modelVirtualAccount.AccountNumber;
								componentCurrent._numberDateTimeVADistance = componentCurrent._numberVATimeExpired - componentCurrent._numberDateTimeNow;
								componentCurrent._functionUserInterface.updateLoadingProgress();

								componentCurrent.setIntervalDateTime(componentCurrent._intervalIDCountDown, componentCurrent._numberDateTimeVADistance, componentCurrent._numberVATimeExpired);

								setTimeout(function()
								{
									componentCurrent.callSendSPPAEmailForPersonalAccident(componentCurrent);
									componentCurrent.callSelectProductGeneralReferenceNumberByToken(componentCurrent);
								}, 1000);

								if (componentCurrent._numberDateTimeVADistance <= 0)
								{
									clearInterval(componentCurrent._intervalIDCountDown);
									componentCurrent._stringPaymentTitle = "Waktu Pembayaran";
									componentCurrent._stringPaymentContent = "Waktu pembayaran anda kadaluarsa, silahkan ganti metode pembayaran!";
								}
								else
								{

								}
							}
							else
							{

							}
						}
						else
						{
						}
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}

				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.callSharePaymentPersonalAccident(componentCurrent); });
				componentCurrent._functionUserInterface.updateLoadingProgress();
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, this._modelProductGeneral);
	}

	private callSendSPPAEmailForPersonalAccident(componentCurrent: PaymentComponent): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneral = new ProductGeneralModel();

		this._modelProductGeneral.Token = this._modelProductGeneralRequest.Token;
		this._modelProductGeneral.PolicyholderEmail = componentCurrent._modelProductGeneralResponse.PolicyholderEmail;

		this._serviceProductPersonalAccident.sendSPPAByEmailPersonalAccidentForSelfServiceMonitoring
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					componentCurrent._functionUserInterface.updateLoadingProgress();
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
					}
				}
				else
				{
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.callSendSPPAEmailForPersonalAccident(componentCurrent); });
				componentCurrent._functionUserInterface.updateLoadingProgress();
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, this._modelProductGeneral);
	}

	public callConfirmPaymentPersonalAccident(componentCurrent: PaymentComponent): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		if (componentCurrent._modelProductGeneral.modelProductPersonalAccident !== null && componentCurrent._modelProductGeneral.modelProductPersonalAccident !== undefined)
		{
			componentCurrent._modelProductPersonalAccident.Token = componentCurrent._stringTokenProduct;
		}
		else
		{
		}

		this._serviceProductPersonalAccident.confirmPaymentForSelfServiceMonitoring
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					alert(modelResponse.MessageContent = "Pembayaran Anda telah berhasil dan sebentar lagi polis Anda akan aktif.");
				}
				else
				{
					alert(modelResponse.MessageContent = "Pembayaran Anda belum berhasil. Silahkan lakukan pembayaran terlebih dahulu.");
				}
				componentCurrent._functionUserInterface.updateLoadingProgress();
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callConfirmPaymentPersonalAccident(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () =>
				{
					componentCurrent.signOut();
				});
			}
		}, this._modelProductPersonalAccident);

	}

	//#endregion


	//#region FUNCTION

	private setIntervalDateTime(intervalID: any, numberDateTimeDistance: number, numberExpired: number)
	{
		const componentCurrent: PaymentComponent = this;

		intervalID = setInterval(() =>
		{
			numberDateTimeDistance = numberExpired - componentCurrent._numberDateTimeNow;

			componentCurrent._numberHours = Math.floor((numberDateTimeDistance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
			if (componentCurrent._numberHours.toString().length === 1)
			{
				componentCurrent._numberHours = "" + "0" + componentCurrent._numberHours;
			}

			componentCurrent._numberMinutes = Math.floor((numberDateTimeDistance % (1000 * 60 * 60)) / (1000 * 60));
			if (componentCurrent._numberMinutes.toString().length === 1)
			{
				componentCurrent._numberMinutes = "" + "0" + componentCurrent._numberMinutes;
			}

			componentCurrent._numberSeconds = Math.floor((numberDateTimeDistance % (1000 * 60)) / 1000);
			if (componentCurrent._numberSeconds.toString().length === 1)
			{
				componentCurrent._numberSeconds = "" + "0" + componentCurrent._numberSeconds;
			}

			if (componentCurrent._numberDateTimeNow > numberExpired)
			{
				componentCurrent._numberHours = "00";
				componentCurrent._numberMinutes = "00";
				componentCurrent._numberSeconds = "00";
			}

			componentCurrent._numberDateTimeNow += 1000;

			if (numberDateTimeDistance <= 0)
			{
				clearInterval(intervalID);
			}

		}, 1000);
	}

	callConfirmPayment()
	{
		const stringURL: string = window.location.pathname;
		this._arrayStringURL = stringURL.split("/");

		const stringProductName: string | null = this._arrayStringURL[(this._arrayStringURL.length - 3)];
		this._stringTokenProduct = this._arrayStringURL[(this._arrayStringURL.length - 2)];
		const stringToken: string | null = this.getParameterFromRouter();

		if (stringProductName !== null && stringToken !== null)
		{
			if(stringProductName === StringConstant.STRING_PRODUCT_NAME_ASRI)
			{
				this.callConfirmPaymentASRI(this);
			}
			else if(stringProductName === StringConstant.STRING_PRODUCT_NAME_OTO)
			{
				this.callConfirmPaymentOTO(this);
			}
			else if(stringProductName === StringConstant.STRING_PRODUCT_NAME_BICYCLE)
			{
				this.callConfirmPaymentBicycle(this);
			}
			else if(stringProductName === StringConstant.STRING_PRODUCT_NAME_PERSONALACCIDENT)
			{
				this.callConfirmPaymentPersonalAccident(this);
			}
			else
			{
			}
		}
		else
		{

		}
	}

	//#endregion


	//#region SETTER

	setTabActive(booleanTabPaymentMethod: boolean): void
	{
		this._booleanTabPaymentMethod = booleanTabPaymentMethod;
	}

	setToClipBoard(stringBrivaNumber: any)
	{
		if (stringBrivaNumber)
		{
			const componentCopy = document.createElement("textarea");
			componentCopy.value = stringBrivaNumber;
			componentCopy.setAttribute("readonly","");
			componentCopy.style.position = "absolute";
			componentCopy.style.left = "-9999px";
			document.body.appendChild(componentCopy);
			componentCopy.select();
			document.execCommand("copy");
			document.body.removeChild(componentCopy);

			alert("Copied success!");
		}
	}

	//#endregion
}

//#endregion
