/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-shadow */
//#region IMPORT

import { Location } from "@angular/common";
import { Component, ViewChild } from "@angular/core";
import { RuleConstant } from "src/app/constants/rule.constant";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxImageCompressService } from "ngx-image-compress";
import { SupportingDocumentModel } from "src/app/models/supportingdocument.model";
import { PolicyGeneralService } from "src/app/services/policygeneral.service";
import { SessionService } from "src/app/services/session.service";
import { NumberConstant } from "src/app/constants/number.constant";
import { ClaimProposalModel } from "src/app/models/claimproposal.model";
import { RegexFunction } from "src/app/functions/regex.function";
import { StringConstant } from "src/app/constants/string.constant";
import { PolicyGeneralModel } from "src/app/models/policygeneral.model";
import { ENUM_CLAIMPROPOSAL_SURVEYADDRESS, ENUM_CLAIM_CHANNEL, ENUM_INCIDENT_TYPE, ENUM_RESPONSE_STATE } from "src/app/constants/enum.constant";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { TableModel } from "src/app/models/bases/table.model";
import { ResponseModel } from "src/app/models/response.model";
import { ClaimProposalGalleryModel } from "src/app/models/claimproposalgallery.model";
import { VehicleLocationModel } from "src/app/models/vehiclelocation.model";
import { VehiclelocationService } from "src/app/services/vehiclelocation.service";
import { BranchModel } from "src/app/models/branch.model";
import { BranchCoverageModel } from "src/app/models/branchcoverage.model";
import { BranchService } from "src/app/services/branch.service";
import { LocationService } from "src/app/services/location.service";
import { BranchCoverageService } from "src/app/services/branchcoverage.service";
import { ClaimProposalService } from "src/app/services/claimproposal.service";
import { HorizontalstepperComponent } from "../../includes/horizontalstepper/horizontalstepper.component";
import { BaseAuthourizeDetailComponent } from "../../bases/baseauthourizedetail.component";

//#endregion


//#region COMPONENT

@Component({
	selector: "app-claim",
	templateUrl: "./claim.component.html",
	styleUrls: ["./claim.component.sass"]
})

//#endregion


//#region

export class ClaimComponent extends BaseAuthourizeDetailComponent
{
	//#region DECLARATION

	@ViewChild(HorizontalstepperComponent) private _componentHorizontalStepperInclude!: HorizontalstepperComponent;

	public _arrayStringStepperMV: Array<string>;
	public _arrayStringStepperNonMV: Array<string>;
	public _arrayNumberSupportingDocumentClaimMV: Array<SupportingDocumentModel>;
	public _arrayNumberSupportingDocumentClaimNonMV: Array<SupportingDocumentModel>;
	public _arrayModelPolicyGeneral?: Array<PolicyGeneralModel>;
	public _arrayModelVehicleLocation?: Array<VehicleLocationModel>;
	public _arrayModelBranch: Array<BranchModel>;
	public _arrayModelBranchCoverage: Array<BranchCoverageModel>;

	public _stringConstant = StringConstant;
	public _stringUndefined = undefined;
	public _stringFileTypeAllowed: string = RuleConstant.STRING_IMAGE_TYPE_ALLOWED;
	public _stringTimePrefix: string;
	public _stringTimeSuffix: string;
	public _stringPlaceholderVehicleLocation: string;
	public _stringPlaceholderBranch: string;
	public _stringPlaceholderBranchCoverage: string;

	public _modelClaimProposal: ClaimProposalModel;
	public _modelClaimProposalRequest: ClaimProposalModel;
	public _modelClaimProposalUpload: ClaimProposalModel;
	public _modelClaimProposalResponse: ClaimProposalModel;
	public _modelPolicyGeneral: PolicyGeneralModel;
	public _modelBranch: BranchModel;
	public _modelBranchCoverage: BranchCoverageModel;

	public _numberConstant = NumberConstant;
	public _numberStepperAllMV: number;
	public _numberStepperAllNonMV: number;
	public _numberStepperActive: number;
	public _numberSurveyAddress?: number;

	public _booleanSameAsPolicyholder: boolean;
	public _booleanDriverSameAsPolicyholder: boolean;
	public _booleanSurveySameAsPolicyholder: boolean;
	public _booleanClaimList: boolean;
	public _booleanMV: boolean | undefined;

	public _functionRegex: RegexFunction;

	public _enumIncidentType = ENUM_INCIDENT_TYPE;
	public _enumSurveyAddress = ENUM_CLAIMPROPOSAL_SURVEYADDRESS;

	public _todayDate: Date = new Date();
	public _tomorrowDate: Date;

	file: any;
	predictions?: number[];
	imageDataEvent: any;
	localUrl: any;
	localCompressedURl: any;
	isFlip: boolean = false;
	sizeOfOriginalImage?: number;
	sizeOFCompressedImage?: number;
	imgResultBeforeCompress?: string;
	imgResultAfterCompress?: string;

	//#endregion


	//#region CONSTRUCTOR

	constructor(routeActivated: ActivatedRoute, location: Location, serviceSession: SessionService, public router: Router, private imageCompress: NgxImageCompressService, private _servicePolicyGeneral: PolicyGeneralService, private _serviceVehicleLocation: VehiclelocationService, private _serviceBranch: BranchService, private _locationService: LocationService, private _serviceBranchCoverage: BranchCoverageService, private _serviceClaimProposal: ClaimProposalService)
	{
		super(routeActivated, location, serviceSession, router);

		this._numberStepperAllMV = this._numberConstant.NUMBER_COMPARE_VALUE_FOUR;
		this._numberStepperAllNonMV = this._numberConstant.NUMBER_COMPARE_VALUE_THREE;
		this._numberStepperActive = this._numberConstant.NUMBER_COMPARE_VALUE_ONE;

		this._stringTimePrefix = "12";
		this._stringTimeSuffix = "30";
		this._stringPlaceholderVehicleLocation = StringConstant.STRING_PLACEHOLDER_VEHICLELOCATION;
		this._stringPlaceholderBranch = StringConstant.STRING_PLACEHOLDER_BRANCH;
		this._stringPlaceholderBranchCoverage = StringConstant.STRING_PLACEHOLDER_BRANCHCOVERAGE;

		this._booleanSameAsPolicyholder = false;
		this._booleanDriverSameAsPolicyholder = false;
		this._booleanSurveySameAsPolicyholder = false;
		this._booleanClaimList = true;
		this._booleanMV = undefined;

		this._modelClaimProposal = new ClaimProposalModel();
		this._modelClaimProposalRequest = new ClaimProposalModel();
		this._modelClaimProposalUpload = new ClaimProposalModel();
		this._modelClaimProposalResponse = new ClaimProposalModel();
		this._modelPolicyGeneral = new PolicyGeneralModel();

		this._modelBranch = new BranchModel();
		this._modelBranchCoverage = new BranchCoverageModel();

		this._arrayStringStepperMV = ["Tertanggung", "Kecelakaan", "Survei", "Dokumen Pendukung"];
		this._arrayStringStepperNonMV = ["Pelapor", "Kerugian", "Dokumen Pendukung"];
		this._arrayNumberSupportingDocumentClaimMV = this._modelClaimProposal.setSupportingDocumentMV();
		this._arrayNumberSupportingDocumentClaimNonMV = this._modelClaimProposal.setSupportingDocumentNonMV();
		this._arrayModelPolicyGeneral = [];
		this._arrayModelVehicleLocation = [];
		this._arrayModelBranch = [];
		this._arrayModelBranchCoverage = [];

		const tomorrowDate = new Date();
		tomorrowDate.setDate(this._todayDate.getDate() + 1);

		this._tomorrowDate = tomorrowDate;

		this._functionRegex = new RegexFunction();
	}

	//#endregion


	//#region INIT

	ngOnInit(): void
	{
		this.callSelectVehicleLocation(this);
	}

	//#endregion


	//#region WEBSERVICE

	private callInsertClaimProposalForCustomer(componentCurrent: this): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		this._modelClaimProposalRequest.ReporterMobilePhone = this._modelClaimProposalRequest.ReporterMobilePhone?.toString().getPhoneNumber();
		this._modelClaimProposalRequest.listModelClaimProposalAppeal = undefined;
		this._modelClaimProposalRequest.listModelClaimProposalGallery = undefined;
		this._modelClaimProposalRequest.listModelClaimProposalSupportingGallery = undefined;
		this._modelClaimProposalRequest.listModelSurvey = undefined;
		this._modelClaimProposalRequest.listModelSurveyThirdPartyLiability = undefined;

		this._serviceClaimProposal.insertClaimProposalForCustomer
		({
			success(modelResponseService: ResponseModel): void
			{
				if (modelResponseService.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponseService.Data != null || modelResponseService.Data !== undefined)
					{
						const modelLetterGeneral: ClaimProposalModel = new ClaimProposalModel();

						modelLetterGeneral.setModelFromString(modelResponseService.Data);
						componentCurrent._modelClaimProposalResponse.Token = modelLetterGeneral.Token;
						componentCurrent.callUploadClaimProposal(componentCurrent._modelClaimProposalResponse.Token);
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseService, () => { });
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseService, () => { });
				}
			},
			fail(modelResponseService: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseService, () => { componentCurrent.callInsertClaimProposalForCustomer(componentCurrent); });
			},
			signOut(modelResponseService: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseService, () => { componentCurrent.signOut(); });
			}
		}, this._modelClaimProposalRequest);
	}

	private callInsertClaimProposalNonMotorVehicleForCustomer(componentCurrent: this): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		this._modelClaimProposalRequest.ReporterMobilePhone = this._modelClaimProposalRequest.ReporterMobilePhone?.toString().getPhoneNumber();
		this._modelClaimProposalRequest.Currency = StringConstant.STRING_CURRENCY_IDR_CODE;
		this._modelClaimProposalRequest.listModelClaimProposalAppeal = undefined;
		this._modelClaimProposalRequest.listModelClaimProposalGallery = undefined;
		this._modelClaimProposalRequest.listModelClaimProposalSupportingGallery = undefined;
		this._modelClaimProposalRequest.listModelSurvey = undefined;
		this._modelClaimProposalRequest.listModelSurveyThirdPartyLiability = undefined;

		this._serviceClaimProposal.insertClaimProposalNonMotorVehicleForCustomer
		({
			success(modelResponseService: ResponseModel): void
			{
				if (modelResponseService.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if(componentCurrent._modelClaimProposalUpload.listModelClaimProposalGallery?.length ?? 0 > 0)
					{
						if (modelResponseService.Data != null || modelResponseService.Data !== undefined)
						{
							const modelLetterGeneral: ClaimProposalModel = new ClaimProposalModel();

							modelLetterGeneral.setModelFromString(modelResponseService.Data);
							componentCurrent._modelClaimProposalResponse.Token = modelLetterGeneral.Token;
							componentCurrent.callUploadClaimProposal(componentCurrent._modelClaimProposalResponse.Token);
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseService, () => { });
						}
					}
					else
					{
						modelResponseService.setReturnContentWithTicketNumberClaimProposalModelForClaimNonMV();
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseService, () => { window.location.reload(); });
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseService, () => { });
				}
			},
			fail(modelResponseService: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseService, () => { componentCurrent.callInsertClaimProposalNonMotorVehicleForCustomer(componentCurrent); });
			},
			signOut(modelResponseService: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseService, () => { componentCurrent.signOut(); });
			}
		}, this._modelClaimProposalRequest);
	}

	private callUploadClaimProposal(stringToken: string | undefined): void
	{
		const componentCurrent: ClaimComponent = this;
		this._modelClaimProposalUpload.Token = stringToken;
		const modelResponseRequest: ResponseModel = this._modelClaimProposalUpload.validateTokenForUpdate();

		if (modelResponseRequest.State === ENUM_RESPONSE_STATE.Success)
		{
			this._serviceClaimProposal.uploadClaimProposalForCustomerPublic
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						if(componentCurrent._booleanMV === true)
						{
							modelResponse.setReturnContentWithTicketNumberClaimProposalModelForClaimMV();
						}
						else
						{
							modelResponse.setReturnContentWithTicketNumberClaimProposalModelForClaimNonMV();
						}
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { window.location.reload(); });
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.callUploadClaimProposal(stringToken); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelClaimProposalUpload);
		}
		else
		{
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseRequest, () => { });
		}
	}

	public callSelectPolicyGeneralByPolicyNumberForCustomerClaim(componentCurrent: ClaimComponent, elementHtml: HTMLElement): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		const modelTable: TableModel = new TableModel();
		modelTable.Search = JSON.stringify(componentCurrent._modelPolicyGeneral);

		const modelResponseRequest: ResponseModel = this._modelPolicyGeneral.setValidateCheckPolicyNumber();

		if (modelResponseRequest.State === ENUM_RESPONSE_STATE.Success)
		{
			componentCurrent._servicePolicyGeneral.selectPolicyGeneralByAttributesForCustomer
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						if (modelResponse.Data !== undefined)
						{
							modelTable.setModelFromString(modelResponse.Data);

							if (modelTable.Result !== undefined)
							{
								componentCurrent._arrayModelPolicyGeneral = JSON.parse(modelTable.Result);
								componentCurrent._functionUserInterface.updateLoadingProgress();

								modelTable.Result = undefined;
								if(elementHtml)
								{
									elementHtml.scrollIntoView({behavior:"smooth"});
								}
							}
							else
							{
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
							}
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectPolicyGeneralByPolicyNumberForCustomerClaim(componentCurrent, elementHtml); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, modelTable);
		}
		else
		{
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseRequest, () => { });
		}
	}

	private callSelectVehicleLocation(componentCurrent: this): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		const modelTable: TableModel = new TableModel();
		modelTable.RowPerPage = NumberConstant.NUMBER_VALUE_ROWPERPAGE_FOR_SELECTMAXTABLE;

		componentCurrent._serviceVehicleLocation.selectVehicleLocationForCustomer
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						const modelTableResponse: TableModel = new TableModel();
						modelTableResponse.setModelFromString(modelResponse.Data);

						if (modelTableResponse.Result !== undefined)
						{
							componentCurrent._arrayModelVehicleLocation = JSON.parse(modelTableResponse.Result);
							componentCurrent._functionUserInterface.updateLoadingProgress();
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectVehicleLocation(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, modelTable);
	}

	public callSelectBranchForCustomer(): void
	{
		const componentCurrent: ClaimComponent = this;

		componentCurrent._functionUserInterface.setLoadingProgress(1);

		const modelTable: TableModel = new TableModel();
		modelTable.RowPerPage = NumberConstant.NUMBER_VALUE_ROWPERPAGE_FOR_SELECTMAXTABLE;
		modelTable.Search = JSON.stringify(this._modelClaimProposalRequest.BranchName);
		componentCurrent._arrayModelBranch = [];

		this._serviceBranch.selectBranchForCustomer
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						modelTable.setModelFromString(modelResponse.Data);
						if (modelTable.Result !== undefined)
						{
							componentCurrent._arrayModelBranch = JSON.parse(modelTable.Result);
							componentCurrent._arrayModelBranch = componentCurrent._arrayModelBranch?.filter(i => i.Code !== StringConstant.STRING_FIELD_BRANCHCODE_HEADOFFICE);
							modelTable.Result = undefined;
							componentCurrent._functionUserInterface.updateLoadingProgress();
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
					componentCurrent._functionUserInterface.updateLoadingProgress();
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, modelTable);
	}

	private callSelectBranchCoverage(componentCurrent: this): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		const modelTable: TableModel = new TableModel();
		modelTable.RowPerPage = NumberConstant.NUMBER_VALUE_ROWPERPAGE_FOR_SELECTMAXTABLE;
		componentCurrent._arrayModelBranchCoverage = [];

		componentCurrent._serviceBranchCoverage.selectBranchCoverage
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						const modelTableResponse: TableModel = new TableModel();
						modelTableResponse.setModelFromString(modelResponse.Data);

						if (modelTableResponse.Result !== undefined)
						{
							componentCurrent._arrayModelBranchCoverage = JSON.parse(modelTableResponse.Result);
							componentCurrent._arrayModelBranchCoverage = componentCurrent._arrayModelBranchCoverage?.filter(i => i.BranchCode !== StringConstant.STRING_FIELD_BRANCHCODE_HEADOFFICE);
							componentCurrent._functionUserInterface.updateLoadingProgress();
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
				componentCurrent._functionUserInterface.updateLoadingProgress();
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectBranchCoverage(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, modelTable);
	}

	private callSelectBranchByLocation(long, lat): void
	{
		const componentCurrent: ClaimComponent = this;

		componentCurrent._functionUserInterface.setLoadingProgress(1);

		const modelBranch: BranchModel = new BranchModel();
		modelBranch.Latitude = lat;
		modelBranch.Longitude = long;

		this._serviceBranch.selectBranchByLocationForWebsiteCustomer
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._functionUserInterface.updateLoadingProgress();
						componentCurrent._modelBranch = JSON.parse(modelResponse.Data);
						componentCurrent._modelClaimProposalRequest.SurveyNearestBranchCode = componentCurrent._modelBranch.Code;
						componentCurrent._modelClaimProposalRequest.SurveyNearestBranchName = componentCurrent._modelBranch.Name;
						componentCurrent._modelClaimProposalRequest.SurveyLatitude = modelBranch.Latitude.toString();
						componentCurrent._modelClaimProposalRequest.SurveyLongitude = modelBranch.Longitude.toString();
					}
					else
					{
						componentCurrent._modelClaimProposalRequest.SurveyNearestBranchCode = undefined;
						componentCurrent._modelClaimProposalRequest.SurveyNearestBranchName = undefined;
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
				componentCurrent._functionUserInterface.updateLoadingProgress();
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectBranchByLocation(long, lat); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, modelBranch);
	};

	//#endregion


	//#region GETTER

	public getBooleanIndexEven(numberIndex: number): boolean
	{
		if(numberIndex % 2 === 0)
		{
			return true;
		}
		else
		{
			return false;
		}
	}

	public getArrayLength(array: Array<any> | undefined): boolean
	{
		if(array?.length === 1)
		{
			return true;
		}
		else
		{
			return false;
		}
	}

	public getImageByProductCode(modelPolicyGeneral: PolicyGeneralModel): string
	{
		if(modelPolicyGeneral.ProductName?.toLowerCase().includes("oto"))
		{
			return "../../../../assets/icons/sakina/icon_oto_new.svg";
		}
		else if(modelPolicyGeneral.ProductName?.toLowerCase().includes("asri"))
		{
			return "../../../../assets/icons/sakina/icon_asri_new.svg";
		}
		else if(modelPolicyGeneral.ProductName?.toLowerCase().includes("personal"))
		{
			return "../../../../assets/icons/sakina/icon_diri_yearly.svg";
		}
		else if(modelPolicyGeneral.ProductCode === StringConstant.STRING_VALUE_PRODUCT_ASRI)
		{
			return "../../../../assets/icons/sakina/icon_asri_new.svg";
		}
		else if(modelPolicyGeneral.ProductCode === StringConstant.STRING_VALUE_PRODUCT_OTO)
		{
			return "../../../../assets/icons/sakina/icon_oto_new.svg";
		}
		else if(modelPolicyGeneral.ProductCode === StringConstant.STRING_VALUE_PRODUCT_PERSONALACCIDENT)
		{
			return "../../../../assets/icons/sakina/icon_diri_yearly.svg";
		}
		else
		{
			return "../../../../assets/icons/sakina/icon_product_other.svg";
		}
	}

	public getTimeHours(): Array<string>
	{
		const arrayNumberTimeHours: Array<string> = [];
		const numberMaxTimeHours: number = 24;

		for (let numberIndex = 0; numberIndex < numberMaxTimeHours; numberIndex++)
		{
			if(numberIndex <= 9)
			{
				const stringHour = "0"+numberIndex.toString();
				arrayNumberTimeHours.push(stringHour);
			}
			else
			{
				arrayNumberTimeHours.push(numberIndex.toString());
			}
		}

		return arrayNumberTimeHours;
	}

	public getTimeMinutes(): Array<string>
	{
		const arrayNumberTimeMinutes: Array<string> = [];
		const numberMaxTimeMinutes: number = 60;

		for (let numberIndex = 0; numberIndex < numberMaxTimeMinutes; numberIndex++)
		{
			if(numberIndex <= 9)
			{
				const stringHour = "0"+numberIndex.toString();
				arrayNumberTimeMinutes.push(stringHour);
			}
			else
			{
				arrayNumberTimeMinutes.push(numberIndex.toString());
			}
		}

		return arrayNumberTimeMinutes;
	}

	//#endregion


	//#region SETTER

	setEventEmitterNumber(numberIndex: number): void
	{
		this._numberStepperActive = numberIndex;
	}

	public setNextStep(numberCurrentStep: number): void
	{
		if(numberCurrentStep)
		{
			//#region FORM ONE

			if(numberCurrentStep === this._numberConstant.NUMBER_COMPARE_VALUE_ONE)
			{
				this.setValidationStepOne();
			}

			//#endregion


			//#region FORM TWO

			if(numberCurrentStep === this._numberConstant.NUMBER_COMPARE_VALUE_TWO)
			{
				if(this._booleanMV)
				{
					this._modelClaimProposalRequest.IncidentTime = this._stringTimePrefix +":"+ this._stringTimeSuffix;
					this.setValidationStepTwoClaimMV();
				}
				else
				{
					this._modelClaimProposalRequest.IncidentTime = this._stringTimePrefix +":"+ this._stringTimeSuffix;
					this.setValidationStepTwoClaimNonMV();
				}
			}

			//#endregion


			//#region FORM THREE

			if(numberCurrentStep === this._numberConstant.NUMBER_COMPARE_VALUE_THREE)
			{
				if(this._booleanMV)
				{
					this.setValidationStepThreeMV();
				}
				else
				{
					this.callInsertClaimProposalNonMotorVehicleForCustomer(this);
				}
			}

			//#endregion


			//#region FORM FOUR

			if(numberCurrentStep === this._numberConstant.NUMBER_COMPARE_VALUE_FOUR)
			{
				this.setValidationStepFour();
			}

			//#endregion
		}
	}

	private setNumberNextStep(numberCurrentStep: number): void
	{
		this.setViewFromTopContent();

		this._numberStepperActive = numberCurrentStep + this._numberConstant.NUMBER_COMPARE_VALUE_ONE;
		this._componentHorizontalStepperInclude.setStepTo(this._numberStepperActive);
	}

	setNumberPreviousStep(numberCurrentStep: number): void
	{
		this.setViewFromTopContent();

		this._numberStepperActive = numberCurrentStep - this._numberConstant.NUMBER_COMPARE_VALUE_ONE;
		this._componentHorizontalStepperInclude.setStepTo(this._numberStepperActive);
	}

	protected setViewFromTopContent(): void
	{
		let top = document.getElementById("sectionPackageProduct");
		if (top !== null)
		{
			top.scrollIntoView();
			top = null;
		}
	}

	setBooleanClaimList(booleanClaimList: boolean)
	{
		if (booleanClaimList === true)
		{
			this._booleanClaimList = true;
		}
		else
		{
			this._booleanClaimList = false;
		}
	}

	setDataSameAsPolicyHolder(booleanSameAsPolicyholder: boolean)
	{
		if(booleanSameAsPolicyholder)
		{
			this._modelClaimProposalRequest.ReporterFirstName = this._modelPolicyGeneral.PolicyholderFirstName;
			this._modelClaimProposalRequest.ReporterMiddleName = this._modelPolicyGeneral.PolicyholderMiddleName;
			this._modelClaimProposalRequest.ReporterLastName = this._modelPolicyGeneral.PolicyholderLastName;
			this._modelClaimProposalRequest.ReporterRelationship = "Tertanggung/Pemegang Polis";
		}
		else
		{
			this._modelClaimProposalRequest.ReporterFirstName = "";
			this._modelClaimProposalRequest.ReporterMiddleName = "";
			this._modelClaimProposalRequest.ReporterLastName = "";
			this._modelClaimProposalRequest.ReporterRelationship = undefined;
		}
	}

	setDataDriverSameAsPolicyHolder(booleanDriverSameAsPolicyholder: boolean)
	{
		if(booleanDriverSameAsPolicyholder)
		{
			this._modelClaimProposalRequest.DriverName = this._modelPolicyGeneral.getPolicyholderName();
		}
		else
		{
			this._modelClaimProposalRequest.DriverName = "";
		}
	}

	setDataSurveySameAsPolicyHolder(booleanSurveySameAsPolicyholder: boolean)
	{
		this._modelClaimProposalRequest.SurveySameAsPolicyholder = booleanSurveySameAsPolicyholder;

		if(this._modelClaimProposalRequest.SurveySameAsPolicyholder)
		{
			this._numberSurveyAddress = this._enumSurveyAddress.Branch;
			this.selectSurveyLocation();
			this.setValuePlaceholderBranch(this._modelPolicyGeneral.BranchCode ?? "");
			this._modelClaimProposalRequest.SurveyNearestBranchCode = this._modelPolicyGeneral.BranchCode;
			this._modelClaimProposalRequest.SurveyNearestBranchName = this._modelPolicyGeneral.BranchName;
			this._modelClaimProposalRequest.SurveyAddress = this._modelPolicyGeneral.PolicyholderAddress;
			if(this._arrayModelBranch)
			{
				const modelBranch: BranchModel = this._arrayModelBranch.filter(modelBranchFilter => modelBranchFilter.Code === this._modelClaimProposal.SurveyNearestBranchCode)[0];
				this._modelBranch = modelBranch;
			}
		}
		else
		{
			this._modelBranch = new BranchModel();
			this._modelClaimProposalRequest.SurveyNearestBranchCode = undefined;
			this._modelClaimProposalRequest.SurveyNearestBranchName = undefined;
			this._modelBranch.Code = undefined;
			this._modelBranch.Name = undefined;
			this._numberSurveyAddress = undefined;
		}
	}

	setVehicleLocation(stringVehicleLocation: string): void
	{
		const modelVehicleLocation: VehicleLocationModel = this._arrayModelVehicleLocation?.find(arrayModelVehicleLocation => arrayModelVehicleLocation?.Code === stringVehicleLocation) || new BranchModel();
		this._modelClaimProposalRequest.VehicleLocationName = modelVehicleLocation.Name;
		this._modelClaimProposalRequest.VehicleLocation = modelVehicleLocation.Code;
	}

	setValuePlaceholderVehicleLocation(stringVehicleLocation: string)
	{
		if(stringVehicleLocation !== undefined && stringVehicleLocation !== null && stringVehicleLocation !== "")
		{
			this._stringPlaceholderVehicleLocation = "";
		}
		else
		{
			this._stringPlaceholderVehicleLocation = StringConstant.STRING_PLACEHOLDER_VEHICLELOCATION;
		}
	}

	setValuePlaceholderBranch(stringBranchCode: string)
	{
		if(stringBranchCode !== undefined && stringBranchCode !== null && stringBranchCode !== "")
		{
			this._stringPlaceholderBranch = "";
		}
		else
		{
			this._stringPlaceholderBranch = StringConstant.STRING_PLACEHOLDER_BRANCH;
		}
	}

	setValuePlaceholderBranchCoverage(modelBranchCoverage: BranchCoverageModel)
	{
		if(modelBranchCoverage !== undefined && modelBranchCoverage !== null)
		{
			this._stringPlaceholderBranchCoverage = "";
		}
		else
		{
			this._stringPlaceholderBranchCoverage = StringConstant.STRING_PLACEHOLDER_BRANCHCOVERAGE;
		}
	}

	setBranch(stringBranchCode: string): void
	{
		const modelBranchTemp: BranchModel = this._arrayModelBranch.filter(modelBranchFilter => modelBranchFilter.Code === stringBranchCode)[0];
		if(modelBranchTemp)
		{
			this._modelClaimProposalRequest.SurveyNearestBranchName = modelBranchTemp.Name;
		}
	}

	setCoverage(modelBranchCoverage: BranchCoverageModel): void
	{
		if(modelBranchCoverage.BranchCode !== undefined )
		{
			const stringBranchCode: string = modelBranchCoverage.BranchCode;
			const modelBranchCoverageFilter: BranchCoverageModel = this._arrayModelBranchCoverage.find(arrayModelBranchCoverage => arrayModelBranchCoverage.BranchCode === stringBranchCode) || new BranchCoverageModel();
			this._modelClaimProposalRequest.SurveyNearestBranchCode = modelBranchCoverageFilter.BranchCode;
			this._modelClaimProposalRequest.SurveyNearestBranchName = modelBranchCoverageFilter.BranchName;
		}
	}

	setPolicyGeneralForClaim(modelPolicyGeneral: PolicyGeneralModel): void
	{
		if(this._booleanMV === false)
		{
			this._modelClaimProposal.Currency = this._stringConstant.STRING_CURRENCY_IDR_CODE;
		}

		if(modelPolicyGeneral === null || modelPolicyGeneral === undefined)
		{
		}
		else
		{
			this._modelClaimProposalRequest.ANO = this._modelPolicyGeneral.ANO;
			this._modelClaimProposalRequest.BranchCode = this._modelPolicyGeneral.BranchCode;
			this._modelClaimProposalRequest.BranchName = this._modelPolicyGeneral.BranchName;
			this._modelClaimProposalRequest.Email = this._modelPolicyGeneral.PolicyholderEmail;
			this._modelClaimProposalRequest.InsuredVehicleChasisNumber = this._modelPolicyGeneral.InsuredVehicleChasisNumber;
			this._modelClaimProposalRequest.InsuredVehicleEngineNumber = this._modelPolicyGeneral.InsuredVehicleEngineNumber;
			this._modelClaimProposalRequest.PolicyNumber = this._modelPolicyGeneral.PolicyNumber;
			this._modelClaimProposalRequest.ProfileID = this._modelPolicyGeneral.ProfileID;
			this._modelClaimProposalRequest.ProductName = this._modelPolicyGeneral.ProductName;
			this._modelClaimProposalRequest.ProductCode = this._modelPolicyGeneral.ProductCode;
			this._modelClaimProposalRequest.ReporterMobilePhone = this._modelPolicyGeneral.PolicyholderMobilePhone;
			this._modelClaimProposalRequest.Channel = ENUM_CLAIM_CHANNEL.CustomerSelfServiceMonitor;
		}
	}

	//#endregion


	//#region VALIDATION

	setValidationStepOne()
	{
		const modelResponseValidation: ResponseModel = this._modelClaimProposalRequest.validateReporter();

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			this._numberStepperActive = this._numberConstant.NUMBER_COMPARE_VALUE_ONE;
			this.setNumberNextStep(this._numberStepperActive);
		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponseValidation, () => { });
		}
	}

	setValidationStepTwoClaimMV()
	{
		const modelResponseValidation: ResponseModel = this._modelClaimProposalRequest.validateAccident();

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			this._numberStepperActive = this._numberConstant.NUMBER_COMPARE_VALUE_TWO;
			this.setNumberNextStep(this._numberStepperActive);
		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponseValidation, () => { });
		}
	}

	setValidationStepTwoClaimNonMV()
	{
		const modelResponseValidation: ResponseModel = this._modelClaimProposalRequest.validateLoss();

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			this._numberStepperActive = this._numberConstant.NUMBER_COMPARE_VALUE_TWO;
			this.setNumberNextStep(this._numberStepperActive);
		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponseValidation, () => { });
		}
	}

	setValidationStepThreeMV()
	{
		const modelResponseValidation: ResponseModel = this._modelClaimProposalRequest.validateSurvey();

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			this._numberStepperActive = this._numberConstant.NUMBER_COMPARE_VALUE_THREE;
			this.setNumberNextStep(this._numberStepperActive);
		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponseValidation, () => { });
		}
	}

	setValidationStepFour()
	{
		const modelResponseValidation: ResponseModel = this._modelClaimProposalUpload.validateUploadDocumentForClaimMV();

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			this._numberStepperActive = this._numberConstant.NUMBER_COMPARE_VALUE_FOUR;
			this.callInsertClaimProposalForCustomer(this);
		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponseValidation, () => { });
		}
	}

	//#endregion


	//#region NAVIGATE

	public navigateClaimSubmission(modelPolicyGeneral: PolicyGeneralModel)
	{
		this._modelPolicyGeneral = new PolicyGeneralModel();
		this._modelPolicyGeneral.setModelFromString(JSON.stringify(modelPolicyGeneral));
		this._booleanMV = this._modelPolicyGeneral.setInterfaceClaim();
		this.setBooleanClaimList(false);
		this.setPolicyGeneralForClaim(modelPolicyGeneral);
	}

	//#endregion


	//#region FUNCTION

	getFileNameExtension(stringFileName: string): string
	{
		const fileParts = stringFileName.split(".");
		if (fileParts.length > 1)
		{
		  return fileParts[fileParts.length - 1];
		}

		return "";
	}

	selectFile(event: any, numberUploadType: number)
	{
		const arrayFileUpload: Array<File> = event.target.files;

		if (arrayFileUpload != null && arrayFileUpload !== undefined && arrayFileUpload.length > 0)
		{
			for (const fileUpload of arrayFileUpload)
			{
				if (event.target.files && event.target.files[0])
				{
					const reader = new FileReader();
					// eslint-disable-next-line no-shadow
					reader.onload = (event: any) =>
					{
						this.localUrl = event.target.result;
						this.compressFile(this.localUrl, numberUploadType, fileUpload);
					};
					reader.readAsDataURL(event.target.files[0]);
				}
			}
		}
		else
		{
		}
	}

	selectSurveyLocation(): void
	{
		this._modelClaimProposalRequest.SurveyNearestBranchCode = undefined;
		this._modelClaimProposalRequest.SurveyNearestBranchName = undefined;
		this._modelClaimProposalRequest.SurveyAddress = undefined;
		this._modelBranch = new BranchModel();
		this._modelBranchCoverage = new BranchCoverageModel();

		if(this._numberSurveyAddress === this._enumSurveyAddress.Branch)
		{
			this.callSelectBranchForCustomer();
		}
		else if(this._numberSurveyAddress === this._enumSurveyAddress.UserLocation)
		{
			this.selectLocation();
		}
		else if(this._numberSurveyAddress === this._enumSurveyAddress.BranchCoverage)
		{
			this.callSelectBranchCoverage(this);
		}
		else
		{

		}
	}

	selectLocation(): void
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.MessageTitle = this._stringConstant.STRING_MODULE_CLAIMPROPOSAL;

		this._functionUserInterface.setLoadingProgress(1);
		this._locationService.getPosition().then(
			pos=>
			{
				this.callSelectBranchByLocation(pos.lng, pos.lat);
			}
		).catch(
			e =>
			{
				modelResponse.MessageContent = this._stringConstant.STRING_MESSAGECONTENT_CLAIMPROPOSAL_LOCATIONISBLOCKED+e;
				this._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
			},
		);
	}

	compressFile(image, numberUploadType, fileUpload)
	{
		// eslint-disable-next-line @typescript-eslint/naming-convention
		const orientation = -1;
		this.sizeOfOriginalImage = this.imageCompress.byteCount(image) / (1024 * 1024);

		this.imageCompress.compressFile(image, orientation, 40, 40).then(
			result =>
			{
				this.imgResultAfterCompress = result;
				this.localCompressedURl = result;
				this.sizeOFCompressedImage = this.imageCompress.byteCount(result) / (1024 * 1024);

				const modelClaimProposalGallery: ClaimProposalGalleryModel = new ClaimProposalGalleryModel();

				modelClaimProposalGallery.Format = fileUpload.type;
				modelClaimProposalGallery.FileName = fileUpload.name;
				modelClaimProposalGallery.Size = fileUpload.size;
				modelClaimProposalGallery.Name = fileUpload.name;
				modelClaimProposalGallery.Data = result;
				modelClaimProposalGallery.Type = numberUploadType;
				modelClaimProposalGallery.Extension = this.getFileNameExtension(fileUpload.name);

				this._modelClaimProposalUpload.listModelClaimProposalGallery = this._modelClaimProposalUpload.listModelClaimProposalGallery?.filter(modelClaimProposalGalleryFilter => modelClaimProposalGalleryFilter.Type !== numberUploadType);

				this._modelClaimProposalUpload.listModelClaimProposalGallery?.push(modelClaimProposalGallery);
			}
		);
	}

	//#endregion

}

//#endregion
