<div class="DivMainProductContainer">
	<section class="SectionHeaderContainer">
		<img id="imgIconBrins">
		<img id="imgIconShield">
	</section>

	<!-- CLAIM LIST -->
	<ng-container *ngIf="_booleanClaimList">
		<section class="SectionHeaderProductContainer MarginBottom15">
			<div class="DivVerticalContainer">
				<h3>Klaim</h3>
				<span>Cari tahu lebih lanjut tentang perlindungan Anda</span>
			</div>
		</section>

		<section class="SectionCardContainer">
			<h4>Pengajuan Klaim</h4>
			<div class="DivVerticalContainer MarginBottom10">
				<label for="textPolicyGeneralPolicyholderIdentificationNumber">NIK</label>
				<input type="text" [(ngModel)]="this._modelPolicyGeneral.PolicyholderIdentificationNumber" id="textPolicyGeneralPolicyholderIdentificationNumber" name="modelPolicyGeneralPolicyholderIdentificationNumber" placeholder="Masukkan NIK" (keypress)="this._functionRegex.regexNumber($event)" maxlength="16">
			</div>
			<div class="DivVerticalContainer MarginBottom10">
				<label for="textPolicyGeneralPolicyNumber">Nomor Polis</label>
				<input type="text" [(ngModel)]="this._modelPolicyGeneral.PolicyNumber" id="textPolicyGeneralPolicyNumber" name="modelPolicyGeneralPolicyNumber" placeholder="Masukkan Nomor Polis" (keypress)="this._functionRegex.regexNumber($event)">
			</div>
		</section>

		<section class="SectionButtonNextContainer">
			<input type="button" value="Cari" (click)="this.callSelectPolicyGeneralByPolicyNumberForCustomerClaim(this, sectionTableSearch)" >
		</section>

		<section class="SectionCardList" #sectionTableSearch>
			<div class="DivContainerBackground" *ngIf="this._arrayModelPolicyGeneral !== null && this._arrayModelPolicyGeneral?.length !== 0">
				<ng-container *ngFor="let modelPolicyGeneral of this._arrayModelPolicyGeneral; let numberIndex= index">
					<div class="DivHorizontalContainer DivContainerBackgroundOdd" [ngClass]="{DivContainerBackgroundEven: getBooleanIndexEven(numberIndex)}" [ngClass]="{DivContainerBackgroundFullRounded: getArrayLength(this._arrayModelPolicyGeneral)}">
						<img [src]="getImageByProductCode(modelPolicyGeneral)">

						<div class="DivVerticalContainer DivContentPolicyInformation" (click)="navigateClaimSubmission(modelPolicyGeneral)">
							<h1>REFERENCE NUMBER * {{modelPolicyGeneral.ReferenceNumber}}</h1>
							<h3>{{modelPolicyGeneral.PolicyholderFullName}}</h3>
							<div class="DivHorizontalContainer">
								<div class="DivForm2Column">
									<div class="DivVerticalContainer">
										<p>Policy Number</p>
										<span>{{modelPolicyGeneral.PolicyNumber}}</span>
									</div>
								</div>
								<div class="DivForm2Column">
									<div class="DivVerticalContainer">
										<p>Status</p>
										<span>{{modelPolicyGeneral.PolicyStatus}}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</ng-container>
			</div>
		</section>
	</ng-container>

	<!-- CONTAINER CLAIM -->
	<ng-container *ngIf="!_booleanClaimList">
		<!-- CONTAINER CLAIM MV -->
		<ng-container *ngIf="_booleanMV">
			<section class="SectionHeaderProductContainer">
				<ng-container >
					<img id="imgIconBack" *ngIf="this._numberStepperActive === this._numberConstant.NUMBER_COMPARE_VALUE_ONE" (click)="setBooleanClaimList(true)">
					<img id="imgIconBack" *ngIf="this._numberStepperActive === this._numberConstant.NUMBER_COMPARE_VALUE_TWO" (click)="setNumberPreviousStep(this._numberConstant.NUMBER_COMPARE_VALUE_TWO)">
					<img id="imgIconBack" *ngIf="this._numberStepperActive === this._numberConstant.NUMBER_COMPARE_VALUE_THREE" (click)="setNumberPreviousStep(this._numberConstant.NUMBER_COMPARE_VALUE_THREE)">
					<img id="imgIconBack" *ngIf="this._numberStepperActive === this._numberConstant.NUMBER_COMPARE_VALUE_FOUR" (click)="setNumberPreviousStep(this._numberConstant.NUMBER_COMPARE_VALUE_FOUR)">
				</ng-container>

				<div class="DivVerticalContainer">
					<h3>Klaim</h3>
					<span>Cari tahu lebih lanjut tentang perlindungan Anda</span>
				</div>
			</section>

			<app-horizontalstepper [_arrayContent]="this._arrayStringStepperMV" (_eventEmitterNumber)="setEventEmitterNumber($event);"></app-horizontalstepper>

			<!-- STEP ONE -->
			<ng-container *ngIf="this._numberStepperActive === this._numberConstant.NUMBER_COMPARE_VALUE_ONE">
				<section class="SectionCardContainer">
					<h4>Pelapor</h4>

					<div class="DivVerticalContainer MarginBottom10">
						<label>Data pelapor sama dengan data personal*</label>
						<div class="DivRadioButtonContainer MarginBottom10">
							<label class="LabelRadioButtonContainer" for="radioSameAsPolicyholder">
								<input type="radio" name="radioSameAsPolicyholder" id="radioSameAsPolicyholder" [value]=true [(ngModel)]="_booleanSameAsPolicyholder" (click)="setDataSameAsPolicyHolder(true)">Ya
							</label>

							<label class="LabelRadioButtonContainer" for="radioNotSameAsPolicyholder">
								<input type="radio" name="radioNotSameAsPolicyholder" id="radioNotSameAsPolicyholder" [value]=false [(ngModel)]="_booleanSameAsPolicyholder" (click)="setDataSameAsPolicyHolder(false)">Tidak
							</label>
						</div>
					</div>

					<div class="DivVerticalContainer MarginBottom10">
						<label for="textClaimProposalReporterName">Nama*</label>
						<input type="text" id="textClaimProposalReporterFirstName" name="modelClaimProposalReporterFirstName" placeholder="Masukkan Nama Depan" [(ngModel)]="_modelClaimProposalRequest.ReporterFirstName" (keypress)="_functionRegex.regexAlfabet($event)">
						<input type="text" id="textClaimProposalReporterMiddleName" name="modelClaimProposalReporterMiddleName" placeholder="Masukkan Nama Tengah" [(ngModel)]="_modelClaimProposalRequest.ReporterMiddleName" (keypress)="_functionRegex.regexAlfabet($event)">
						<input type="text" id="textClaimProposalReporterLastName" name="modelClaimProposalReporterLastName" placeholder="Masukkan Nama Belakang" [(ngModel)]="_modelClaimProposalRequest.ReporterLastName" (keypress)="_functionRegex.regexAlfabet($event)">
					</div>

					<div class="DivVerticalContainer MarginBottom10">
						<label>Email*</label>
						<input type="text" name="modelClaimProposalEmail" id="textModelClaimProposalEmail" placeholder="Masukkan email" [(ngModel)]="_modelClaimProposalRequest.ReporterEmail" (keypress)="this._functionRegex.regexEmail($event)">
					</div>

					<div class="DivVerticalContainer MarginBottom10">
						<label for="textClaimProposalReporterMobilePhone">Nomor Handphone*</label>
						<div class="DivHorizontalInputNumberPhone">
							<label>+62</label>
							<input type="number" id="textClaimProposalReporterMobilePhone" name="modelClaimProposalReporterMobilePhone" placeholder="Masukkan Nomor Handphone" maxlength="13" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" (keypress)="this._functionRegex.regexNumber($event)" [(ngModel)]="_modelClaimProposalRequest.ReporterMobilePhone">
						</div>
					</div>

					<div class="DivVerticalContainer MarginBottom10">
						<label for="selectOccupation">Hubungan Pelapor*</label>
						<select name="modelClaimProposalReporterRelationship" id="selectReporterRelationship" [(ngModel)]="_modelClaimProposalRequest.ReporterRelationship"
						[disabled]="_booleanSameAsPolicyholder">
							<option [ngValue]="undefined" hidden>Pilih Hubungan</option>
							<option [ngValue]="'Tertanggung/Pemegang Polis'">Tertanggung/Pemegang Polis</option>
							<option [ngValue]="'Suami/Istri'">Suami/Istri</option>
							<option [ngValue]="'Supir'">Supir</option>
							<option [ngValue]="'Orang Tua'">Orang Tua</option>
							<option [ngValue]="'Anak'">Anak</option>
							<option [ngValue]="'Lainnya'">Lainnya</option>
						</select>
					</div>

					<div class="DivVerticalContainer">
						<label for="textClaimProposalReporterAddress">Alamat*</label>
						<textarea id="textClaimProposalReporterAddress" name="modelClaimProposalReporterAddress" placeholder="Masukkan Alamat" [(ngModel)]="_modelClaimProposalRequest.ReporterAddress" (keypress)="_functionRegex.regexAlfabetNumericAddress($event)"></textarea>
					</div>
				</section>

				<section class="SectionButtonNextContainer">
					<input type="button" value="Lanjutkan" (click)="setNextStep(this._numberConstant.NUMBER_COMPARE_VALUE_ONE)">
				</section>
			</ng-container>

			<!-- STEP TWO -->
			<ng-container *ngIf="this._numberStepperActive === this._numberConstant.NUMBER_COMPARE_VALUE_TWO">
				<section class="SectionCardContainer">
					<h4>Kecelakaan</h4>

					<div class="DivVerticalContainer MarginBottom10">
						<label for="selectIncidentType">Tipe Kecelakaan*</label>
						<select name="modelClaimProposalIncidentType" id="selectIncidentType" [(ngModel)]="_modelClaimProposalRequest.IncidentType">
							<option [ngValue]="undefined">Pilih Tipe Kecelakaan</option>
							<option [ngValue]="_enumIncidentType.Crash">Tabrakan</option>
							<option [ngValue]="_enumIncidentType.ReverseOrFell">Terbalik atau Terjatuh</option>
							<option [ngValue]="_enumIncidentType.CriminalOrCrime">Tindak Pidana atau Kejahatan</option>
							<option [ngValue]="_enumIncidentType.Fire">Kebakaran</option>
							<option [ngValue]="_enumIncidentType.FloodOrNaturalDisaster">Banjir atau Bencana Alam</option>
							<option [ngValue]="_enumIncidentType.Riot">Kerusuhan</option>
							<option [ngValue]="_enumIncidentType.Terrorism">Terorisme</option>
							<option [ngValue]="_enumIncidentType.HitByATreeOrBuilding">Tertimpa Pohon atau Bangunan</option>
							<option [ngValue]="_enumIncidentType.Lost">Kehilangan</option>
						</select>
					</div>

					<div class="DivVerticalContainer MarginBottom10">
						<label for="textIncidentAddress">Alamat Kecelakaan*</label>
						<textarea id="textIncidentAddress" name="modelClaimProposalIncindentAddress" placeholder="Masukkan Alamat Kecelakaan" [(ngModel)]="_modelClaimProposalRequest.IncidentLocation" (keypress)="this._functionRegex.regexAlfanumericSpecialCharacter($event)"></textarea>
					</div>

					<div class="DivVerticalContainer MarginBottom10">
						<label for="textIncidentChronology">Kronologis Kecelakaan*</label>
						<textarea id="textIncidentChronology" name="modelClaimProposalIncidentChronology" placeholder="Masukkan Kronologis Kecelakaan" [(ngModel)]="_modelClaimProposalRequest.IncidentChronology" (keypress)="this._functionRegex.regexAlfanumericSpecialCharacter($event)"></textarea>
					</div>

					<div class="DivVerticalContainer MarginBottom10">
						<label for="dateIncidentDate">Tanggal Terjadi Kecelakaan*</label>
						<mat-form-field appearance="fill">
						<input matInput [matDatepicker]="picker" placeholder="Pilih Tanggal" [(ngModel)]="_modelClaimProposalRequest.IncidentDate" [max]="_todayDate" (click)="picker.open()">
							<mat-datepicker-toggle matIconSuffix [for]="picker">
								<mat-icon matDatepickerToggleIcon>
									<img src="../../../../../assets/icons/sakina/icon_calendar.svg">
								</mat-icon>
							</mat-datepicker-toggle>
							<mat-datepicker #picker></mat-datepicker>
						</mat-form-field>
					</div>

					<div class="DivVerticalContainer">
						<label for="">Waktu Kecelakaan*</label>
						<div class="DivHorizontalInputNumberPhone DivHorizontalInputTime">
							<select name="modelIncidentDatePrefix" id="selectIncidentDatePrefix" class="SelectTimePrefix" [(ngModel)]="_stringTimePrefix">
								<option *ngFor="let stringTimeHours of getTimeHours()" [value]="stringTimeHours">{{ stringTimeHours }}</option>
							</select>
							<span id="spanClaimProposalTime" name="modelClaimProposalTime" class="SpanTimeInfix">:</span>
							<select name="modelIncidentDateSuffix" id="selectIncidentDateSuffix" class="SelectTimeSuffix" [(ngModel)]="_stringTimeSuffix">
								<option *ngFor="let stringTimeMinutes of getTimeMinutes()" [value]="stringTimeMinutes">{{ stringTimeMinutes }}</option>
							</select>
						</div>
					</div>
				</section>

				<section class="SectionCardContainer">
					<h4>Data</h4>

					<div class="DivVerticalContainer MarginBottom10">
						<label>Data pengemudi sama dengan pemegang polis*</label>
						<div class="DivRadioButtonContainer MarginBottom10">
							<label class="LabelRadioButtonContainer" for="radioDriverSameAsPolicyholder">
								<input type="radio" name="radioDriverSameAsPolicyholder" id="radioDriverSameAsPolicyholder" [value]=true [(ngModel)]="_booleanDriverSameAsPolicyholder" (click)="setDataDriverSameAsPolicyHolder(true)">Ya
							</label>

							<label class="LabelRadioButtonContainer" for="radioDriverNotSameAsPolicyholder">
								<input type="radio" name="radioDriverNotSameAsPolicyholder" id="radioDriverNotSameAsPolicyholder" [value]=false [(ngModel)]="_booleanDriverSameAsPolicyholder" (click)="setDataDriverSameAsPolicyHolder(false)">Tidak
							</label>
						</div>
					</div>

					<div class="DivVerticalContainer MarginBottom10">
						<label for="modelClaimProposalDriverName">Nama Pengemudi*</label>
						<input type="text" id="textModelClaimProposalDriverName" name="modelClaimProposalDriverName" placeholder="Masukkan Nama Pengemudi" [(ngModel)]="_modelClaimProposalRequest.DriverName" (keypress)="this._functionRegex.regexAlfabetWithSpace($event)">
					</div>

					<div class="DivVerticalContainer MarginBottom10">
						<label>Nomor SIM Pengemudi*</label>
						<input type="text" id="textModelClaimProposalDriverLicenseNumber" name="modelClaimProposalDriverLicenseNumber"  placeholder="Masukkan Nomor SIM" [(ngModel)]="_modelClaimProposalRequest.DriverLicenseNumber" (keypress)="this._functionRegex.regexNumberWithDash($event)" minlength="12" maxlength="14">
					</div>

					<div class="DivVerticalContainer MarginBottom10">
						<label for="">Kecepatan Kendaraan*</label>
						<div class="DivHorizontalInputNumberPhone">
							<input type="number" id="numberModelClaimProposalVehicleSpeed" name="modelClaimProposalVehicleSpeed"  placeholder="Masukkan Kecepatan Kendaraan" [(ngModel)]="_modelClaimProposalRequest.VehicleSpeed"
									maxlength="3" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" (keypress)="this._functionRegex.regexNumber($event)">
							<span class="SpanLabelVehicleSpeed">KM/H</span>
						</div>
					</div>

					<div class="DivVerticalContainer">
						<label for="selectClaimProposalVehicleLocation">Lokasi Kendaraan*</label>
						<ng-select class="NgSelectVehicleLocation" id="selectClaimProposalVehicleLocation"
							[multiple]="false"
							[searchable]="true"
							[hideSelected]="true"
							[(ngModel)]=_modelClaimProposalRequest.VehicleLocation
							[ngModelOptions]="{standalone: true}"
							[placeholder]="_stringPlaceholderVehicleLocation"
							(change)="setValuePlaceholderVehicleLocation(_modelClaimProposalRequest.VehicleLocation!)"
							(ngModelChange)="setVehicleLocation(_modelClaimProposalRequest.VehicleLocation!)">
							<ng-option class="custom" [value]="modelVehicleLocation.Code" *ngFor="let modelVehicleLocation of _arrayModelVehicleLocation; let numberIndex = index">
								{{ modelVehicleLocation.Name }}
							</ng-option>
						</ng-select>
					</div>
				</section>

				<section class="SectionButtonNextContainer">
					<input type="button" value="Lanjutkan" (click)="setNextStep(this._numberConstant.NUMBER_COMPARE_VALUE_TWO)">
				</section>
			</ng-container>

			<!-- STEP THREE -->
			<ng-container *ngIf="this._numberStepperActive === this._numberConstant.NUMBER_COMPARE_VALUE_THREE">
				<section class="SectionCardContainer">
					<h4>Survei</h4>

					<div class="DivVerticalContainer MarginBottom10">
						<label>Alamat survei sama dengan data personal</label>
						<div class="DivRadioButtonContainer MarginBottom10">
							<label class="LabelRadioButtonContainer" for="radioSameAsPolicyholder">
								<input type="radio" name="radioSurvey" id="radioSurveySameAsPolicyholder" [value]=true [(ngModel)]="_booleanSurveySameAsPolicyholder" (click)="setDataSurveySameAsPolicyHolder(true)">Ya
							</label>

							<label class="LabelRadioButtonContainer" for="radioNotSameAsPolicyholder">
								<input type="radio" name="radioSurvey" id="radioSurveyNotSameAsPolicyholder" [value]=false [(ngModel)]="_booleanSurveySameAsPolicyholder" (click)="setDataSurveySameAsPolicyHolder(false)">Tidak
							</label>
						</div>
					</div>

					<div class="DivVerticalContainer MarginBottom10">
						<label for="selectOccupation">Survei*</label>
						<select id="selectSurveyBranch" name="selectSurveyBranch" [(ngModel)]="_numberSurveyAddress" (change)="selectSurveyLocation()" [disabled]="_modelClaimProposalRequest.SurveySameAsPolicyholder!">
							<option [ngValue]="undefined" hidden>Pilih Survei</option>
							<option [ngValue]="_enumSurveyAddress.Branch">Cabang BRINS</option>
							<option [ngValue]="_enumSurveyAddress.UserLocation">Lokasi Saat Ini</option>
							<option [ngValue]="_enumSurveyAddress.BranchCoverage">Wilayah Layanan</option>
						</select>
					</div>

					<div class="DivVerticalContainer MarginBottom10" *ngIf="_numberSurveyAddress === _enumSurveyAddress.Branch">
						<label for="selectBRINSBranch">Cabang BRINS*</label>
						<ng-select class="NgSelectBRINSBranch" id="selectClaimProposalBRINSBranch"
							[multiple]="false"
							[searchable]="false"
							[hideSelected]="true"
							[(ngModel)]=_modelClaimProposalRequest.SurveyNearestBranchCode
							[ngModelOptions]="{standalone: true}"
							[placeholder]="_stringPlaceholderBranch"
							(change)="setValuePlaceholderBranch(_modelClaimProposalRequest.SurveyNearestBranchCode!)"
							(ngModelChange)="setBranch(_modelClaimProposalRequest.SurveyNearestBranchCode!)"
							[disabled]="_modelClaimProposalRequest.SurveySameAsPolicyholder!">
							<ng-option class="custom" [value]="modelBranch.Code" *ngFor="let modelBranch of _arrayModelBranch; let numberIndex = index">
								{{ modelBranch.Name }}
							</ng-option>
						</ng-select>
					</div>

					<div class="DivVerticalContainer MarginBottom10" *ngIf="_numberSurveyAddress === _enumSurveyAddress.BranchCoverage">
						<label for="selectBranchCoverage">Cakupan Cabang</label>
						<ng-select class="NgSelectBranchCoverage" id="selectBranchCoverage"
							[multiple]="false"
							[searchable]="false"
							[hideSelected]="true"
							[(ngModel)]=_modelBranchCoverage
							[ngModelOptions]="{standalone: true}"
							[placeholder]="_stringPlaceholderBranchCoverage"
							(change)="setValuePlaceholderBranchCoverage(_modelBranchCoverage)"
							(ngModelChange)="setCoverage(_modelBranchCoverage)">
							<ng-option class="custom" [value]="modelBranchCoverage" *ngFor="let modelBranchCoverage of _arrayModelBranchCoverage; let numberIndex = index">
								{{ modelBranchCoverage.AreaName }}
							</ng-option>
						</ng-select>
					</div>

					<div class="DivVerticalContainer MarginBottom10" *ngIf="_numberSurveyAddress === _enumSurveyAddress.UserLocation">
						<label for="selectBRINSBranch">Lokasi Saat Ini</label>
						<span id="spanModelClaimProposalSurverAddress" name="modelClaimProposalSurverAddress">{{ _modelBranch.Name }}</span>
					</div>

					<div class="DivVerticalContainer MarginBottom10">
						<label for="textSurveyAddress">Alamat Survei*</label>
						<textarea type="text" name="modelClaimProposalSurveyAddress" id="textareaClaimProposalSurveyAddress" placeholder="Masukkan Alamat Survei" [(ngModel)]="_modelClaimProposalRequest.SurveyAddress" (keypress)="this._functionRegex.regexAlfanumericSpecialCharacter($event)"></textarea>
					</div>

					<div class="DivVerticalContainer">
						<label for="">Tanggal Survei*</label>
						<mat-form-field appearance="fill">
						<input matInput [matDatepicker]="picker" placeholder="Pilih Tanggal" [(ngModel)]="_modelClaimProposalRequest.SurveyDate" [min]="_tomorrowDate" (click)="picker.open()">
							<mat-datepicker-toggle matIconSuffix [for]="picker">
								<mat-icon matDatepickerToggleIcon>
									<img src="../../../../../assets/icons/sakina/icon_calendar.svg">
								</mat-icon>
							</mat-datepicker-toggle>
							<mat-datepicker #picker></mat-datepicker>
						</mat-form-field>
					</div>
				</section>

				<section class="SectionButtonNextContainer">
					<input type="button" value="Lanjutkan" (click)="setNextStep(this._numberConstant.NUMBER_COMPARE_VALUE_THREE)">
				</section>
			</ng-container>

			<!-- STEP FOUR -->
			<ng-container *ngIf="this._numberStepperActive === this._numberConstant.NUMBER_COMPARE_VALUE_FOUR">
				<section class="SectionCardContainer">
					<h4>Dokumen Pendukung</h4>

					<ng-container  *ngFor="let numberSupportingDocumentClaimMV of this._arrayNumberSupportingDocumentClaimMV; let numberIndex = index">
						<label class="MarginBottom10">{{numberSupportingDocumentClaimMV.Name}}</label>
						<input class="ImgCapture MarginBottom15" type="file" name="pic" accept="image/*" (change)="selectFile($event, numberSupportingDocumentClaimMV.Type!)">

						<ng-container *ngFor="let modelClaimProposalGallery of this._modelClaimProposalUpload.listModelClaimProposalGallery">
							<div  class="DivImgForDisplay" *ngIf="modelClaimProposalGallery.Type === numberSupportingDocumentClaimMV.Type">
								<input class="ImgCaptureForDisplay" #fileAttachment type="file" accept="image/*" (change)="selectFile($event, numberSupportingDocumentClaimMV.Type!)" [ngStyle]="{'background-image': 'url(' + modelClaimProposalGallery.Data + ')'}">
							</div>
						</ng-container>
					</ng-container>
				</section>

				<section class="SectionButtonNextContainer">
					<input type="button" value="Lanjutkan" (click)="setNextStep(this._numberConstant.NUMBER_COMPARE_VALUE_FOUR)">
				</section>
			</ng-container>
		</ng-container>

		<!-- CONTAINER CLAIM NON MV -->
		<ng-container *ngIf="!_booleanMV">
			<section class="SectionHeaderProductContainer">
				<ng-container >
					<img id="imgIconBack" *ngIf="this._numberStepperActive === this._numberConstant.NUMBER_COMPARE_VALUE_ONE" (click)="setBooleanClaimList(true)">
					<img id="imgIconBack" *ngIf="this._numberStepperActive === this._numberConstant.NUMBER_COMPARE_VALUE_TWO" (click)="setNumberPreviousStep(this._numberConstant.NUMBER_COMPARE_VALUE_TWO)">
					<img id="imgIconBack" *ngIf="this._numberStepperActive === this._numberConstant.NUMBER_COMPARE_VALUE_THREE" (click)="setNumberPreviousStep(this._numberConstant.NUMBER_COMPARE_VALUE_THREE)">
				</ng-container>

				<div class="DivVerticalContainer">
					<h3>Klaim</h3>
					<span>Cari tahu lebih lanjut tentang perlindungan Anda</span>
				</div>
			</section>

			<app-horizontalstepper [_arrayContent]="this._arrayStringStepperNonMV" (_eventEmitterNumber)="setEventEmitterNumber($event);"></app-horizontalstepper>

			<!-- STEP ONE -->
			<ng-container *ngIf="this._numberStepperActive === this._numberConstant.NUMBER_COMPARE_VALUE_ONE">
				<section class="SectionCardContainer">
					<h4>Pelapor</h4>

					<div class="DivVerticalContainer MarginBottom10">
						<label>Data pelapor sama dengan data personal*</label>
						<div class="DivRadioButtonContainer MarginBottom10">
							<label class="LabelRadioButtonContainer" for="radioSameAsPolicyholder">
								<input type="radio" name="radioSameAsPolicyholder" id="radioSameAsPolicyholder" [value]=true [(ngModel)]="_booleanSameAsPolicyholder" (click)="setDataSameAsPolicyHolder(true)">Ya
							</label>

							<label class="LabelRadioButtonContainer" for="radioNotSameAsPolicyholder">
								<input type="radio" name="radioNotSameAsPolicyholder" id="radioNotSameAsPolicyholder" [value]=false [(ngModel)]="_booleanSameAsPolicyholder" (click)="setDataSameAsPolicyHolder(false)">Tidak
							</label>
						</div>
					</div>

					<div class="DivVerticalContainer MarginBottom10">
						<label for="textClaimProposalReporterName">Nama*</label>
						<input type="text" id="textClaimProposalReporterFirstName" name="modelClaimProposalReporterFirstName" placeholder="Masukkan Nama Depan" [(ngModel)]="_modelClaimProposalRequest.ReporterFirstName" (keypress)="_functionRegex.regexAlfabet($event)">
						<input type="text" id="textClaimProposalReporterMiddleName" name="modelClaimProposalReporterMiddleName" placeholder="Masukkan Nama Tengah" [(ngModel)]="_modelClaimProposalRequest.ReporterMiddleName" (keypress)="_functionRegex.regexAlfabet($event)">
						<input type="text" id="textClaimProposalReporterLastName" name="modelClaimProposalReporterLastName" placeholder="Masukkan Nama Belakang" [(ngModel)]="_modelClaimProposalRequest.ReporterLastName" (keypress)="_functionRegex.regexAlfabet($event)">
					</div>

					<div class="DivVerticalContainer MarginBottom10">
						<label>Email*</label>
						<input type="text" name="modelClaimProposalEmail" id="textModelClaimProposalEmail" placeholder="Masukkan email" [(ngModel)]="_modelClaimProposalRequest.ReporterEmail" (keypress)="this._functionRegex.regexEmail($event)">
					</div>

					<div class="DivVerticalContainer MarginBottom10">
						<label for="textClaimProposalReporterMobilePhone">Nomor Handphone*</label>
						<div class="DivHorizontalInputNumberPhone">
							<label>+62</label>
							<input type="number" id="textClaimProposalReporterMobilePhone" name="modelClaimProposalReporterMobilePhone" placeholder="Masukkan Nomor Handphone" maxlength="13" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" (keypress)="this._functionRegex.regexNumber($event)" [(ngModel)]="_modelClaimProposalRequest.ReporterMobilePhone">
						</div>
					</div>

					<div class="DivVerticalContainer MarginBottom10">
						<label for="selectOccupation">Hubungan Pelapor*</label>
						<select name="modelClaimProposalReporterRelationship" id="selectReporterRelationship" [(ngModel)]="_modelClaimProposalRequest.ReporterRelationship"
						[disabled]="_booleanSameAsPolicyholder">
							<option [ngValue]="undefined" hidden>Pilih Hubungan</option>
							<option [ngValue]="'Tertanggung/Pemegang Polis'">Tertanggung/Pemegang Polis</option>
							<option [ngValue]="'Suami/Istri'">Suami/Istri</option>
							<option [ngValue]="'Supir'">Supir</option>
							<option [ngValue]="'Orang Tua'">Orang Tua</option>
							<option [ngValue]="'Anak'">Anak</option>
							<option [ngValue]="'Lainnya'">Lainnya</option>
						</select>
					</div>

					<div class="DivVerticalContainer">
						<label for="textClaimProposalReporterAddress">Alamat*</label>
						<textarea id="textClaimProposalReporterAddress" name="modelClaimProposalReporterAddress" placeholder="Masukkan Alamat" [(ngModel)]="_modelClaimProposalRequest.ReporterAddress" (keypress)="_functionRegex.regexAlfabetNumericAddress($event)"></textarea>
					</div>
				</section>

				<section class="SectionButtonNextContainer">
					<input type="button" value="Lanjutkan" (click)="setNextStep(this._numberConstant.NUMBER_COMPARE_VALUE_ONE)">
				</section>
			</ng-container>

			<!-- STEP TWO -->
			<ng-container *ngIf="this._numberStepperActive === this._numberConstant.NUMBER_COMPARE_VALUE_TWO">
				<section class="SectionCardContainer">
					<h4>Informasi Kerugian</h4>

					<div class="DivVerticalContainer MarginBottom10">
						<label for="textIncidentLossAddress">Lokasi Kejadian*</label>
						<input type="text" id="textIncidentLossAddress" name="modelClaimProposalIncidentLossAddress" placeholder="Masukkan Lokasi Kejadian" [(ngModel)]="_modelClaimProposalRequest.IncidentLocation" (keypress)="this._functionRegex.regexAlfabetWithSpace($event)">
					</div>

					<div class="DivVerticalContainer MarginBottom10">
						<label for="textIncidentDetailLossAddress">Detail Lokasi Kejadian*</label>
						<textarea id="textIncidentDetailLossAddress" name="modelClaimProposalIncidentDetailLossAddress" placeholder="Masukkan Detail Lokasi Kejadian" [(ngModel)]="_modelClaimProposalRequest.IncidentDetailLocation" (keypress)="this._functionRegex.regexAlfanumericSpecialCharacter($event)"></textarea>
					</div>

					<div class="DivVerticalContainer MarginBottom10">
						<label for="textareaClaimProposalIncidentCronology">Kronologi Kejadian*</label>
						<textarea id="textareaClaimProposalIncidentCronology" name="modelClaimProposalIncidentCronology" placeholder="Masukkan Kronologi Kejadian" [(ngModel)]="_modelClaimProposalRequest.IncidentChronology" (keypress)="this._functionRegex.regexAlfanumericSpecialCharacter($event)"></textarea>
					</div>

					<div class="DivVerticalContainer MarginBottom10">
						<label for="textareaClaimProposalIncidentValue">Estimasi Nilai Klaim*</label>
						<div class="DivHorizontalInputNumberPhone">
							<label>IDR</label>
							<input type="text" id="textareaClaimProposalIncidentValue" name="modelClaimProposalIncidentValue" placeholder="Masukkan Estimasi Nilai Klaim" mask="separator.2" thousandSeparator="." [(ngModel)]="_modelClaimProposalRequest.Amount"
							oninput="this.value = this.value.replace(/[^0-9.]/g,'')" (keypress)="this._functionRegex.regexNumber($event)">
						</div>
					</div>

					<div class="DivVerticalContainer MarginBottom10">
						<label for="dateIncidentDate">Tanggal Terjadi Kejadian*</label>
						<mat-form-field appearance="fill">
						<input matInput [matDatepicker]="picker" placeholder="Pilih Tanggal" [(ngModel)]="_modelClaimProposalRequest.IncidentDate" [max]="_todayDate" (click)="picker.open()">
							<mat-datepicker-toggle matIconSuffix [for]="picker">
								<mat-icon matDatepickerToggleIcon>
									<img src="../../../../../assets/icons/sakina/icon_calendar.svg">
								</mat-icon>
							</mat-datepicker-toggle>
							<mat-datepicker #picker></mat-datepicker>
						</mat-form-field>
					</div>

					<div class="DivVerticalContainer">
						<label for="">Waktu Kejadian*</label>
						<div class="DivHorizontalInputNumberPhone DivHorizontalInputTime">
							<select name="modelIncidentDatePrefix" id="selectIncidentDatePrefix" class="SelectTimePrefix" [(ngModel)]="_stringTimePrefix">
								<option *ngFor="let stringTimeHours of getTimeHours()" [value]="stringTimeHours">{{ stringTimeHours }}</option>
							</select>
							<span id="spanClaimProposalTime" name="modelClaimProposalTime" class="SpanTimeInfix">:</span>
							<select name="modelIncidentDateSuffix" id="selectIncidentDateSuffix" class="SelectTimeSuffix" [(ngModel)]="_stringTimeSuffix">
								<option *ngFor="let stringTimeMinutes of getTimeMinutes()" [value]="stringTimeMinutes">{{ stringTimeMinutes }}</option>
							</select>
						</div>
					</div>
				</section>

				<section class="SectionButtonNextContainer">
					<input type="button" value="Lanjutkan" (click)="setNextStep(this._numberConstant.NUMBER_COMPARE_VALUE_TWO)">
				</section>
			</ng-container>

			<!-- STEP THREE -->
			<ng-container *ngIf="this._numberStepperActive === this._numberConstant.NUMBER_COMPARE_VALUE_THREE">
				<section class="SectionCardContainer">
					<h4>Dokumen Pendukung</h4>

					<ng-container  *ngFor="let numberSupportingDocumentClaimNonMV of this._arrayNumberSupportingDocumentClaimNonMV; let numberIndex = index">
						<label class="MarginBottom10">{{numberSupportingDocumentClaimNonMV.Name}}</label>
						<input class="ImgCapture MarginBottom15" type="file" name="pic" accept="image/*" (change)="selectFile($event, numberSupportingDocumentClaimNonMV.Type!)">

						<ng-container *ngFor="let modelClaimProposalGallery of this._modelClaimProposalUpload.listModelClaimProposalGallery">
							<div  class="DivImgForDisplay" *ngIf="modelClaimProposalGallery.Type === numberSupportingDocumentClaimNonMV.Type">
								<input class="ImgCaptureForDisplay" #fileAttachment type="file" accept="image/*" (change)="selectFile($event, numberSupportingDocumentClaimNonMV.Type!)" [ngStyle]="{'background-image': 'url(' + modelClaimProposalGallery.Data + ')'}">
							</div>
						</ng-container>
					</ng-container>
				</section>

				<section class="SectionButtonNextContainer">
					<input type="button" value="Lanjutkan" (click)="setNextStep(this._numberConstant.NUMBER_COMPARE_VALUE_THREE)">
				</section>
			</ng-container>
		</ng-container>

	</ng-container>
</div>

