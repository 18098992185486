//#region IMPORT

import { Injectable } from "@angular/core";

//#endregion

//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#region CLASS

export class LocationService
{


	//#region CONSTRUCTOR

	constructor()
	{

	}

	//#endregion

	//#region FUNCTION

	getPosition(): Promise<any>
	{
		return new Promise((resolve, reject) =>
		{
			navigator.geolocation.getCurrentPosition(resp =>
			{

				resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
			},
			err =>
			{
				reject(`ERROR(${err.code}): ${err.message}`);
			},
			{
				enableHighAccuracy: true,
				timeout: 5000,
				maximumAge: 0
			}
			);
		});

	}

	//#endregion

}
