<div class="DivMainProductContainer">
	<section class="SectionHeaderContainer">
		<img id="imgIconBrins" src="../../../../../assets/icons/sakina/icon_brins.png">
		<img id="imgIconShield" src="../../../../../assets/icons/sakina/icon_shield.png">
	</section>

	<section class="SectionCardContainer">
		<h4>Metode Pembayaran</h4>
		<div class="DivVerticalContainer">
			<img id="imgLogoBriva" src="../../../../../assets/icons/sakina/icon_logo_briva.svg">
			<div class="DivVerticalContainer MarginBottom5">
				<label>Nomor Virtual Account</label>
				<div class="DivHorizontalSpaceBetween">
					<span id="spanPaymentMethod">{{_stringPaymentContent}}</span>
					<img id="imgIconCopy" (click)="setToClipBoard(_stringPaymentContent)">
				</div>
			</div>
			<div class="DivVerticalContainer MarginBottom5">
				<label>Total Pembayaran</label>
				<div class="DivHorizontalSpaceBetween">
					<span id="spanPaymentMethod">Rp{{_modelProductGeneralResponse.PremiumTotalAmount | currencyValue | convertEmptyToDash}}</span>
					<img id="imgIconCopy" (click)="setToClipBoard(_modelProductGeneralResponse.PremiumTotalAmount)">
				</div>
			</div>

			<div class="DivVerticalContainer">
				<label>Pembayaran Berakhir pada</label>
				<div class="DivTimerContainer">
					<div class="DivTimerCard">
						<span>{{_numberHours}}</span>
					</div>
					<span>:</span>
					<div class="DivTimerCard">
						<span>{{_numberMinutes}}</span>
					</div>
					<span>:</span>
					<div class="DivTimerCard">
						<span>{{_numberSeconds}}</span>
					</div>
				</div>
			</div>
		</div>
	</section>

	<section class="SectionCardTransparentContainer">
		<h4>Cara Pembayaran</h4>
		<div class="DivTabContainer">
			<button (click)="setTabActive(true)" [ngClass]="_booleanTabPaymentMethod === true ? 'ButtonTabActive' : 'ButtonTabInactive'">ATM</button>
			<button (click)="setTabActive(false)" [ngClass]="_booleanTabPaymentMethod === false ? 'ButtonTabActive' : 'ButtonTabInactive'">BRIVA</button>
		</div>
		<ng-container *ngIf="_booleanTabPaymentMethod">
			<div class="DivHorizontalNumberParagraphContainer PaddingLeftRight10 TransitionLineUp" *ngFor="let stringPaymentMethod of _stringConstant.ARRAY_STRING_PAYMENT_ATM_BRI; let numberIndex = index;">
				<label>{{ numberIndex+1 }}. </label>
				<label>{{ stringPaymentMethod }}</label>
			</div>
		</ng-container>
		<ng-container *ngIf="!_booleanTabPaymentMethod">
			<div class="DivHorizontalNumberParagraphContainer PaddingLeftRight10 TransitionLineUp" *ngFor="let stringPaymentMethod of _stringConstant.ARRAY_STRING_PAYMENT_BRIMO; let numberIndex = index;">
				<label>{{ numberIndex+1 }}. </label>
				<label>{{ stringPaymentMethod }}</label>
			</div>
		</ng-container>
	</section>

	<section class="SectionCardInformation">
		<div class="DivHeaderInformation">
			<img id="imgIconInformation" src="../../../../../assets/icons/sakina/icon_information.png">
			<h5>Informasi</h5>
			<img id="imgIconSideInformation" src="../../../../../assets/icons/sakina/icon_side_information.png">
		</div>
		<div class="DivHorizontalNumberParagraphContainer PaddingLeftRight10">
			<p>1. </p>
			<p>Pastikan nomor BRIVA pada halaman ini telah Anda salin untuk melanjutkan ke proses pembayaran. Nomor BRIVA anda telah terkirim juga ke email.</p>
		</div>
		<div class="DivHorizontalNumberParagraphContainer PaddingLeftRight10">
			<p>2. </p>
			<p>Bukti kepesertaan elektronik akan dikirimkan melalui email yang telah didaftarkan, <b>apabila proses pembayaran telah selesai dilakukan.</b></p>
		</div>
		<div class="DivHorizontalNumberParagraphContainer PaddingLeftRight10">
			<p>3. </p>
			<p>Jika Anda membutuhkan informasi lebih lanjut, silakan menghubungi Call Center kami di nomor 14081 (Senin – Jumat, pukul 8.00 – 17.00 WIB).</p>
		</div>
	</section>

	<section class="SectionButtonNextContainer">
		<input type="button" value="Konfirmasi Pembayaran" (click)="callConfirmPayment()">
	</section>
</div>