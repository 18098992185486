import { BaseModel } from "./bases/base.model";
//#region CLASS

export class PremiumModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for premium model, used for simulate premium.
		Author: Ibrahim Aziz.
		Created on : Monday, 19 October 2020.			Updated on : Monday, 19 October 2020.
		Created by : Ibrahim Aziz.						Updated by : Ibrahim Aziz.
		Version : 1.0:1.
	*/

	Formula?: string;
	PremiumBasicAmount?: number;
	override AdministrationAmount?: number;
	StampAmount?: number;
	LossPercent?: number;
	AdjectivePercent?: number;
	LoadingAmount?: number;
	Code?: string;
	Risk?: string;
	RateAmount?: number;
	Unit?: string;
	ProrateAmount?: number;
	Category?: string;
	Interest?: string;

	/* Attribute - END */
}

//#endregion