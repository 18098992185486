//#region IMPORT

import { Router } from "@angular/router";
import { UserInterfaceFunction } from "src/app/functions/userinterface.function";
import { SessionService } from "src/app/services/session.service";

//#endregion


//#region CLASS

export class BasePublicComponent
{
	//#region DECLARATION

	protected _functionUserInterface: UserInterfaceFunction;

	//#endregion


	//#region CONSTRUCTOR

	constructor(protected _serviceSession: SessionService, protected _router: Router)
	{
		this._functionUserInterface = new UserInterfaceFunction();
	}

	//#endregion


	//#region FUNCTION

	protected goToSignInWithClearSession(): void
	{
		this._serviceSession.clearKey();
		this._serviceSession.clearSession();
		this._router.navigate(["sessionexpired"]);
	}

	//#endregion
}

//#endregion