//#region IMPORT

import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";

//#endregion


//#region COMPONENT

@Component({
	selector: "app-horizontalstepper",
	templateUrl: "./horizontalstepper.component.html",
	styleUrls: ["./horizontalstepper.component.sass"]
})

//#endregion


//#region CLASS

export class HorizontalstepperComponent implements OnInit
{
	//#region DECLARATION

	@Input() public _numberStep: number;
	@Input() public _numberMaxDraft: number; // terimaan number max draft dari masing2 draft
	@Input() public _arrayContent: Array<string>;
	@Output() public _eventEmitterNumber: EventEmitter<number> = new EventEmitter<number>();
	public _numberToggle: number;

	//#endregion


	//#region CONSTRUCTOR

	constructor()
	{
		this._numberMaxDraft = 1;
		this._numberToggle = 1;
		this._numberStep = 1;
		this._arrayContent = [""];
	}

	//#endregion


	//#region INITIALIZE

	ngOnInit(): void
	{
	}

	//#endregion


	//#region FUNCTION


	//#endregion


	//#region SETTER

	private eventEmitterSetStep(numberIndex: number): void
	{
		this._eventEmitterNumber.emit(numberIndex);
	}

	public setStepTo(numberIndex: number): void
	{
		this._numberToggle = numberIndex;
		this.eventEmitterSetStep(numberIndex);
	}

	//#endregion
}

//#endregion