//#region IMPORT

import { BaseModel } from "./bases/base.model";

//#endregion


//#region CLASS

export class BranchCoverageModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for branch coverage model, used for package information and link to product.
		Author: Eka Saputra and Ibrahim Aziz.
		Created on : Thursday, 23 September 2021. 			Updated on : Thursday, 23 September 2021.
		Created by : Eka Saputra.						Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

	ID?: number;
	BranchCode?: string;
	BranchName?: string;
	AreaCode?: string;
	AreaName?: string;

	/* Attribute - END */
}

//#endregion