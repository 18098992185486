//#region IMPORT

import * as moment from "moment";
import { ENUM_GENDER_TYPE, ENUM_IDENTIFICATION_TYPE, ENUM_MARITAL_STATUS, ENUM_PROJECT_TYPE, ENUM_RESPONSE_STATE, ENUM_SPPA_STATUS } from "../constants/enum.constant";
import { RuleConstant } from "../constants/rule.constant";
import { StringConstant } from "../constants/string.constant";
import { GeneralModel } from "./bases/general.model";
import { DiscountModel } from "./discount.model";
import { ProductAsriModel } from "./productasri.model";
import { ProductBicycleModel } from "./productbicycle.model";
import { ProductOtoModel } from "./productoto.model";
import { ProductPersonalAccidentModel } from "./productpersonalaccident.model";
import { ResponseModel } from "./response.model";
import { UserModel } from "./user.model";
import { VirtualAccountModel } from "./virtualaccount.model";

//#endregion


//#region CLASS

export class ProductGeneralModel extends GeneralModel
{
	/*
		Attribute - START
		Description : Attribute for product general model, used for contain general information to cover specific product.
		Author: Andri Septiawan and Ibrahim Aziz.
		Created on : Tuesday, 29 December 2020.		    	Updated on : Wednesday, 6 January 2021.
		Created by : Andri Septiawan.						Updated by : Ibrahim Aziz.
		Version : 1.0:1.
	*/

	/* POLICY DETAIL - START */

	ID?: number;
	ProductCode?: string;
	ProductName?: string;
	ReferenceNumber?: string;
	PolicyNumber?: string;
	PolicyID?: number;
	Status?: ENUM_SPPA_STATUS;
	PolicyStartDate?: Date;
	PolicyEndDate?: Date;
	LeadToken?: string;

	CustomerID?: number;
	RelationshipManagerID?: number;
	Token?: string;

	Disclaimer?: boolean;
	Note?: string;
	ProjectSource?: ENUM_PROJECT_TYPE;

	/* POLICY DETAIL - END */

	/* POLICYHOLDER - START */

	PolicyholderFirstName?: string;
	PolicyholderMiddleName?: string;
	PolicyholderLastName?: string;
	PolicyholderBirthDate?: any;
	PolicyholderBirthPlace?: string;
	PolicyholderGender?: ENUM_GENDER_TYPE;
	PolicyholderTelephone?: string;
	PolicyholderMobilePhone?: string;
	PolicyholderEmail?: string;
	PolicyholderEmailForLocalStorage?: string;

	PolicyholderIdentificationNumber?: string;
	PolicyholderIdentificationType?: ENUM_IDENTIFICATION_TYPE;

	PolicyholderMaritalStatus?: ENUM_MARITAL_STATUS;
	PolicyholderCitizenshipStatus?: string;
	PolicyholderOccupationStatusCode?: string;
	PolicyholderOccupationStatusName?: string;

	PolicyholderAddress?: string;
	PolicyholderCountryID?: number;
	PolicyholderProvinceID?: number;
	PolicyholderCityID?: number;
	PolicyholderSubDistrictID?: number;
	PolicyholderVillageID?: number;
	PolicyholderHamletCode?: string;
	PolicyholderNeighbourhoodCode?: string;
	PolicyholderPostalID?: number;
	PolicyholderLatitude?: number;
	PolicyholderLongitude?: number;
	PolicyholderBranchCode?: string;
	PolicyholderBranchName?: string;
	PolicyholderPotentialAmount?: number;

	PolicyholderPostalName?: string;
	PolicyholderPostalCode?: string;
	PolicyholderProvinceCode?: string;
	PolicyholderProvinceName?: string;
	PolicyholderCityCode?: string;
	PolicyholderCityName?: string;

	PolicyholderMothersMaidenName?: string;
	PolicyholderSourceOfIncome?: string;
	PolicyholderAverageIncomePerYear?: string;
	PolicyholderInsurancePurpose?: string;

	/* POLICYHOLDER - END */

	/* PAYMENT - START */

	PremiumCurrency?: string;
	PremiumAmount?: any;

	DiscountCode?: string;
	DiscountCurrency?: string;
	DiscountPercent?: string;
	DiscountAmount?: number;

	CommissionCode?: string;
	CommissionPercent?: number;
	CommissionCurrency?: string;
	CommmisionAmount?: number;

	AdministrationAmount?: number;
	StampAmount?: number;
	PremiumTotalAmount?: number;
	PremiumTotalAmountWithDiscount?: number;

	InvoiceURL?: string;
	InvoiceExpiredDate?: Date;
	InvoiceID?: string;
	CoverageCode?: string;
	PaymentAccountID?: number;

	VirtualAccountID?: number;
	ReferralCode?: string;

	/* PAYMENT - END */

	/* TRACKING - START */

	SavedBy?: number;
	SavedOn?: Date;
	SubmittedBy?: number;
	SubmittedOn?: Date;
	SurveyedBy?: number;
	SurveyedOn?: Date;
	ReviewedBy?: number;
	ReviewedOn?: Date;
	PaidBy?: number;
	PaidOn?: Date;
	CompletedBy?: number;
	CompletedOn?: Date;

	/* TRACKING - END */

	/* CARE TECH - START */

	ANO?: string;
	INO?: string;
	SourceID?: string;

	/* CARE TECH - END */

	/* NOT MAPPED - START */

	modelUser?: UserModel;
	modelProductAsri?: ProductAsriModel;
	modelProductOto?: ProductOtoModel;
	modelProductBicycle?: ProductBicycleModel;
	modelProductPersonalAccident?: ProductPersonalAccidentModel;
	modelVirtualAccount?: VirtualAccountModel;
	modelDiscount?: DiscountModel;

	stringBirthDate?: string;

	/* NOT MAPPED - END */

	/* Attribute - END */


	//#region CONSTRUCTOR

	constructor()
	{
		super();
	}

	//#endregion


	//#region FUNCTION

	getClearCalculate(stringCurrency: string = StringConstant.STRING_CURRENCY_IDR_CODE): void
	{
		this.PremiumAmount = 0;
		this.AdministrationAmount = 0;
		this.StampAmount = 0;
		this.PremiumTotalAmount = 0;
		this.DiscountAmount = 0;
		this.PremiumCurrency = stringCurrency;
		this.DiscountCurrency = stringCurrency;
	}

	premiumTotalAmount(): void
	{
		if (this.AdministrationAmount !== undefined && this.StampAmount !== undefined && this.PremiumAmount !== undefined && this.DiscountAmount !== undefined)
		{
			this.PremiumTotalAmount = (this.AdministrationAmount + this.StampAmount + this.PremiumAmount) - this.DiscountAmount;
		}
		else
		{

		}
	}

	//#endregion


	//#region VALIDATE

	validatePolicyholderEmail(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("User");
		const regularExpression: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_EMAIL);

		if (this.PolicyholderEmail == null || this.PolicyholderEmail === undefined || this.PolicyholderEmail === "")
		{
			modelResponse.MessageContent = "Email tidak boleh kosong.";
		}
		else if (regularExpression.test(this.PolicyholderEmail))
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{
			modelResponse.MessageContent = "Enter a valid email.";
		}

		return modelResponse;
	}


	validateForPolicyHolder(booleanGender: boolean | undefined): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		const regularExpressionForEmail: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_EMAIL);
		const regularExpressionForAddress: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ADDRESS);
		const regularExpressionAlphabet: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABETWITHOUTEMOJI);
		const regularExpressionNumeric: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_NUMERIC);

		const dateBirthdate: any = moment(this.PolicyholderBirthDate).add(17, "years") <= moment();

		modelResponse.setForValidation("Product General");

		if (this.PolicyholderFirstName == null || this.PolicyholderFirstName === undefined)
		{
			modelResponse.MessageContent = "Nama Depan tidak boleh kosong.";
			return modelResponse;
		}
		else if (this.PolicyholderFirstName.length > 40)
		{
			modelResponse.MessageContent = "Nama Depan tidak boleh lebih dari 40 karakter";
			return modelResponse;
		}
		else if (this.PolicyholderFirstName !== undefined && this.PolicyholderFirstName !== "" && !regularExpressionAlphabet.test(this.PolicyholderFirstName))
		{
			modelResponse.MessageContent = "Nama Depan mengandung karakter yang tidak diizinkan";
			return modelResponse;
		}

		else if (this.PolicyholderMiddleName !== undefined && this.PolicyholderMiddleName !== "" && !regularExpressionAlphabet.test(this.PolicyholderMiddleName))
		{
			modelResponse.MessageContent = "Nama Tengah mengandung karakter yang tidak diizinkan";
			return modelResponse;
		}

		else if (this.PolicyholderLastName !== undefined && this.PolicyholderLastName !== "" && !regularExpressionAlphabet.test(this.PolicyholderLastName))
		{
			modelResponse.MessageContent = "Nama Belakang mengandung karakter yang tidak diizinkan";
			return modelResponse;
		}

		else if (this.PolicyholderBirthDate == null || this.PolicyholderBirthDate === undefined)
		{
			modelResponse.MessageContent = "Tanggal Lahir tidak boleh kosong.";
			return modelResponse;
		}
		else if (!dateBirthdate)
		{
			modelResponse.MessageContent = "Umur Policyholder minimal 17 tahun.";
			return modelResponse;
		}

		else if (booleanGender === undefined || booleanGender === null)
		{
			modelResponse.MessageContent = "Jenis Kelamin tidak boleh kosong.";
			return modelResponse;
		}

		else if (this.PolicyholderIdentificationNumber == null || this.PolicyholderIdentificationNumber === undefined)
		{
			modelResponse.MessageContent = "KTP tidak boleh kosong.";
			return modelResponse;
		}
		else if (this.PolicyholderIdentificationNumber?.length < 16 || this.PolicyholderIdentificationNumber?.length > 16)
		{
			modelResponse.MessageContent = "KTP harus terdiri 16 digit";
			return modelResponse;
		}
		else if (!regularExpressionNumeric.test(this.PolicyholderIdentificationNumber))
		{
			modelResponse.MessageContent = "KTP mengandung karakter yang tidak diizinkan";
			return modelResponse;
		}

		else if (this.PolicyholderGender == null || this.PolicyholderGender === undefined)
		{
			modelResponse.MessageContent = "Jenis Kelamin tidak boleh kosong.";
			return modelResponse;
		}

		else if (this.PolicyholderOccupationStatusCode == null || this.PolicyholderOccupationStatusCode === undefined || this.PolicyholderOccupationStatusName == null || this.PolicyholderOccupationStatusName === undefined)
		{
			modelResponse.MessageContent = "Pekerjaan tidak boleh kosong.";
			return modelResponse;
		}

		else if (this.PolicyholderMaritalStatus == null || this.PolicyholderMaritalStatus === undefined)
		{
			modelResponse.MessageContent = "Status Perkawinan tidak boleh kosong.";
			return modelResponse;
		}

		else if (this.PolicyholderCitizenshipStatus == null || this.PolicyholderCitizenshipStatus === undefined)
		{
			modelResponse.MessageContent = "Status Kewarganegaraan tidak boleh kosong.";
			return modelResponse;
		}
		else if (this.PolicyholderCitizenshipStatus !== undefined && this.PolicyholderCitizenshipStatus !== "" && !regularExpressionAlphabet.test(this.PolicyholderCitizenshipStatus))
		{
			modelResponse.MessageContent = "Status Kewarganegaraan mengandung karakter yang tidak diizinkan";
			return modelResponse;
		}

		else if (this.PolicyholderMothersMaidenName === undefined || this.PolicyholderMothersMaidenName === "" || this.PolicyholderMothersMaidenName === null)
		{
			modelResponse.MessageContent = "Nama Ibu Kandung tidak boleh kosong";
			return modelResponse;
		}
		else if (this.PolicyholderMothersMaidenName !== undefined && this.PolicyholderMothersMaidenName !== "" && !regularExpressionAlphabet.test(this.PolicyholderMothersMaidenName))
		{
			modelResponse.MessageContent = "Nama Ibu Kandung mengandung karakter yang tidak diizinkan";
			return modelResponse;
		}

		else if (this.PolicyholderSourceOfIncome === undefined || this.PolicyholderSourceOfIncome === "" || this.PolicyholderSourceOfIncome === null)
		{
			modelResponse.MessageContent = "Sumber Penghasilan tidak boleh kosong";
			return modelResponse;
		}

		else if (this.PolicyholderAverageIncomePerYear === undefined || this.PolicyholderAverageIncomePerYear === "" || this.PolicyholderAverageIncomePerYear === null)
		{
			modelResponse.MessageContent = "Penghasilan rata-rata per tahun tidak boleh kosong";
			return modelResponse;
		}

		else if (this.PolicyholderInsurancePurpose === undefined || this.PolicyholderInsurancePurpose === "" || this.PolicyholderInsurancePurpose === null)
		{
			modelResponse.MessageContent = "Tujuan Asuransi tidak boleh kosong";
			return modelResponse;
		}
		else if (this.PolicyholderInsurancePurpose !== undefined && this.PolicyholderInsurancePurpose !== "" && !regularExpressionForAddress.test(this.PolicyholderInsurancePurpose))
		{
			modelResponse.MessageContent = "Tujuan Asuransi mengandung karakter yang tidak diizinkan";
			return modelResponse;
		}

		else if (this.PolicyholderPostalCode == null || this.PolicyholderPostalCode === undefined)
		{
			modelResponse.MessageContent = "Kode Pos tidak boleh kosong.";
			return modelResponse;
		}
		else if (this.PolicyholderCityName == null || this.PolicyholderCityName === undefined)
		{
			modelResponse.MessageContent = "Kota tidak boleh kosong.";
			return modelResponse;
		}
		else if (this.PolicyholderPostalName == null || this.PolicyholderPostalName === undefined)
		{
			modelResponse.MessageContent = "Lokasi tidak boleh kosong.";
			return modelResponse;
		}
		else if (this.PolicyholderProvinceName == null || this.PolicyholderProvinceName === undefined)
		{
			modelResponse.MessageContent = "Provinsi tidak boleh kosong.";
			return modelResponse;
		}

		else if (this.PolicyholderAddress == null || this.PolicyholderAddress === undefined)
		{
			modelResponse.MessageContent = "Alamat tidak boleh kosong.";
			return modelResponse;
		}
		else if (this.PolicyholderAddress.length < 10)
		{
			modelResponse.MessageContent = "Alamat harus terdiri minimal 10 karakter";
			return modelResponse;
		}
		else if (!regularExpressionForAddress.test(this.PolicyholderAddress))
		{
			modelResponse.MessageContent = "Alamat mengandung karakter yang tidak diizinkan.";
			return modelResponse;
		}

		else if (this.PolicyholderEmail == null || this.PolicyholderEmail === undefined)
		{
			modelResponse.MessageContent = "Email tidak boleh kosong.";
			return modelResponse;
		}
		else if (!regularExpressionForEmail.test(this.PolicyholderEmail))
		{
			modelResponse.MessageContent = "Email invalid";
			return modelResponse;
		}

		else if (this.PolicyholderMobilePhone == null || this.PolicyholderMobilePhone === undefined)
		{
			modelResponse.MessageContent = "Nomor telepon tidak boleh kosong.";
			return modelResponse;
		}
		else if(this.PolicyholderMobilePhone.length <= RuleConstant.NUMBER_MOBILEPHONE_LENGTHMINIMUM)
		{
			modelResponse.MessageContent = "Nomor telepon tidak boleh kurang dari "+RuleConstant.NUMBER_MOBILEPHONE_LENGTHMINIMUM+" digit.";
			return modelResponse;
		}
		else if(this.PolicyholderMobilePhone.length >= RuleConstant.NUMBER_MOBILEPHONE_LENGTHMAXIMUM)
		{
			modelResponse.MessageContent = "Nomor telepon tidak boleh lebih dari "+RuleConstant.NUMBER_MOBILEPHONE_LENGTHMAXIMUM+" digit.";
			return modelResponse;
		}

		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			return modelResponse;
		}
	}


	validateForPolicyHolderForButton(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if ((this.PolicyholderFirstName == null || this.PolicyholderFirstName === undefined) || (this.PolicyholderLastName == null || this.PolicyholderLastName === undefined) || (this.PolicyholderIdentificationNumber == null || this.PolicyholderIdentificationNumber === undefined) || (this.PolicyholderAddress == null || this.PolicyholderAddress === undefined) || (this.PolicyholderEmail == null || this.PolicyholderEmail === undefined) || (this.PolicyholderMobilePhone == null || this.PolicyholderMobilePhone === undefined))
		{
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			return modelResponse;
		}
	}



	//#region SETTER

	setModelVirtualAccount(): void
	{
		const stringModelVirtualAccount: string = JSON.stringify(this.modelVirtualAccount);
		this.modelVirtualAccount = new VirtualAccountModel();
		this.modelVirtualAccount.setModelFromString(stringModelVirtualAccount);
	}

	setModelProductAsri(): void
	{
		const stringModelProductAsri: string = JSON.stringify(this.modelProductAsri);
		this.modelProductAsri = new ProductAsriModel();
		this.modelProductAsri.setModelFromString(stringModelProductAsri);
	}

	setModelProductOto(): void
	{
		const stringModelProductOto: string = JSON.stringify(this.modelProductOto);
		this.modelProductOto = new ProductOtoModel();
		this.modelProductOto.setModelFromString(stringModelProductOto);
	}

	setModelProductBicycle(): void
	{
		const stringModelProductBicycle: string = JSON.stringify(this.modelProductBicycle);
		this.modelProductBicycle = new ProductBicycleModel();
		this.modelProductBicycle.setModelFromString(stringModelProductBicycle);
	}

	setModelProductPersonalAccident(): void
	{
		const stringModelProductPersonalAccident: string = JSON.stringify(this.modelProductPersonalAccident);
		this.modelProductPersonalAccident = new ProductPersonalAccidentModel();
		this.modelProductPersonalAccident.setModelFromString(stringModelProductPersonalAccident);
	}

	//#endregion



	//#endregion
}

//#endregion
