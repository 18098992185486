//#region IMPORT

import { CurrencyPipe, DatePipe, DecimalPipe } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatInputModule } from "@angular/material/input";
import { MatSliderModule } from "@angular/material/slider";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxImageCompressService } from "ngx-image-compress";
import { NgSelectModule } from "@ng-select/ng-select";
import { WebcamModule } from "ngx-webcam";
import { NgxMaskDirective, NgxMaskPipe } from "ngx-mask";
import { provideEnvironmentNgxMask } from "ngx-mask";
import { IConfig } from "ngx-mask";
import { CustomcurrencyDirective } from "../functions/directives/customcurrency.directive";
import { PipeModule } from "../functions/pipes/pipe.module";
import { AppRoutingModule } from "./app-routing.module";
import { HorizontalstepperComponent } from "./includes/horizontalstepper/horizontalstepper.component";
import { PaymentComponent } from "./payment/payment.component";
import { ProductAsriYearlyComponent } from "./product/productasri/productasriyearly/productasriyearly.component";
import { AppComponent } from "./root/app.component";
import { ProductOtoYearlyComponent } from "./product/productoto/productotoyearly/productotoyearly.component";
import { ClaimComponent } from "./claim/claim/claim.component";
import { ProductBicycleYearlyComponent } from "./product/productbicycle/productbicycleyearly/productbicycleyearly.component";
import { ProductPersonalAccidentYearlyComponent } from "./product/productpersonalaccident/productpersonalaccident/productpersonalaccident.component";

//#endregion


//#region MODULE

const maskConfig: Partial<IConfig> =
	{
		validation: false,
  	};


@NgModule
(
	{
		declarations:
		[
			AppComponent,
			CustomcurrencyDirective,
			HorizontalstepperComponent,
      		ClaimComponent,
			ProductAsriYearlyComponent,
			ProductOtoYearlyComponent,
			ProductBicycleYearlyComponent,
			ProductPersonalAccidentYearlyComponent,
			PaymentComponent,
		],
		imports:
		[
			BrowserModule,
			AppRoutingModule,
			FormsModule,
			HttpClientModule,
			PipeModule,
			BrowserAnimationsModule,
			MatNativeDateModule,
			MatInputModule,
			MatSliderModule,
			MatDatepickerModule,
			MatDialogModule,
			ReactiveFormsModule,
			WebcamModule,
			MatDialogModule,
			NgxMaskDirective,
    		NgxMaskPipe,
			NgSelectModule,
		],
		providers: [provideEnvironmentNgxMask(maskConfig), CurrencyPipe, NgxImageCompressService, DecimalPipe, DatePipe, {
			provide: MatDialogRef,
			useValue: {}
		},],
		bootstrap: [AppComponent],
	}
)

//#endregion


//#region CLASS

export class AppModule { }

//#endregion
