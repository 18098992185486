//#region IMPORT

import { ENUM_CLAIMPROPOSAL_STATUS, ENUM_CLAIM_CHANNEL, ENUM_GENDER_TYPE, ENUM_IDENTIFICATION_TYPE, ENUM_INCIDENT_TYPE, ENUM_PHOTO_TYPE, ENUM_POLICY_STATUS, ENUM_RESPONSE_STATE, ENUM_REVIEW_STATE } from "../constants/enum.constant";
import { RuleConstant } from "../constants/rule.constant";
import { StringConstant } from "../constants/string.constant";
import { ConvertByteForPreview } from "../functions/pipes/converter.pipe";
import { BaseModel } from "./bases/base.model";
import { ClaimProposalAppealModel } from "./claimproposalappeal.model";
import { ClaimProposalGalleryModel } from "./claimproposalgallery.model";
import { ClaimProposalSupportingGalleryModel } from "./claimproposalsupportinggallery.model";
import { ResponseModel } from "./response.model";
import { SupportingDocumentModel } from "./supportingdocument.model";
import { SurveyModel } from "./survey.model";
import { SurveyThirdPartyLiabilityModel } from "./surveythirdpartyliability.model";
import { UserModel } from "./user.model";
import { ClaimProposalSatisfactionModel } from "./claimproposalsatisfaction.model";
import { ClaimProposalTrackingModel } from "./claimproposaltracking.model";

//#endregion


//#region CLASS

export class ClaimProposalModel extends BaseModel
{

	ID?: number;
	ProfileID?: string;
	BranchCreatorCode?: string;
	BranchCreatorName?: string;
	CertificateNumber?: string;
	TicketNumber?: string;
	VehicleLicensePlate?: string;
	Token?: string;
	PolicyNumber?: string;
	ANO?: string;
	PolicyStatus?: ENUM_POLICY_STATUS;
	ClaimStatus?: ENUM_CLAIMPROPOSAL_STATUS;
	Channel?: ENUM_CLAIM_CHANNEL;
	LicensePlatePrefixCode?: string;
	LicensePlateInfix?: string;
	LicensePlateSuffix?: string;
	ChasisNumber?: string;
	Email?: string;
	ProductName?: string;
	Currency?: string;
	Amount?: number;

	BranchCode?: string;
	BranchName?: string;

	DriverName?: string;
	DriverLicenseNumber?: string;

	ReporterName?: string;
	ReportedOn?: Date;

	IncidentCauseOfLoss?: string;
	IncidentDate?: Date;
	IncidentDamageDescription?: string;
	IncidentDamageType?: string;
	IncidentType?: ENUM_INCIDENT_TYPE;
	IncidentChronology?: string;
	IncidentNote?: string;
	IncidentLocation?: string;
	IncidentDetailLocation?: string;
	IncidentLongitude?: string;
	IncidentLatitude?: string;
	IncidentTime?: string;

	VehicleSpeed?: number;
	VehicleLocation?: string;
	VehicleLocationName?: string;
	InsuredLocation?: string;

	VehicleBrandID?: number;
	VehicleBrandCode?: number;
	VehicleBrandName?: string;

	VehicleTypeID?: number;
	VehicleTypeCode?: number;
	VehicleTypeName?: string;

	VehicleColorID?: number;
	VehicleColorCode?: string;
	VehicleColorName?: string;

	InsuredSurveyLocation?: string;
	InsuredVehicleChasisNumber?: string;
	InsuredVehicleEngineNumber?: string;
	InsuredLicensePlate?: string;

	LegalResponsibility?: boolean;
	PersonalAccidentPassanger?: boolean;
	PersonalAccidentDriver?: boolean;

	Status?: ENUM_CLAIMPROPOSAL_STATUS;
	VIPStatus?: boolean;

	PolicyActiveStatus?: boolean;
	PolicyPeriodeStatus?: boolean;
	PolicyPaymentStatus?: boolean;
	PolicyGracePeriodeStatus?: boolean;
	PolicyPaymentDate?: Date;

	BranchReviewedNote?: string;
	BranchReviewedBy?: number;
	BranchReviewedOn?: string;
	BranchReviewedStatus?: ENUM_REVIEW_STATE;

	MakerHeadOfficeReviewedNote?: string;
	MakerHeadOfficeReviewedBy?: number;
	MakerHeadOfficeReviewedOn?: string;
	MakerHeadOfficeReviewedStatus?: ENUM_REVIEW_STATE;

	CheckerHeadOfficeReviewedNote?: string;
	CheckerHeadOfficeReviewedBy?: number;
	CheckerHeadOfficeReviewedOn?: string;
	CheckerHeadOfficeReviewedStatus?: ENUM_REVIEW_STATE;

	HeadOfficeReviewedNote?: string;
	HeadOfficeReviewedBy?: number;
	HeadOfficeReviewedOn?: string;
	HeadOfficeReviewedStatus?: ENUM_REVIEW_STATE;

	ReporterSameAsPolicyholder?: boolean;
	InsuredLocationSameAsPolicyholder?: boolean;
	DriverSameAsPolicyholder?: boolean;
	SurveySameAsPolicyholder?: boolean;

	//#region REPORTER

	ReporterFirstName?: string;
	ReporterMiddleName?: string;
	ReporterLastName?: string;
	ReporterRelationship?: string;

	ReporterAddress?: string;
	ReporterLocation?: string;
	ReporterCountryID?: number;
	ReporterCountryCode?: string;
	ReporterCountryName?: string;
	ReporterCountryNameGeolocation?: string;
	ReporterProvinceID?: number;
	ReporterProvinceCode?: string;
	ReporterProvinceName?: string;
	ReporterProvinceNameGeolocation?: string;
	ReporterCityID?: number;
	ReporterCityCode?: string;
	ReporterCityName?: string;
	ReporterCityNameGeolocation?: string;
	ReporterSubDistrictID?: number;
	ReporterSubDistrictName?: string;
	ReporterSubDistrictCode?: string;
	ReporterSubDistrictNameGeolocation?: string;
	ReporterVillageID?: number;
	ReporterVillageCode?: string;
	ReporterVillageName?: string;
	ReporterVillageNameGeolocation?: string;
	ReporterHamletCode?: string;
	ReporterNeighbourhoodCode?: string;
	ReporterPostalID?: number;
	ReporterPostalCode?: string;
	ReporterPostalName?: string;
	ReporterPostalCodeGeolocation?: string;
	ReporterLongitude?: string;
	ReporterLatitude?: string;

	ReporterTelephone?: string;
	ReporterFax?: string;
	ReporterMobilePhonePrefix?: string;
	ReporterMobilePhone?: string;
	ReporterEmail?: string;

	//#endregion REPORTER


	//#region SURVEY

	SurveyAddress?: string;
	SurveyAddressSameAsPolicyholder?: boolean;
	SurveyDate?: Date;
	SurveyCountryID?: number;
	SurveyCountryCode?: string;
	SurveyCountryName?: string;
	SurveyCountryNameGeolocation?: string;
	SurveyLocation?: string;
	SurveyLongitude?: string;
	SurveyLatitude?: string;
	SurveyProvinceID?: number;
	SurveyProvinceCode?: string;
	SurveyProvinceName?: string;
	SurveyProvinceNameGeolocation?: string;
	SurveyCityID?: number;
	SurveyCityCode?: string;
	SurveyCityName?: string;
	SurveyCityNameGeolocation?: string;
	SurveySubDistrictID?: number;
	SurveySubDistrictName?: string;
	SurveySubDistrictCode?: string;
	SurveySubDistrictNameGeolocation?: string;
	SurveyVillageID?: number;
	SurveyVillageCode?: string;
	SurveyVillageName?: string;
	SurveyVillageNameGeolocation?: string;
	SurveyHamletCode?: string;
	SurveyNeighbourhoodCode?: string;
	SurveyPostalID?: number;
	SurveyPostalCode?: string;
	SurveyPostalName?: string;
	SurveyPostalCodeGeolocation?: string;

	SurveyNearestBranchCode?: string;
	SurveyNearestBranchName?: string;

	//#endregion SURVEY


	/* POLICYHOLDER - START */

	PolicyholderFirstName?: string;
	PolicyholderMiddleName?: string;
	PolicyholderLastName?: string;
	PolicyholderFullName?: string;
	PolicyholderBirthDate?: Date;
	PolicyholderBirthPlace?: string;
	PolicyholderGender?: ENUM_GENDER_TYPE;
	PolicyholderTelephone?: string;
	PolicyholderMobilePhone?: string;
	PolicyholderEmail?: string;

	PolicyholderIdentificationNumber?: string;
	PolicyholderIdentificationType?: ENUM_IDENTIFICATION_TYPE;

	PolicyholderAddress?: string;
	PolicyholderCountryID?: number;
	PolicyholderCountryCode?: number;
	PolicyholderCountryName?: number;
	PolicyholderProvinceID?: number;
	PolicyholderProvinceCode?: number;
	PolicyholderProvinceName?: number;
	PolicyholderCityID?: number;
	PolicyholderCityCode?: number;
	PolicyholderCityName?: number;
	PolicyholderSubDistrictID?: number;
	PolicyholderVillageID?: number;
	PolicyholderHamletCode?: string;
	PolicyholderNeighbourhoodCode?: string;
	PolicyholderPostalID?: number;
	PolicyholderPostalCode?: number;
	PolicyholderPostalName?: number;
	PolicyholderLatitude?: number;
	PolicyholderLongitude?: number;

	BooleanForSearchTrackingClaim?: boolean;

	/* POLICYHOLDER - END */

	SignerHeadOfficeReviewedNote?: string;
	SignerHeadOfficeReviewedBy?: number;
	SignerHeadOfficeReviewedOn?: string;
	SignerHeadOfficeReviewedStatus?: ENUM_REVIEW_STATE;

	//#region MODEL

	modelUserSigner?: UserModel;
	modelUserSignerHeadOfficeReviewed?: UserModel;
	modelUser?: UserModel;
	modelClaimProposalAppeal?: ClaimProposalAppealModel;
	modelClaimProposalSatisfaction?: ClaimProposalSatisfactionModel;

	//#endregion


	//#region LIST MODEL

	listModelClaimProposalGallery?: Array<ClaimProposalGalleryModel>;
	listModelClaimProposalSupportingGallery?: Array<ClaimProposalSupportingGalleryModel>;
	listModelSurvey?: Array<SurveyModel>;
	listModelSurveyThirdPartyLiability?: Array<SurveyThirdPartyLiabilityModel>;
	listModelClaimProposalAppeal?: Array<ClaimProposalAppealModel>;
	listModelClaimProposalTracking?: Array<ClaimProposalTrackingModel>;

	//#endregion

	constructor()
	{
		super();
		this.listModelClaimProposalGallery = [];
		this.listModelClaimProposalSupportingGallery = [];
		this.listModelSurvey = [];
		this.listModelSurveyThirdPartyLiability = [];
		this.listModelClaimProposalAppeal = [];
	}

	//#region VALIDATION

	validateData(): ResponseModel
	{
		const regularExpression: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_EMAIL);
		const regExAphabet: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABET);
		const regExAphabetSpace: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABET_WITHSPACE);
		const regExNumeric: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_NUMERIC);
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation(StringConstant.STRING_MODULE_CLAIMPROPOSAL);

		if (
			(this.PolicyNumber == null || this.PolicyholderEmail === null || this.PolicyNumber === "") &&
			(
				(this.LicensePlatePrefixCode == null || this.LicensePlatePrefixCode === null || this.LicensePlatePrefixCode === "") &&
				(this.LicensePlateInfix == null || this.LicensePlateInfix === null || this.LicensePlateInfix === "") &&
				(this.LicensePlateSuffix == null || this.LicensePlateSuffix === null || this.LicensePlateSuffix === "")
			)
		)
		{
			modelResponse.MessageContent = "Please fill in the policy number or license plate.";
		}
		else if (!this.ReporterSameAsPolicyholder && this.ReporterFirstName == null || this.ReporterFirstName === undefined || this.ReporterFirstName === "")
		{
			modelResponse.MessageContent = "Reporter\'s first name cannot be empty.";
		}
		else if(!this.ReporterSameAsPolicyholder && (this.ReporterFirstName != null || this.ReporterFirstName !== undefined || this.ReporterFirstName !== "") && !regExAphabet.test(this.ReporterFirstName))
		{
			modelResponse.MessageContent = "Reporter\'s first name is in incorrect format.";
		}
		else if (!this.ReporterSameAsPolicyholder && this.ReporterFirstName?.length > RuleConstant.NUMBER_FIRSTNAME_LENGTHMAXIMUM)
		{
			modelResponse.MessageContent = "Reporter\'s first name maximum contain "+RuleConstant.NUMBER_FIRSTNAME_LENGTHMAXIMUM+" characters.";
		}
		else if (!this.ReporterSameAsPolicyholder && this.ReporterFirstName?.length < RuleConstant.NUMBER_FIRSTNAME_LENGTHMINIMUM)
		{
			modelResponse.MessageContent = "Reporter\'s first name minimum contain "+RuleConstant.NUMBER_FIRSTNAME_LENGTHMINIMUM+" characters.";
		}
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		else if(!this.ReporterSameAsPolicyholder && (this.ReporterMiddleName!) && (this.ReporterMiddleName != null || this.ReporterMiddleName !== undefined || this.ReporterMiddleName !== "") && !regExAphabetSpace.test(this.ReporterMiddleName))
		{
			modelResponse.MessageContent = "Reporter\'s middle name is in incorrect format.";
		}
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		else if(!this.ReporterSameAsPolicyholder && (this.ReporterLastName!) && (this.ReporterLastName != null || this.ReporterLastName !== undefined || this.ReporterLastName !== "" ) && !regExAphabetSpace.test(this.ReporterLastName))
		{
			modelResponse.MessageContent = "Reporter\'s last name is in incorrect format.";
		}
		else if (!this.ReporterSameAsPolicyholder && (this.ReporterMobilePhonePrefix === null || this.ReporterMobilePhonePrefix === undefined || this.ReporterMobilePhonePrefix === ""))
		{
			modelResponse.MessageContent = "Reporter\'s mobile phone prefix cannot be empty.";
		}
		else if ((!this.ReporterSameAsPolicyholder) && this.ReporterMobilePhone === null || this.ReporterMobilePhone === undefined || this.ReporterMobilePhone === "")
		{
			modelResponse.MessageContent = "Reporter\'s mobile phone cannot be empty.";
		}
		else if (!this.ReporterSameAsPolicyholder && !regExNumeric.test(this.ReporterMobilePhone))
		{
			modelResponse.MessageContent = "Reporter\'s mobile phone can only contain numeric.";
		}
		else if ((this.ReporterMobilePhone.length < RuleConstant.NUMBER_MOBILEPHONE_LENGTHMINIMUM) && !this.ReporterSameAsPolicyholder)
		{
			modelResponse.MessageContent = "Reporter\'s mobile phone minimum contain "+ RuleConstant.NUMBER_MOBILEPHONE_LENGTHMINIMUM +" characters.";
		}
		else if ((this.ReporterMobilePhone.length > RuleConstant.NUMBER_MOBILEPHONE_LENGTHMAXIMUM) && !this.ReporterSameAsPolicyholder)
		{
			modelResponse.MessageContent = "Reporter\'s mobile phone maximum contain "+ RuleConstant.NUMBER_MOBILEPHONE_LENGTHMAXIMUM +" characters.";
		}
		else if (this.ReporterRelationship == null || this.ReporterRelationship === undefined)
		{
			modelResponse.MessageContent = "Reporter\'s relationship can not be empty.";
		}
		else if (this.ReporterAddress == null || this.ReporterAddress === undefined || this.ReporterAddress === "")
		{
			modelResponse.MessageContent = "Reporter\s address can not be empty.";
		}
		else if (this.ReporterAddress.length < RuleConstant.NUMBER_CLAIMPROPOSAL_INCIDENTCHRONOLOGY_LENGTHMINIMUM)
		{
			modelResponse.MessageContent = "Reporter\s address minimum "+RuleConstant.NUMBER_CLAIMPROPOSAL_INCIDENTCHRONOLOGY_LENGTHMINIMUM+" digits characters.";
		}
		else if (this.IncidentType == null || this.IncidentType === undefined)
		{
			modelResponse.MessageContent = "Accident type can not be empty.";
		}
		else if (this.IncidentLocation == null || this.IncidentLocation === undefined || this.IncidentLocation === "")
		{
			modelResponse.MessageContent = "Accident location can not be empty.";
		}
		else if (this.IncidentChronology == null || this.IncidentChronology === undefined || this.IncidentChronology === "")
		{
			modelResponse.MessageContent = "Accident chronology can not be empty.";
		}
		else if (this.IncidentChronology.length < RuleConstant.NUMBER_CLAIMPROPOSAL_INCIDENTCHRONOLOGY_LENGTHMINIMUM)
		{
			modelResponse.MessageContent = "Accident chronology minimum "+RuleConstant.NUMBER_CLAIMPROPOSAL_INCIDENTCHRONOLOGY_LENGTHMINIMUM+" digits characters.";
		}
		else if (this.IncidentDamageType == null || this.IncidentDamageType === undefined || this.IncidentDamageType === "")
		{
			modelResponse.MessageContent = "Accident Damage type can not be empty.";
		}
		else if (this.IncidentCauseOfLoss == null || this.IncidentCauseOfLoss === undefined || this.IncidentCauseOfLoss === "")
		{
			modelResponse.MessageContent = "Accident Cause Of Loss can not be empty.";
		}
		else if (this.IncidentDate == null || this.IncidentDate === undefined)
		{
			modelResponse.MessageContent = "Accident date can not be empty.";
		}
		else if (this.IncidentTime == null || this.IncidentTime === undefined || this.IncidentTime === "")
		{
			modelResponse.MessageContent = "Accident time can not be empty.";
		}
		else if (this.LegalResponsibility == null || this.LegalResponsibility === undefined)
		{
			modelResponse.MessageContent = "Legal reponsibility can not be empty.";
		}
		else if (this.SurveyNearestBranchName == null || this.SurveyNearestBranchName === undefined || this.SurveyNearestBranchName === "" || this.SurveyNearestBranchCode == null || this.SurveyNearestBranchCode === undefined || this.SurveyNearestBranchCode === "")
		{
			modelResponse.MessageContent = "Survey Branch can not be empty.";
		}
		else if (this.SurveyAddress == null || this.SurveyAddress === undefined || this.SurveyAddress === "")
		{
			modelResponse.MessageContent = "Survey Address can not be empty.";
		}
		else if (this.SurveyDate == null || this.SurveyDate === undefined)
		{
			modelResponse.MessageContent = "Survey date can not be empty.";
		}
		else if ((this.DriverName == null || this.DriverName === undefined || this.DriverName === "") && ( this.IncidentType === ENUM_INCIDENT_TYPE.Crash || this.IncidentType === ENUM_INCIDENT_TYPE.ReverseOrFell))
		{
			modelResponse.MessageContent = "Driver name cannot be empty.";
		}
		else if ((this.DriverName) && (!regExAphabetSpace.test(this.DriverName)) && ( this.IncidentType === ENUM_INCIDENT_TYPE.Crash || this.IncidentType === ENUM_INCIDENT_TYPE.ReverseOrFell))
		{
			modelResponse.MessageContent = "Driver name can only contain alphabet.";
		}
		else if((this.DriverName) && (this.DriverName.length < RuleConstant.NUMBER_FIRSTNAME_LENGTHMINIMUM) && ( this.IncidentType === ENUM_INCIDENT_TYPE.Crash || this.IncidentType === ENUM_INCIDENT_TYPE.ReverseOrFell))
		{
			modelResponse.MessageContent = "Driver name need to consist of minimum "+ RuleConstant.NUMBER_FIRSTNAME_LENGTHMINIMUM +" characters.";
		}
		else if((this.DriverName) && (this.DriverName.length > RuleConstant.NUMBER_NAME_MAXIMUM) && ( this.IncidentType === ENUM_INCIDENT_TYPE.Crash || this.IncidentType === ENUM_INCIDENT_TYPE.ReverseOrFell))
		{
			modelResponse.MessageContent = "Driver name need to consist of maximum "+ RuleConstant.NUMBER_NAME_MAXIMUM +" characters.";
		}
		else if ((this.DriverLicenseNumber == null || this.DriverLicenseNumber === undefined || this.DriverLicenseNumber === "") && ( this.IncidentType === ENUM_INCIDENT_TYPE.Crash || this.IncidentType === ENUM_INCIDENT_TYPE.ReverseOrFell))
		{
			modelResponse.MessageContent = "Driver license number cannot be empty.";
		}
		else if ((this.DriverLicenseNumber) && (!regExNumeric.test(this.DriverLicenseNumber)) && ( this.IncidentType === ENUM_INCIDENT_TYPE.Crash || this.IncidentType === ENUM_INCIDENT_TYPE.ReverseOrFell))
		{
			modelResponse.MessageContent = "Driver license number can only contain numeric.";
		}
		else if((this.DriverLicenseNumber) && (this.DriverLicenseNumber.length < RuleConstant.NUMBER_DRIVERLICENSENUMBER_NUMBERMINIMUM) && ( this.IncidentType === ENUM_INCIDENT_TYPE.Crash || this.IncidentType === ENUM_INCIDENT_TYPE.ReverseOrFell))
		{
			modelResponse.MessageContent = "Driver license number need to consist of minimum "+RuleConstant.NUMBER_DRIVERLICENSENUMBER_NUMBERMINIMUM+" characters.";
		}
		else if((this.DriverLicenseNumber) && (this.DriverLicenseNumber?.length > RuleConstant.NUMBER_DRIVERLICENSENUMBER_NUMBERMAXIMUM) && ( this.IncidentType === ENUM_INCIDENT_TYPE.Crash || this.IncidentType === ENUM_INCIDENT_TYPE.ReverseOrFell))
		{
			modelResponse.MessageContent = "Driver license number need to consist of maximum "+RuleConstant.NUMBER_DRIVERLICENSENUMBER_NUMBERMAXIMUM+" characters.";
		}
		else if((this.VehicleSpeed == null || this.VehicleSpeed === undefined) && ( this.IncidentType === ENUM_INCIDENT_TYPE.Crash || this.IncidentType === ENUM_INCIDENT_TYPE.ReverseOrFell))
		{
			modelResponse.MessageContent = "Vehicle speed can not be empty.";
		}
		else if (this.VehicleLocation == null || this.VehicleLocation === undefined || this.VehicleLocation === "")
		{
			modelResponse.MessageContent = "Vehicle location can not be empty.";
		}
		else
		{
			if (this.ReporterEmail != null && this.ReporterEmail !== undefined && this.ReporterEmail !== "")
			{
				modelResponse.MessageContent = "Reporter\'s email cannot be empty";
				if (regularExpression.test(this.ReporterEmail))
				{
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
				else
				{
					modelResponse.MessageContent = "Reporter\'s email format is incorrect.";
				}
			}
			else
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}

		return modelResponse;
	}

	validateDataNonMV(): ResponseModel
	{
		const regularExpression: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_EMAIL);
		const regExAphabet: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABET);
		const regExAphabetSpace: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABET_WITHSPACE);
		const regExNumeric: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_NUMERIC);
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Claim Proposal.");

		if (this.PolicyNumber == null || this.PolicyholderEmail === null || this.PolicyNumber === "")
		{
			modelResponse.MessageContent = "Please fill in the policy number or license plate.";
		}
		else if (this.ReporterFirstName == null || this.ReporterFirstName === undefined || this.ReporterFirstName === "")
		{
			modelResponse.MessageContent = "Reporter\'s first name cannot be empty.";
		}
		else if((!this.ReporterSameAsPolicyholder) && (this.ReporterFirstName != null || this.ReporterFirstName !== undefined || this.ReporterFirstName !== "") && !regExAphabet.test(this.ReporterFirstName))
		{
			modelResponse.MessageContent = "Reporter\'s first name is in incorrect format.";
		}
		else if ((!this.ReporterSameAsPolicyholder) && this.ReporterFirstName?.length > RuleConstant.NUMBER_FIRSTNAME_LENGTHMAXIMUM)
		{
			modelResponse.MessageContent = "Reporter\'s first name maximum contain "+RuleConstant.NUMBER_FIRSTNAME_LENGTHMAXIMUM+" characters.";
		}
		else if ((!this.ReporterSameAsPolicyholder) && this.ReporterFirstName?.length < RuleConstant.NUMBER_FIRSTNAME_LENGTHMINIMUM)
		{
			modelResponse.MessageContent = "Reporter\'s first name minimum contain "+RuleConstant.NUMBER_FIRSTNAME_LENGTHMINIMUM+" characters.";
		}
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		else if((!this.ReporterSameAsPolicyholder) && (this.ReporterMiddleName!) && (this.ReporterMiddleName != null || this.ReporterMiddleName !== undefined || this.ReporterMiddleName !== "") && !regExAphabetSpace.test(this.ReporterMiddleName))
		{
			modelResponse.MessageContent = "Reporter\'s middle name is in incorrect format.";
		}
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		else if((!this.ReporterSameAsPolicyholder) && (this.ReporterLastName!) && (this.ReporterLastName != null || this.ReporterLastName !== undefined || this.ReporterLastName !== "" ) && !regExAphabetSpace.test(this.ReporterLastName))
		{
			modelResponse.MessageContent = "Reporter\'s last name is in incorrect format.";
		}
		else if (!this.ReporterSameAsPolicyholder && (this.ReporterMobilePhonePrefix === null || this.ReporterMobilePhonePrefix === undefined || this.ReporterMobilePhonePrefix === ""))
		{
			modelResponse.MessageContent = "Reporter\'s mobile phone prefix cannot be empty.";
		}
		else if ((!this.ReporterSameAsPolicyholder) && this.ReporterMobilePhone === null || this.ReporterMobilePhone === undefined || this.ReporterMobilePhone === "")
		{
			modelResponse.MessageContent = "Reporter\'s mobile phone cannot be empty.";
		}
		else if (!this.ReporterSameAsPolicyholder && !regExNumeric.test(this.ReporterMobilePhone))
		{
			modelResponse.MessageContent = "Reporter\'s mobile phone can only contain numeric.";
		}
		else if ((this.ReporterMobilePhone.length < RuleConstant.NUMBER_MOBILEPHONE_LENGTHMINIMUM) && !this.ReporterSameAsPolicyholder)
		{
			modelResponse.MessageContent = "Reporter\'s mobile phone minimum contain "+RuleConstant.NUMBER_MOBILEPHONE_LENGTHMINIMUM+" characters.";
		}
		else if ((this.ReporterMobilePhone.length > RuleConstant.NUMBER_MOBILEPHONE_LENGTHMAXIMUM) && !this.ReporterSameAsPolicyholder)
		{
			modelResponse.MessageContent = "Reporter\'s mobile phone maximum contain "+RuleConstant.NUMBER_MOBILEPHONE_LENGTHMAXIMUM+" characters.";
		}
		else if (this.ReporterRelationship == null || this.ReporterRelationship === undefined)
		{
			modelResponse.MessageContent = "Reporter\'s relationship can not be empty.";
		}
		else if (this.IncidentCauseOfLoss == null || this.IncidentCauseOfLoss === undefined || this.IncidentCauseOfLoss === "")
		{
			modelResponse.MessageContent = "Cronology of loss can not be empty.";
		}
		else if (this.IncidentDetailLocation == null || this.IncidentDetailLocation === undefined || this.IncidentDetailLocation === "")
		{
			modelResponse.MessageContent = "Incident Detail Location can not be empty.";
		}
		else if (this.IncidentDate == null || this.IncidentDate === undefined)
		{
			modelResponse.MessageContent = "Accident date can not be empty.";
		}
		else if (this.IncidentTime == null || this.IncidentTime === undefined || this.IncidentTime === "")
		{
			modelResponse.MessageContent = "Accident time can not be empty.";
		}
		else
		{
			if (this.ReporterEmail == null || this.ReporterEmail === undefined || this.ReporterEmail === "")
			{
				modelResponse.MessageContent = "Reporter\'s email cannot be empty";
			}
			else if (regularExpression.test(this.ReporterEmail))
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
			else if (!regularExpression.test(this.ReporterEmail))
			{
				modelResponse.MessageContent = "Reporter\'s email format is incorrect.";
			}
			if (this.ReporterEmail != null && this.ReporterEmail !== undefined && this.ReporterEmail !== "")
			{
				modelResponse.MessageContent = "Reporter\'s email cannot be empty";
				if (regularExpression.test(this.ReporterEmail))
				{
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
				else if (!regularExpression.test(this.ReporterEmail))
				{
					modelResponse.MessageContent = "Reporter\'s email format is incorrect.";
				}
			}
			else
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}

		return modelResponse;
	}

	validateDataNotFound(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Submission");
		const regExpAphabet: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABET);
		const regExpEmail: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_EMAIL);
		const regExpNumeric: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_NUMERIC);

		if
		(
			(this.LicensePlatePrefixCode == null || this.LicensePlatePrefixCode === undefined || this.LicensePlatePrefixCode === "" ) &&
			(this.LicensePlateInfix == null || this.LicensePlateInfix === undefined || this.LicensePlateInfix === "" ) &&
			(this.LicensePlateSuffix == null || this.LicensePlateSuffix === undefined || this.LicensePlateSuffix === "" )
		)
		{
			modelResponse.MessageContent = "License plate cannot be empty.";
		}
		else if ( this.PolicyNumber !== null && this.PolicyNumber !== undefined && this.PolicyNumber !== "" )
		{
			if (this.PolicyNumber?.length !== RuleConstant.NUMBER_POLICY_NUMBER_LENGTH)
			{
				modelResponse.MessageContent = "Policynumber must be contain 16 digit numbers.";
			}
			else if (!regExpNumeric.test(this.PolicyNumber))
			{
				modelResponse.MessageContent = "Policy number format incorrect.";
			}
			else
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success ;
			}
		}
		else if
		(
			(this.LicensePlatePrefixCode !== null && this.LicensePlatePrefixCode !== undefined && this.LicensePlatePrefixCode !== "" ) ||
			(this.LicensePlateInfix !== null && this.LicensePlateInfix !== undefined && this.LicensePlateInfix !== "" ) ||
			(this.LicensePlateSuffix !== null && this.LicensePlateSuffix !== undefined && this.LicensePlateSuffix !== "" )
		)
		{
			if (this.LicensePlatePrefixCode === null || this.LicensePlatePrefixCode === undefined || this.LicensePlatePrefixCode === "" )
			{
				modelResponse.MessageContent = "License Plate Prefix can’t be empty, please fill this field.";
			}
			else if (this.LicensePlateInfix === null || this.LicensePlateInfix === undefined || this.LicensePlateInfix === "" )
			{
				modelResponse.MessageContent = "License Plate Infix can’t be empty, please fill this field.";
			}
			else if (this.LicensePlateSuffix === null || this.LicensePlateSuffix === undefined || this.LicensePlateSuffix === "" )
			{
				modelResponse.MessageContent = "License Plate Suffix can’t be empty, please fill this field.";
			}
			else if (!regExpAphabet.test(this.LicensePlateSuffix))
			{
				modelResponse.MessageContent = "License Plate Suffix format incorrect.";
			}
			else
			{
				this.InsuredLicensePlate = this.LicensePlatePrefixCode.replace(/\s/g, "") +""+ this.LicensePlateInfix +""+ this.LicensePlateSuffix;
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else if (this.InsuredVehicleChasisNumber !== null && this.InsuredVehicleChasisNumber !== undefined && this.InsuredVehicleChasisNumber !== "" )
		{
			modelResponse.MessageContent = "Chasis Number correct.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else if (this.PolicyholderEmail !== null && this.PolicyholderEmail !== undefined && this.PolicyholderEmail !== "")
		{
			if (regExpEmail.test(this.PolicyholderEmail))
			{
				modelResponse.MessageContent = "Email correct.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
			else
			{
				modelResponse.MessageContent = "Email incorrect.";
				modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			}
		}
		else
		{
			modelResponse.MessageContent = "Attributes search empty";
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
		}

		return modelResponse;
	}

	validateAddSubmissionDocumentLenght(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Submission");

		if(this.listModelClaimProposalGallery?.length === 0 )
		{
			modelResponse.MessageContent = "Document empty.";
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateAddSubmissionDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Submission");

		if(this.listModelClaimProposalGallery == null || this.listModelClaimProposalGallery === undefined)
		{
			modelResponse.MessageContent = "An error occurred in the attachment list.";
			modelResponse.State = ENUM_RESPONSE_STATE.Warning;
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateAddSubmissionDocumentByBranch(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Submission");

		if(this.listModelClaimProposalGallery == null || this.listModelClaimProposalGallery === undefined)
		{
			modelResponse.MessageContent = "An error occurred in the attachment list.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateUpload(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Submission");

		if(this.listModelClaimProposalGallery == null || this.listModelClaimProposalGallery === undefined)
		{
			modelResponse.MessageContent = "Terdapat kesalahan pada daftar lampiran.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateUploadDocumentForClaimMV(): ResponseModel
	{
		const modelResponse: ResponseModel = this.validateUpload();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;

			if (this.listModelClaimProposalGallery?.length ?? 0 > 0)
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
			else
			{
				modelResponse.MessageContent = "Dokumen tidak boleh kosong, harap lampirkan file.";
			}
		}
		else
		{
			modelResponse.MessageContent = "Terdapat kesalahan pada daftar lampiran.";
		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			let modelResponseClaimProposalGallery: ResponseModel;
			let numberSize: number = 0;

			for (const modelClaimProposalGallery of this.listModelClaimProposalGallery ?? [])
			{
				modelResponseClaimProposalGallery = modelClaimProposalGallery.validateAdd();

				if (modelResponseClaimProposalGallery.State === ENUM_RESPONSE_STATE.Fail)
				{
					return modelResponseClaimProposalGallery;
				}
				else
				{
					numberSize += modelClaimProposalGallery.Size ?? 0;
				}
			}

			if (numberSize > RuleConstant.NUMBER_FILE_TOTAL_SIZEMAXIMUM_FOR_PRODUCTOTO_SUPPORTINGDOCUMENT)
			{
				const pipeConvertByteForPreview: ConvertByteForPreview = new ConvertByteForPreview();

				modelResponse.MessageContent = "Maksimal total lampiran adalah " + pipeConvertByteForPreview.transform(RuleConstant.NUMBER_FILE_TOTAL_SIZEMAXIMUM_FOR_PRODUCTOTO_SUPPORTINGDOCUMENT);
			}
			else
			{
				modelResponse.MessageContent = "Formulir diisi dengan benar.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else
		{

		}

		if(modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			let numberDocumentSuccess: number = 0;
			let numberDocumentFailed: number = 0;
			let booleanBreak: boolean = false;

			const arrayModelSupportingDocument: Array<SupportingDocumentModel> = this.setSupportingDocumentMV();

			arrayModelSupportingDocument.forEach(modelSupportingDocument =>
			{
				const arrayDocument = this.listModelClaimProposalGallery?.filter(modelClaimProposalGallery => modelClaimProposalGallery.Type === modelSupportingDocument.Type);

				if(arrayDocument?.length !== 0)
				{
					numberDocumentSuccess = numberDocumentSuccess + 1;
				}
				else
				{
					if(booleanBreak === false)
					{
						booleanBreak = true;
						modelResponse.MessageContent = "Dokumen "+ modelSupportingDocument.Name +" tidak boleh kosong.";
						numberDocumentFailed = numberDocumentFailed + 1;
					}
				}

			});

			if(numberDocumentSuccess === arrayModelSupportingDocument.length)
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
			else
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			}
		}

		return modelResponse;
	}

	validateTokenForUpdate(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Letter general");

		if (this.Token == null || this.Token === undefined || this.Token === "")
		{
			modelResponse.MessageContent = "Token tidak boleh kosong.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateAddForUpload(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if (this.listModelClaimProposalGallery?.length ?? 0 > 0)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{

		}

		return modelResponse;
	}

	validateAddForUploadByBranch(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if (this.listModelClaimProposalSupportingGallery?.length ?? 0 > 0)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{

		}

		return modelResponse;
	}

	validateDataByBranch(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation(StringConstant.STRING_MODULE_CLAIMPROPOSAL);

		if (this.BranchReviewedNote == null || this.BranchReviewedNote === undefined || this.BranchReviewedNote === "")
		{
			modelResponse.MessageContent = "Note can’t be empty, please fill this field.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		return modelResponse;
	}

	validateUploadByBranch(): ResponseModel
	{
		const modelResponse: ResponseModel = this.validateAddSubmissionDocumentByBranch();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;

			if (this.listModelClaimProposalSupportingGallery?.length ?? 0 > 0)
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
			else
			{
				modelResponse.MessageContent = "Document can’t be empty, please fill this field.";
			}
		}
		else
		{
			modelResponse.MessageContent = "An error occurred in the attachment list.";
		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			let modelResponseDeclarationDocument: ResponseModel;
			let numberSize: number = 0;

			for (const modelSubmissionDocument of this.listModelClaimProposalSupportingGallery ?? [])
			{
				modelResponseDeclarationDocument = modelSubmissionDocument.validateAdd();

				if (modelResponseDeclarationDocument.State === ENUM_RESPONSE_STATE.Fail)
				{
					return modelResponseDeclarationDocument;
				}
				else
				{
					numberSize += modelSubmissionDocument.Size ?? 0;
				}
			}

			if (numberSize > RuleConstant.NUMBER_FILE_TOTAL_SIZEMAXIMUM)
			{
				const pipeConvertByteForPreview: ConvertByteForPreview = new ConvertByteForPreview();

				modelResponse.MessageContent = "Maksimum total size attachment adalah " + pipeConvertByteForPreview.transform(RuleConstant.NUMBER_FILE_TOTAL_SIZEMAXIMUM);
			}
			else
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else
		{

		}

		return modelResponse;
	}

	validateDataByHeadOffice(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation(StringConstant.STRING_MODULE_CLAIMPROPOSAL);

		if (this.HeadOfficeReviewedNote == null || this.HeadOfficeReviewedNote === undefined || this.HeadOfficeReviewedNote === "")
		{
			modelResponse.MessageContent = "Note can’t be empty, please fill this field.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		return modelResponse;
	}

	validateKirReport(): boolean
	{
		if(this.LegalResponsibility)
		{
			return true;
		}
		else
		{
			return false;
		}
	}

	validateSearchClaimProposalAttributes(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		const regExpAphabet: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABET);
		const regExpNumeric: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_NUMERIC);

		modelResponse.setForValidation(StringConstant.STRING_MODULE_CLAIMPROPOSAL);

		if (this.TicketNumber !== null && this.TicketNumber !== undefined && this.TicketNumber !== "")
		{
			const stringTicketNumberPrefix = this.TicketNumber.substring(0,5);
			if (stringTicketNumberPrefix === "BNCL-")
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
			else
			{
				modelResponse.MessageContent = "Ticket Number format incorrect.";
				modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			}
		}
		else if (this.PolicyNumber !== null && this.PolicyNumber !== undefined && this.PolicyNumber !== "")
		{

			if (this.PolicyNumber.length !== RuleConstant.NUMBER_POLICY_NUMBER_LENGTH)
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Warning ;
				modelResponse.MessageContent = "Policy number must be contain 16 digit numbers.";
			}
			else if (!regExpNumeric.test(this.PolicyNumber))
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Warning ;
				modelResponse.MessageContent = "Policy number format incorrect.";
			}
			else
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success ;
			}
		}
		else if
		(
			(this.LicensePlatePrefixCode !== null && this.LicensePlatePrefixCode !== undefined && this.LicensePlatePrefixCode !== "" ) ||
			(this.LicensePlateInfix !== null && this.LicensePlateInfix !== undefined && this.LicensePlateInfix !== "" ) ||
			(this.LicensePlateSuffix !== null && this.LicensePlateSuffix !== undefined && this.LicensePlateSuffix !== "" )
		)
		{
			if (this.LicensePlatePrefixCode === null || this.LicensePlatePrefixCode === undefined || this.LicensePlatePrefixCode === "" )
			{
				modelResponse.MessageContent = "License Plate Prefix can’t be empty, please fill this field.";
			}
			else if (this.LicensePlateInfix === null || this.LicensePlateInfix === undefined || this.LicensePlateInfix === "" )
			{
				modelResponse.MessageContent = "License Plate Infix can’t be empty, please fill this field.";
			}
			else if (this.LicensePlateSuffix === null || this.LicensePlateSuffix === undefined || this.LicensePlateSuffix === "" )
			{
				modelResponse.MessageContent = "License Plate Suffix can’t be empty, please fill this field.";
			}
			else if (!regExpAphabet.test(this.LicensePlateSuffix))
			{
				modelResponse.MessageContent = "License Plate Suffix format incorrect.";
			}
			else
			{
				this.InsuredLicensePlate = this.LicensePlatePrefixCode.replace(/\s/g, "") +""+ this.LicensePlateInfix +""+ this.LicensePlateSuffix;
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else if (this.CreatedOn !== null && this.CreatedOn !== undefined)
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else if (this.ReporterFirstName !== null && this.ReporterFirstName !== undefined && this.ReporterFirstName !== "")
		{
			if (!regExpAphabet.test(this.ReporterFirstName))
			{
				modelResponse.MessageContent = "Reporter first name format incorrect.";
			}
			else
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateReporter(): ResponseModel
	{
		const regularExpression: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_EMAIL);
		const regExAphabet: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABET);
		const regExAphabetSpace: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABET_WITHSPACE);
		const regExNumeric: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_NUMERIC);
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation(StringConstant.STRING_MODULE_CLAIMPROPOSAL);

		if (this.ReporterFirstName == null || this.ReporterFirstName === undefined || this.ReporterFirstName === "")
		{
			modelResponse.MessageContent = "Nama depan tidak boleh kosong.";
		}
		else if((this.ReporterFirstName != null || this.ReporterFirstName !== undefined || this.ReporterFirstName !== "") && !regExAphabet.test(this.ReporterFirstName))
		{
			modelResponse.MessageContent = "Nama depan harus alphabet.";
		}
		else if (this.ReporterFirstName?.length > RuleConstant.NUMBER_FIRSTNAME_LENGTHMAXIMUM)
		{
			modelResponse.MessageContent = "Nama depan maksimal "+RuleConstant.NUMBER_FIRSTNAME_LENGTHMAXIMUM+" karakter.";
		}
		else if (this.ReporterFirstName?.length < RuleConstant.NUMBER_FIRSTNAME_LENGTHMINIMUM)
		{
			modelResponse.MessageContent = "Nama depan minimal "+RuleConstant.NUMBER_FIRSTNAME_LENGTHMINIMUM+" karakter.";
		}
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		else if((this.ReporterMiddleName!) && (this.ReporterMiddleName != null || this.ReporterMiddleName !== undefined || this.ReporterMiddleName !== "") && !regExAphabetSpace.test(this.ReporterMiddleName))
		{
			modelResponse.MessageContent = "Nama tengah harus alphabet.";
		}
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		else if((this.ReporterLastName!) && (this.ReporterLastName != null || this.ReporterLastName !== undefined || this.ReporterLastName !== "" ) && !regExAphabetSpace.test(this.ReporterLastName))
		{
			modelResponse.MessageContent = "Nama belakang harus alphabet.";
		}
		else if(this.ReporterEmail == null || this.ReporterEmail === undefined || this.ReporterEmail === "")
		{
			modelResponse.MessageContent = "Email tidak boleh kosong";
		}
		else if(!regularExpression.test(this.ReporterEmail))
		{
			modelResponse.MessageContent = "Email tidak sesuai format.";
		}
		else if (this.ReporterMobilePhone === null || this.ReporterMobilePhone === undefined || this.ReporterMobilePhone === "")
		{
			modelResponse.MessageContent = "Nomor telepon tidak boleh kosong.";
		}
		else if (!regExNumeric.test(this.ReporterMobilePhone))
		{
			modelResponse.MessageContent = "Nomor telepon harus angka.";
		}
		else if ((this.ReporterMobilePhone.toString().length < RuleConstant.NUMBER_MOBILEPHONE_LENGTHMINIMUM))
		{
			modelResponse.MessageContent = "Nomor telephone minimal "+ RuleConstant.NUMBER_MOBILEPHONE_LENGTHMINIMUM +" karakter.";
		}
		else if ((this.ReporterMobilePhone.toString().length > RuleConstant.NUMBER_MOBILEPHONE_LENGTHMAXIMUM))
		{
			modelResponse.MessageContent = "Nomor telephone maksimal "+ RuleConstant.NUMBER_MOBILEPHONE_LENGTHMAXIMUM +" karakter.";
		}
		else if (this.ReporterRelationship == null || this.ReporterRelationship === undefined)
		{
			modelResponse.MessageContent = "Hubungan pelapor tidak boleh kosong.";
		}
		else if (this.ReporterAddress == null || this.ReporterAddress === undefined || this.ReporterAddress === "")
		{
			modelResponse.MessageContent = "Alamat tidak boleh kosong.";
		}
		else if (this.ReporterAddress.length < RuleConstant.NUMBER_CLAIMPROPOSAL_INCIDENTCHRONOLOGY_LENGTHMINIMUM)
		{
			modelResponse.MessageContent = "Alamat minimal "+RuleConstant.NUMBER_CLAIMPROPOSAL_INCIDENTCHRONOLOGY_LENGTHMINIMUM+" karakter.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateAccident(): ResponseModel
	{
		const regExAphabetSpace: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABET_WITHSPACE);
		const regExNumeric: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_NUMERIC);

		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation(StringConstant.STRING_MODULE_CLAIMPROPOSAL);

		if (this.IncidentType == null || this.IncidentType === undefined)
		{
			modelResponse.MessageContent = "Tipe kecelakaan tidak boleh kosong.";
		}
		else if (this.IncidentLocation == null || this.IncidentLocation === undefined || this.IncidentLocation === "")
		{
			modelResponse.MessageContent = "Alamat kecelakaan tidak boleh kosong.";
		}
		else if (this.IncidentLocation.length < RuleConstant.NUMBER_CLAIMPROPOSAL_INCIDENTLOCATION_LENGTHMINIMUM)
		{
			modelResponse.MessageContent = "Alamat kecelakaan minimal "+RuleConstant.NUMBER_CLAIMPROPOSAL_INCIDENTLOCATION_LENGTHMINIMUM+" karakter.";
		}
		else if (this.IncidentChronology == null || this.IncidentChronology === undefined || this.IncidentChronology === "")
		{
			modelResponse.MessageContent = "Kronologis kecelakaan tidak boleh kosong.";
		}
		else if (this.IncidentChronology.length < RuleConstant.NUMBER_CLAIMPROPOSAL_INCIDENTCHRONOLOGY_LENGTHMINIMUM)
		{
			modelResponse.MessageContent = "Kronologi kecelakaan minimal "+RuleConstant.NUMBER_CLAIMPROPOSAL_INCIDENTCHRONOLOGY_LENGTHMINIMUM+" karakter.";
		}
		else if (this.IncidentDate == null || this.IncidentDate === undefined)
		{
			modelResponse.MessageContent = "Tanggal kecelakaan tidak boleh kosong.";
		}
		else if (this.IncidentDate.setHours(0,0,0,0) > (new Date().setHours(0,0,0,0)))
		{
			modelResponse.MessageContent = "Tanggal kecelakaan tidak boleh lebih dari hari ini.";
		}
		else if (this.IncidentTime == null || this.IncidentTime === undefined || this.IncidentTime === "")
		{
			modelResponse.MessageContent = "Waktu kecelakaan tidak boleh kosong.";
		}
		else if ((this.DriverName == null || this.DriverName === undefined || this.DriverName === ""))
		{
			modelResponse.MessageContent = "Nama pengemudi tidak boleh kosong.";
		}
		else if ((this.DriverName) && (!regExAphabetSpace.test(this.DriverName)))
		{
			modelResponse.MessageContent = "Nama pengemudi hanya berisi huruf.";
		}
		else if((this.DriverName) && (this.DriverName.length < RuleConstant.NUMBER_FIRSTNAME_LENGTHMINIMUM))
		{
			modelResponse.MessageContent = "Nama pengemudi harus terdiri dari minimum "+ RuleConstant.NUMBER_FIRSTNAME_LENGTHMINIMUM +" karakter.";
		}
		else if((this.DriverName) && (this.DriverName.length > RuleConstant.NUMBER_NAME_MAXIMUM))
		{
			modelResponse.MessageContent = "Nama pengemudi harus terdiri dari maximum "+ RuleConstant.NUMBER_NAME_MAXIMUM +" karakter.";
		}
		else if ((this.DriverLicenseNumber == null || this.DriverLicenseNumber === undefined || this.DriverLicenseNumber === ""))
		{
			modelResponse.MessageContent = "Nomor SIM tidak boleh kosong";
		}
		else if ((this.DriverLicenseNumber) && (!regExNumeric.test(this.DriverLicenseNumber)))
		{
			modelResponse.MessageContent = "Nomor SIM hanya boleh berisi angka.";
		}
		else if((this.DriverLicenseNumber) && (this.DriverLicenseNumber.length < RuleConstant.NUMBER_DRIVERLICENSENUMBER_NUMBERMINIMUM))
		{
			modelResponse.MessageContent = "Nomor SIM harus terdiri dari minimum "+RuleConstant.NUMBER_DRIVERLICENSENUMBER_NUMBERMINIMUM+" karakter.";
		}
		else if((this.DriverLicenseNumber) && (this.DriverLicenseNumber?.length > RuleConstant.NUMBER_DRIVERLICENSENUMBER_NUMBERMAXIMUM))
		{
			modelResponse.MessageContent = "Nomor SIM harus terdiri dari maximum "+RuleConstant.NUMBER_DRIVERLICENSENUMBER_NUMBERMAXIMUM+" karakter.";
		}
		else if(this.VehicleSpeed == null || this.VehicleSpeed === undefined)
		{
			modelResponse.MessageContent = "Kecepatan kendaraan tidak boleh kosong.";
		}
		else if (this.VehicleLocation == null || this.VehicleLocation === undefined || this.VehicleLocation === "")
		{
			modelResponse.MessageContent = "Lokasi kendaraan tidak boleh kosong.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateLoss(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation(StringConstant.STRING_MODULE_CLAIMPROPOSAL);

		if (this.IncidentLocation == null || this.IncidentLocation === undefined || this.IncidentLocation === "")
		{
			modelResponse.MessageContent = "Lokasi kejadian tidak boleh kosong.";
		}
		else if (this.IncidentDetailLocation == null || this.IncidentDetailLocation === undefined || this.IncidentDetailLocation === "")
		{
			modelResponse.MessageContent = "Detail lokasi kejadian tidak boleh kosong.";
		}
		else if (this.IncidentDetailLocation.length < RuleConstant.NUMBER_CLAIMPROPOSAL_INCIDENTCHRONOLOGY_LENGTHMINIMUM)
		{
			modelResponse.MessageContent = "Detail lokasi kejadian minimal "+RuleConstant.NUMBER_CLAIMPROPOSAL_INCIDENTCHRONOLOGY_LENGTHMINIMUM+" karakter.";
		}
		else if (this.IncidentChronology == null || this.IncidentChronology === undefined || this.IncidentChronology === "")
		{
			modelResponse.MessageContent = "Kronologis kejadian tidak boleh kosong.";
		}
		else if (this.IncidentChronology.length < RuleConstant.NUMBER_CLAIMPROPOSAL_INCIDENTCHRONOLOGY_LENGTHMINIMUM)
		{
			modelResponse.MessageContent = "Kronologis kejadian minimal "+RuleConstant.NUMBER_CLAIMPROPOSAL_INCIDENTCHRONOLOGY_LENGTHMINIMUM+" karakter.";
		}
		else if ((this.Currency == null || this.Currency === undefined) && (this.Amount == null || this.Amount === undefined))
		{
			modelResponse.MessageContent = "Estimasi nilai klaim tidak boleh kosong.";
		}
		else if (this.IncidentDate == null || this.IncidentDate === undefined)
		{
			modelResponse.MessageContent = "Tanggal kejadian tidak boleh kosong.";
		}
		else if (this.IncidentDate.setHours(0,0,0,0) > (new Date().setHours(0,0,0,0)))
		{
			modelResponse.MessageContent = "Tanggal kejadian tidak boleh lebih dari hari ini.";
		}
		else if (this.IncidentTime == null || this.IncidentTime === undefined || this.IncidentTime === "")
		{
			modelResponse.MessageContent = "Waktu kejadian tidak boleh kosong.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateSurvey(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation(StringConstant.STRING_MODULE_CLAIMPROPOSAL);

		if (this.SurveyNearestBranchName == null || this.SurveyNearestBranchName === undefined || this.SurveyNearestBranchName === "" || this.SurveyNearestBranchCode == null || this.SurveyNearestBranchCode === undefined || this.SurveyNearestBranchCode === "")
		{
			modelResponse.MessageContent = "Cabang survei tidak boleh kosong.";
		}
		else if (this.SurveyAddress == null || this.SurveyAddress === undefined || this.SurveyAddress === "")
		{
			modelResponse.MessageContent = "Alamat survei tidak boleh kosong.";
		}
		else if (this.SurveyAddress.length < RuleConstant.NUMBER_CLAIMPROPOSAL_SURVEYADDRESS_LENGTHMINIMUM)
		{
			modelResponse.MessageContent = "Alamat survei minimal "+RuleConstant.NUMBER_CLAIMPROPOSAL_SURVEYADDRESS_LENGTHMINIMUM+" karakter.";
		}
		else if (this.SurveyDate == null || this.SurveyDate === undefined)
		{
			modelResponse.MessageContent = "Tanggal survei tidak boleh kosong.";
		}
		else if (this.SurveyDate.setHours(0,0,0,0) < (new Date().setHours(0,0,0,0)))
		{
			modelResponse.MessageContent = "Tanggal survei tidak boleh kurang dari ini.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	//#endregion


	//#region SETTER

	setInsuredLicensePlate(stringInsuredLicensePlate: string): boolean
	{
		const stringTicketNumberPrefix = stringInsuredLicensePlate.substring(0,3);

		if(stringInsuredLicensePlate === null || stringInsuredLicensePlate === undefined || stringInsuredLicensePlate === "")
		{
			return true;
		}
		else if(stringTicketNumberPrefix === "TBA")
		{
			this.LicensePlatePrefixCode = stringTicketNumberPrefix;
			return true;
		}
		else if(stringInsuredLicensePlate.length >= 4)
		{
			const arrayInsuredLicensePlate = stringInsuredLicensePlate.match(/[^\d]+|\d+/g) || Array<string>();
			this.LicensePlatePrefixCode = arrayInsuredLicensePlate[0].replace(StringConstant.STRING_REGULAREXPRESSION_SPACE, "");
			this.LicensePlateInfix = arrayInsuredLicensePlate[1].replace(StringConstant.STRING_REGULAREXPRESSION_SPACE, "");
			this.LicensePlateSuffix = arrayInsuredLicensePlate[2].replace(StringConstant.STRING_REGULAREXPRESSION_SPACE, "");

			return true;
		}
		else
		{
			return false;
		}

	}

	setReporterRelationship(stringReporterRelationship: string): boolean
	{
		if(stringReporterRelationship !== null || stringReporterRelationship !== undefined || stringReporterRelationship !== "")
		{
			return true;
		}
		else
		{
			return false;
		}
	}

	setSupportingDocumentMV(): Array<SupportingDocumentModel>
	{
		const enumPhotoType = ENUM_PHOTO_TYPE;
		const arrayModelGalleryModel: Array<SupportingDocumentModel> =
		[
			{
				Type: enumPhotoType.VehicleFront,
				Name : "Tampak Depan*"
			},
			{
				Type: enumPhotoType.VehicleLeft,
				Name : "Tampak Kiri*"
			},
			{
				Type: enumPhotoType.VehicleRight,
				Name : "Tampak Kanan*"
			},
			{
				Type: enumPhotoType.VehicleBack,
				Name : "Tampak Belakang*"
			},
			{
				Type: enumPhotoType.KirReport,
				Name : "KIR*"
			},
			{
				Type: enumPhotoType.KTP,
				Name : "KTP*"
			},
			{
				Type: enumPhotoType.STNK,
				Name : "STNK*"
			},
			{
				Type: enumPhotoType.DrivingLicense,
				Name : "Driver License*"
			},
		];

		return arrayModelGalleryModel;
	}

	setSupportingDocumentNonMV(): Array<SupportingDocumentModel>
	{
		const enumPhotoType = ENUM_PHOTO_TYPE;
		const arrayModelGalleryModel: Array<SupportingDocumentModel> =
		[
			{
				Type: enumPhotoType.KTP,
				Name : "KTP"
			},
			{
				Type: enumPhotoType.ClaimLetter,
				Name : "Surat Tuntutan"
			},
			{
				Type: enumPhotoType.ObjectOfLoss,
				Name : "Barang yang Hilang"
			},
			{
				Type: enumPhotoType.Other,
				Name : "Lainnnya"
			},
		];

		return arrayModelGalleryModel;
	}

	setListModelClaimProposalDocument(
		arrayClaimProposalDocument?: Array<ClaimProposalGalleryModel>
	): void
	{
		if (arrayClaimProposalDocument == null || arrayClaimProposalDocument === undefined)
		{

		}
		else
		{
			if (arrayClaimProposalDocument.length > 0)
			{
				let modelClaimProposalDocument: ClaimProposalGalleryModel;

				for (const modelClaimProposalDocumentTemp of arrayClaimProposalDocument)
				{
					modelClaimProposalDocument = new ClaimProposalGalleryModel();
					modelClaimProposalDocument.setModelFromObject(modelClaimProposalDocumentTemp);
					this.listModelClaimProposalGallery?.push(modelClaimProposalDocument);

				}
			}
			else
			{

			}

		}
	}

	setInterfaceForMotorVehicle(b): Array<number>
	{
		let arrayModelGalleryModel: Array<number> = Array();
		if(b === true)
		{
			arrayModelGalleryModel = [ 11, 17, 10, 16, 5, 6, 7, 8 ];
		}
		else
		{
			arrayModelGalleryModel = [ 11, 18 ];
		}

		return arrayModelGalleryModel;
	}

	setInterfaceDocument(b): Array<number>
	{
		let arrayModelGalleryModel: Array<number> = Array();
		const enumPhotoType = ENUM_PHOTO_TYPE;

		if(b === 0 || b === 1 || b === 3 || b === 4)
		{
			arrayModelGalleryModel = [
				enumPhotoType.KTP,
				enumPhotoType.ClaimLetter,
				enumPhotoType.ObjectOfLoss,
				enumPhotoType.Other
			];
		}
		else
		{
			arrayModelGalleryModel = [ 11, 17, 10, 16, 5, 6, 7, 8,
				enumPhotoType.TitikKerusakan1,
				enumPhotoType.TitikKerusakan2,
				enumPhotoType.TitikKerusakan3,
				enumPhotoType.TitikKerusakan4,
				enumPhotoType.TitikKerusakan5,
			];
		}
		return arrayModelGalleryModel;
	}

	setInterfaceDocumentForBRINS(b): Array<SupportingDocumentModel>
	{
		let arrayModelGalleryModel: Array<SupportingDocumentModel> = Array();
		const enumPhotoType = ENUM_PHOTO_TYPE;

		if(b === 0 || b === 1 || b === 3 || b === 4)
		{
			arrayModelGalleryModel =
			[
				{
					Type: enumPhotoType.KTP,
					Name: "KTP"
				},
				{
					Type: enumPhotoType.ClaimLetter,
					Name: "Surat Tuntutan"
				},
				{
					Type: enumPhotoType.ObjectOfLoss,
					Name: "Barang yang Hilang"
				},
				{
					Type: enumPhotoType.Other,
					Name: "Lainnya"
				}
			];
		}
		else
		{
			arrayModelGalleryModel = [
				{
					Type: enumPhotoType.KTP,
					Name: "KTP"
				},
				{
					Type: enumPhotoType.DrivingLicense,
					Name: "SIM"
				},
				{
					Type: enumPhotoType.STNK,
					Name: "STNK"
				},
				{
					Type: enumPhotoType.KirReport,
					Name: "Surat KIR"
				},
				{
					Type: enumPhotoType.VehicleFront,
					Name: "Tampak Depan"
				},
				{
					Type: enumPhotoType.VehicleLeft,
					Name: "Tampak Kiri"
				},
				{
					Type: enumPhotoType.VehicleRight,
					Name: "Tampak Kanan"
				},
				{
					Type: enumPhotoType.VehicleBack,
					Name: "Tampak Belakang"
				},
				{
					Type: enumPhotoType.TitikKerusakan1,
					Name: "Titik Kerusakan 1"
				},
				{
					Type: enumPhotoType.TitikKerusakan2,
					Name: "Titik Kerusakan 2"
				},
				{
					Type: enumPhotoType.TitikKerusakan3,
					Name: "Titik Kerusakan 3"
				},
				{
					Type: enumPhotoType.TitikKerusakan4,
					Name: "Titik Kerusakan 4"
				},
				{
					Type: enumPhotoType.TitikKerusakan5,
					Name: "Titik Kerusakan 5"
				},
			];
		}
		return arrayModelGalleryModel;
	}

	setInterfaceValidationDocument(b): Array<number>
	{
		let arrayModelGalleryModel: Array<number> = Array();

		if(b === 0 || b === 1 || b === 3 || b === 4)
		{
			arrayModelGalleryModel = [ 11, 18 ];
		}
		else
		{
			arrayModelGalleryModel = [];
		}
		return arrayModelGalleryModel;
	}

	setInterface(b): boolean
	{
		let booleanMV: boolean = false;
		if(b === 0 || b === 1 || b === 3 || b === 4)
		{
			booleanMV = false;
		}
		else
		{
			booleanMV = true;
		}
		return booleanMV;
	}

	setValidateCheckPolicyNumber(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Policy General");


		if(this.PolicyNumber == null || this.PolicyNumber === undefined || this.PolicyNumber === "")
		{
			modelResponse.MessageContent = "Policy number tidak boleh kosong";
		}
		else if(this.PolicyholderIdentificationNumber == null || this.PolicyholderIdentificationNumber === undefined || this.PolicyholderIdentificationNumber === "")
		{
			modelResponse.MessageContent = "NIK tidak boleh kosong";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	setValidateCheckTicketNumberForTracking(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Claim proposal");

		if(this.TicketNumber == null || this.TicketNumber === undefined)
		{
			modelResponse.MessageContent = "Nomor tiket tidak boleh kosong";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	//#endregion


	//#region FUNCTION

	checkTypePolicyNumber(stringTOC: string): number
	{

		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Submission");

		const stringTypeTOC: string = stringTOC;
		let numberTypePolicy: number | undefined = 0;

		if(modelResponse.State !== ENUM_RESPONSE_STATE.Success)
		{
			StringConstant.ARRAY_VALUE_TOC_FIRE.forEach(function(stringValue)
			{
				if(stringTypeTOC === stringValue)
				{
					numberTypePolicy = 0;
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
			});
		}

		if(modelResponse.State !== ENUM_RESPONSE_STATE.Success)
		{
			StringConstant.ARRAY_VALUE_TOC_ENGINEERING.forEach(function(stringValue)
			{
				if(stringTypeTOC === stringValue)
				{
					numberTypePolicy = 1;
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
			});
		}

		if(modelResponse.State !== ENUM_RESPONSE_STATE.Success)
		{
			StringConstant.ARRAY_VALUE_TOC_MV.forEach(function(stringValue)
			{
				if(stringTypeTOC === stringValue)
				{
					numberTypePolicy = 2;
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
			});
		}

		if(modelResponse.State !== ENUM_RESPONSE_STATE.Success)
		{
			StringConstant.ARRAY_VALUE_TOC_VARIA.forEach(function(stringValue)
			{
				if(stringTypeTOC === stringValue)
				{
					numberTypePolicy = 3;
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
			});
		}

		if(modelResponse.State !== ENUM_RESPONSE_STATE.Success)
		{
			StringConstant.ARRAY_VALUE_TOC_FINANSIALLINE.forEach(function(stringValue)
			{
				if(stringTypeTOC === stringValue)
				{
					numberTypePolicy = 4;
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
			});
		}

		return numberTypePolicy;
	}

	checkThirdPartyLiability(booleanThirdParty: boolean): string
	{
		if(booleanThirdParty === true)
		{
			return "Yes";
		}
		else if(booleanThirdParty === false)
		{
			return "No";
		}
		else
		{
			return StringConstant.STRING_CHARACTER_DASH;
		}
	}

	checkListClaimProposalAppealNotEmpty(): boolean
	{
		if(this.listModelClaimProposalAppeal === undefined || this.listModelClaimProposalAppeal.length === 0)
		{
			return false;
		}
		else
		{
			return true;
		}
	}

	checkCertificate(): boolean
	{
		if(this.CertificateNumber === null || this.CertificateNumber === undefined || this.CertificateNumber === "")
		{
			return false;
		}
		else
		{
			return true;
		}
	}

	booleanCheckThirdPartyLiability(booleanThirdParty: boolean): boolean
	{
		if(booleanThirdParty === true || booleanThirdParty === false)
		{
			return true;
		}
		else
		{
			return false;
		}
	}

	public saveData(stringKey: string, stringValue: string)
	{
		localStorage.setItem(stringKey, stringValue);
	}

	public getData(stringKey: string)
	{
		return localStorage.getItem(stringKey);
	}

	public removeData(stringKey: string)
	{
		localStorage.removeItem(stringKey);
	}

	public clearData()
	{
		localStorage.clear();
	}

	//#endregion

}

//#endregion
