//#region IMPORT

import { ENUM_GENDER_TYPE, ENUM_PHOTO_TYPE, ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { RuleConstant } from "../constants/rule.constant";
import { StringConstant } from "../constants/string.constant";
import { ConvertByteForPreview } from "../functions/pipes/converter.pipe";
import { BaseModel } from "./bases/base.model";
import { CoverageModel } from "./coverage.model";
import { ProductGeneralModel } from "./productgeneral.model";
import { ProductOtoGalleryModel } from "./productotogallery.model";
import { ResponseModel } from "./response.model";
import { SupportingDocumentModel } from "./supportingdocument.model";
import { VehicleBrandModel } from "./vehiclebrand.model";
import { VehicleLocationModel } from "./vehiclelocation.model";
import { VehicleManufactureYearModel } from "./vehiclemanufactureyear.model";
import { VehicleModelModel } from "./vehiclemodel.model";

//#endregion


//#region CLASS

export class ProductOtoModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for product oto model, used for SPPA and policy.
		Author: Andri Septiawan and Ibrahim Aziz.
		Created on : Tuesday, 29 December 2020. 			Updated on : Wednesday, 6 January 2021.
		Created by : Andri Septiawan.						Updated by : Ibrahim Aziz.
		Version : 1.0:2.
	*/

	/* POLICY DETAIL - START */

	ID?: number;
	ProductGeneralID?: number;
	CoverageCode?: string;
	override ProductCode?: string;
	CoverageName?: string;

	Disclaimer?: boolean;
	SurveyOtoID?: number;
	Note?: string;
	Token?: string;

	/* POLICY DETAIL - END */

	/* POLICYHOLDER - START */

	PolicyholderFirstName?: string;
	PolicyholderMiddleName?: string;
	PolicyholderLastName?: string;
	PolicyholderGender?: ENUM_GENDER_TYPE;
	PolicyholderBirthDate?: Date;
	PolicyholderEmail?: string;
	PolicyholderExpiredDrivingLicense?: Date;

	/* POLICYHOLDER - END */

	/* INSURED - START */

	VehiclePrice?: number;
	EquipmentNonStandardPrice?: number;
	EquipmentNonStandardNote?: string;
	VehicleRegionCode?: string;
	VehicleRegionName?: string;
	LicensePlatePrefixID?: number;
	LicensePlatePrefixCode?: string;
	LicensePlatePrefixName?: string;
	LicensePlateCode?: string;
	LicensePlateInfix?: string;
	LicensePlateSuffix?: string;

	VehicleManufactureYearID?: number;
	VehicleManufactureYearCode?: string;
	VehicleManufactureYearName?: string;
	VehicleBrandID?: number;
	VehicleBrandCode?: string;
	VehicleBrandName?: string;
	VehicleModelID?: number;
	VehicleModelCode?: string;
	VehicleModelName?: string;
	VehicleSubModelID?: number;
	VehicleSubModelCode?: string;
	VehicleSubModelName?: string;
	VehicleTypeID?: number;
	VehicleTypeCode?: number;
	VehicleTypeName?: number;
	VehicleColorID?: number;
	VehicleColorCode?: string;
	VehicleColorName?: string;
	VehicleFunctionID?: number;
	VehicleFunctionCode?: number;
	VehicleLocationID?: number;
	VehicleLocationCode?: string;
	VehicleLocationName?: string;

	VehicleSerie?: string;
	VehicleChasisNumber?: string;
	VehicleEngineNumber?: string;
	VehicleSeatCapacity?: string;
	AuthorizedWorkshop?: boolean;

	/* INSURED - END */

	/* NOT MAPPED - START */

	override modelProductGeneral?: ProductGeneralModel;
	modelVehicleBrand?: VehicleBrandModel;
	modelVehicleModel?: VehicleModelModel;
	modelVehicleManufactureYear?: VehicleManufactureYearModel;
	modelVehicleLocation?: VehicleLocationModel;
	modelCoverage?: CoverageModel;

	listModelProductOtoGallery?: Array<ProductOtoGalleryModel>;

	/* NOT MAPPED - END */


	/* CUSTOM - START */

	Comprehensive?: boolean;
	TotalLossOnly?: boolean;
	ThirdPartyLegal?: boolean;
	ThirdPartyLegalValue?: number;
	FloodTyphoonHurricaneHailLandslide?: boolean;
	Riots?: boolean;
	TerrorismAndSabotage?: boolean;
	EarthquakesTsunamisVolcanicEruptions?: boolean;
	DriverPersonalAccident?: boolean;
	PassengerPersonalAccident?: boolean;

	/* CUSTOM - END */

	/* Attribute - END */


	//#region CONSTRUCTOR

	constructor()
	{
		super();
	}

	//#endregion


	//#region VALIDATION

	validateforVehiclePrice(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		const regularExpression: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABETNUMERICWITHOUTTAGEMOJI);

		modelResponse.setForValidation("Product Oto");

		if(this.VehiclePrice == null || this.VehiclePrice === undefined)
		{
			modelResponse.MessageContent = "Harga Kendaraan tidak boleh kosong";
			return modelResponse;
		}
		else if (this.VehiclePrice > 2000000000)
		{
			modelResponse.MessageContent = "Harga Kendaraan tidak boleh lebih dari Rp 2.000.000.000";
			return modelResponse;
		}
		else if (this.VehiclePrice < 1000000)
		{
			modelResponse.MessageContent = "Harga Kendaraan tidak boleh kurang dari Rp 1.000.000.";
			return modelResponse;
		}
		else if (this.EquipmentNonStandardPrice !== undefined && (this.VehiclePrice + this.EquipmentNonStandardPrice) > 2000000000)
		{
			modelResponse.MessageContent = "Nilai Pertanggungan tidak boleh lebih dari Rp 2.000.000.000";
			return modelResponse;
		}
		else if (this.EquipmentNonStandardNote !== undefined && (!regularExpression.test(this.EquipmentNonStandardNote)))
		{
			modelResponse.MessageContent = "Perlengkapan Non Standar mengandung karakter yang tidak diizinkan.";
			return modelResponse;
		}
		else if (this.EquipmentNonStandardPrice !== undefined && (this.EquipmentNonStandardPrice < 100000))
		{
			modelResponse.MessageContent = "Nilai Perlengkapan Non Standar harus lebih dari Rp 100.000";
			return modelResponse;
		}
		else if (this.EquipmentNonStandardPrice !== undefined && (this.EquipmentNonStandardPrice > (this.VehiclePrice / 10)))
		{
			modelResponse.MessageContent = "Harga Perlengkapan Non Standar tidak boleh lebih dari 10% Harga Kendaraan.";
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			return modelResponse;
		}
	}

	validateforEquipmentNonStandardPrice(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Product Oto");

		if(this.EquipmentNonStandardPrice == null || this.EquipmentNonStandardPrice === undefined)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			return modelResponse;
		}
		if(this.VehiclePrice == null || this.VehiclePrice === undefined)
		{
			modelResponse.MessageContent = "Vehicle Price tidak boleh kosong.";
			return modelResponse;
		}
		// else if (this.EquipmentNonStandardPrice > (this.VehiclePrice / 10))
		// {
		// 	modelResponse.MessageContent = "Harga Perlengkapan Non Standar harus kurang dari 10% Harga Kendaraan.";
		// 	return modelResponse;
		// }
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			return modelResponse;
		}
	}

	validateforVehicleManufactureYearCode(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Product Oto");

		if(this.VehicleManufactureYearCode == null || this.VehicleManufactureYearCode === undefined)
		{
			modelResponse.MessageContent = "Tahun Produksi tidak boleh kosong.";
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			return modelResponse;
		}
	}

	validateforCoverageCode(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Product Oto");

		if(this.CoverageCode == null || this.CoverageCode === undefined)
		{
			modelResponse.MessageContent = "Coverage tidak boleh kosong.";
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			return modelResponse;
		}
	}

	validateforVehicleBrandCode(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Product Oto");

		if(this.VehicleBrandCode == null || this.VehicleBrandCode === undefined)
		{
			modelResponse.MessageContent = "Merk tidak boleh kosong.";
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			return modelResponse;
		}
	}

	validateforVehicleModelCode(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Product Oto");

		if(this.VehicleModelCode == null || this.VehicleModelCode === undefined)
		{
			modelResponse.MessageContent = "Model tidak boleh kosong.";
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			return modelResponse;
		}
	}

	validateforVehicleLocationCode(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Product Oto");

		if(this.VehicleLocationCode == null || this.VehicleLocationCode === undefined)
		{
			modelResponse.MessageContent = "Area Penggunaan tidak boleh kosong.";
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			return modelResponse;
		}
	}

	validateforLicensePlatePrefix(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		const regularExpression: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABETNUMERICWITHOUTEMOJI);

		modelResponse.setForValidation("Product Oto");

		if(this.LicensePlatePrefixCode == null || this.LicensePlatePrefixCode === undefined || this.LicensePlatePrefixName == null || this.LicensePlatePrefixName === undefined)
		{
			modelResponse.MessageContent = "Plat Prefix (Cth: B, F, D, dll) tidak boleh kosong.";
			return modelResponse;
		}
		else if (this.LicensePlateInfix !== undefined && !regularExpression.test(this.LicensePlateInfix))
		{
			this.LicensePlateInfix = undefined;
			modelResponse.MessageContent = "Plat Infix mengandung karakter yang tidak diizinkan.";
			return modelResponse;
		}
		else if (this.LicensePlateSuffix !== undefined && !regularExpression.test(this.LicensePlateSuffix))
		{
			this.LicensePlateSuffix = undefined;
			modelResponse.MessageContent = "Plat Suffix mengandung karakter yang tidak diizinkan.";
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			return modelResponse;
		}
	}

	validateforPlateNumber(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		const regularExpression: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABETNUMERICWITHOUTEMOJI);

		modelResponse.setForValidation("Product Oto");

		if(this.LicensePlatePrefixCode == null || this.LicensePlatePrefixCode === undefined || this.LicensePlatePrefixName == null || this.LicensePlatePrefixName === undefined)
		{
			modelResponse.MessageContent = "Plat Prefix (Cth: B, F, D, dll) tidak boleh kosong.";
			return modelResponse;
		}
		else if(this.LicensePlateInfix == null || this.LicensePlateInfix === undefined )
		{
			modelResponse.MessageContent = "Plat Infix tidak boleh kosong.";
			return modelResponse;
		}
		else if (!regularExpression.test(this.LicensePlateInfix))
		{
			modelResponse.MessageContent = "Plat Infix mengandung karakter yang tidak diizinkan.";
			return modelResponse;
		}
		else if(this.LicensePlateSuffix == null || this.LicensePlateSuffix === undefined )
		{
			modelResponse.MessageContent = "Plat Suffix tidak boleh kosong.";
			return modelResponse;
		}
		else if (!regularExpression.test(this.LicensePlateSuffix))
		{
			modelResponse.MessageContent = "Plat Suffix mengandung karakter yang tidak diizinkan.";
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			return modelResponse;
		}
	}

	validateforRegion(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Product Oto");

		if(this.VehicleRegionCode == null || this.VehicleRegionCode === undefined || this.VehicleRegionName == null || this.VehicleRegionName === undefined)
		{
			modelResponse.MessageContent = "Wilayah Kendaraan tidak boleh kosong.";
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			return modelResponse;
		}
	}

	validateCheckForCalculatePremiumOto(): ResponseModel
	{
		let modelResponse: ResponseModel = this.validateforCoverageCode();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateforVehicleBrandCode();
		}
		else
		{

		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateforVehicleModelCode();
		}
		else
		{

		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateforVehicleLocationCode();
		}
		else
		{

		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateforVehicleManufactureYearCode();
		}
		else
		{

		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			if (this.EquipmentNonStandardNote === "" || this.EquipmentNonStandardNote === null)
			{
				this.EquipmentNonStandardNote = undefined;
			}
			modelResponse = this.validateforVehiclePrice();
		}
		else
		{

		}

		return modelResponse;
	}


	validateforVehicleCodeForButton(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if((this.VehicleBrandCode == null || this.VehicleBrandCode === undefined) || (this.VehicleModelCode == null || this.VehicleModelCode === undefined) || (this.VehicleManufactureYearCode == null || this.VehicleManufactureYearCode === undefined) || (this.VehicleLocationCode == null || this.VehicleLocationCode === undefined) || (this.VehiclePrice == null || this.VehiclePrice === undefined))
		{
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			return modelResponse;
		}
	}

	setSupportingDocument(): Array<SupportingDocumentModel>
	{
		const enumPhotoType = ENUM_PHOTO_TYPE;
		const arrayModelGalleryModel: Array<SupportingDocumentModel> =
		[
			{
				Type: enumPhotoType.VehicleFront,
				Name : "Tampak Depan*"
			},
			{
				Type: enumPhotoType.VehicleLeft,
				Name : "Tampak Kiri*"
			},
			{
				Type: enumPhotoType.VehicleRight,
				Name : "Tampak Kanan*"
			},
			{
				Type: enumPhotoType.VehicleBack,
				Name : "Tampak Belakang*"
			},
			{
				Type: enumPhotoType.VehicleInterior,
				Name : "Interior*"
			},
			{
				Type: enumPhotoType.STNK,
				Name : "STNK*"
			},
			{
				Type: enumPhotoType.KTP,
				Name : "KTP*"
			},
		];

		return arrayModelGalleryModel;
	}

	validateTokenForUpdate(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Produk Oto");

		if (this.Token == null || this.Token === undefined || this.Token === "")
		{
			modelResponse.MessageContent = "Token tidak boleh kosong.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateAddForUpload(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if (this.listModelProductOtoGallery?.length ?? 0 > 0)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{

		}

		return modelResponse;
	}

	//#endregion


	//#region GENERATE

	generateManufactureYearForCalculatePremium(): Array<number>
	{
		const arrayNumberManufactureYear: Array<number> = [];
		const numberMaximumYear: number = new Date().getFullYear();
		const numberMinimumYear: number = numberMaximumYear - 15;

		for (let numberYearCalc: number = numberMinimumYear; numberYearCalc <= numberMaximumYear; numberYearCalc++)
		{
			arrayNumberManufactureYear.push(numberYearCalc);
		}

		return arrayNumberManufactureYear;
	}

	//#endregion


	//#region VALIDATE OTO INSURED

	validateCheckForObjectInformationOto(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		const regularExpression: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABETNUMERICWITHOUTEMOJI);

		modelResponse.setForValidation("Product Oto");

		if(this.VehicleColorCode == null || this.VehicleColorCode === undefined)
		{
			modelResponse.MessageContent = "Warna Kendaraan tidak boleh kosong.";
			return modelResponse;
		}
		else if(this.VehicleChasisNumber == null || this.VehicleChasisNumber === undefined)
		{
			modelResponse.MessageContent = "Nomor Rangka tidak boleh kosong.";
			return modelResponse;
		}
		else if (!regularExpression.test(this.VehicleChasisNumber))
		{
			modelResponse.MessageContent = "Nomor Rangka mengandung karakter yang tidak diizinkan.";
			return modelResponse;
		}
		else if(this.VehicleEngineNumber == null || this.VehicleEngineNumber === undefined)
		{
			modelResponse.MessageContent = "Nomor Mesin tidak boleh kosong.";
			return modelResponse;
		}
		else if (!regularExpression.test(this.VehicleEngineNumber))
		{
			modelResponse.MessageContent = "Nomor Rangka mengandung karakter yang tidak diizinkan.";
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			return modelResponse;
		}
	}

	//#endregion


	//#region  VALIDATE UPLOAD DOCUMENT

	validateUpload(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Produk Oto");

		if(this.listModelProductOtoGallery == null || this.listModelProductOtoGallery === undefined)
		{
			modelResponse.MessageContent = "Terdapat kesalahan pada daftar lampiran.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateUploadButtonDisable(): ResponseModel
	{
		let numberDocumentSuccess: number = 0;
		let numberDocumentFailed: number = 0;
		let booleanBreak: boolean = false;

		const modelResponse: ResponseModel = new ResponseModel();

		const arrayModelSupportingDocument: Array<SupportingDocumentModel> = this.setSupportingDocument();

		for(const modelSupportingDocument of arrayModelSupportingDocument)
		{
			const arrayDocument = this.listModelProductOtoGallery?.filter(modelProductOtoGallery => modelProductOtoGallery.Type === modelSupportingDocument.Type);

			if(arrayDocument?.length === 0)
			{
				if(booleanBreak === false)
				{
					booleanBreak = true;
					numberDocumentFailed = numberDocumentFailed + 1;
					return modelResponse;
				}
				else
				{
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
					return modelResponse;
				}
			}
			else
			{
				numberDocumentSuccess = numberDocumentSuccess + 1;
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		return modelResponse;
	}

	validateUploadDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = this.validateUpload();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;

			if (this.listModelProductOtoGallery?.length ?? 0 > 0)
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
			else
			{
				modelResponse.MessageContent = "Dokumen tidak boleh kosong, harap lampirkan file.";
			}
		}
		else
		{
			modelResponse.MessageContent = "Terdapat kesalahan pada daftar lampiran.";
		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			let modelResponseProductOtoGallery: ResponseModel;
			let numberSize: number = 0;

			for (const modelProductOtoGallery of this.listModelProductOtoGallery ?? [])
			{
				modelResponseProductOtoGallery = modelProductOtoGallery.validateAdd();

				if (modelResponseProductOtoGallery.State === ENUM_RESPONSE_STATE.Fail)
				{
					return modelResponseProductOtoGallery;
				}
				else
				{
					numberSize += modelProductOtoGallery.Size ?? 0;
				}
			}

			if (numberSize > RuleConstant.NUMBER_FILE_TOTAL_SIZEMAXIMUM_FOR_PRODUCTOTO_SUPPORTINGDOCUMENT)
			{
				const pipeConvertByteForPreview: ConvertByteForPreview = new ConvertByteForPreview();

				modelResponse.MessageContent = "Maksimal total lampiran adalah " + pipeConvertByteForPreview.transform(RuleConstant.NUMBER_FILE_TOTAL_SIZEMAXIMUM_FOR_PRODUCTOTO_SUPPORTINGDOCUMENT);
			}
			else
			{
				modelResponse.MessageContent = "Formulir diisi dengan benar.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else
		{

		}

		if(modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			let numberDocumentSuccess: number = 0;
			let numberDocumentFailed: number = 0;
			let booleanBreak: boolean = false;

			const arrayModelSupportingDocument: Array<SupportingDocumentModel> = this.setSupportingDocument();

			arrayModelSupportingDocument.forEach(modelSupportingDocument =>
			{
				const arrayDocument = this.listModelProductOtoGallery?.filter(modelProductOtoGallery => modelProductOtoGallery.Type === modelSupportingDocument.Type);

				if(arrayDocument?.length !== 0)
				{
					numberDocumentSuccess = numberDocumentSuccess + 1;
				}
				else
				{
					if(booleanBreak === false)
					{
						booleanBreak = true;
						modelResponse.MessageContent = "Dokumen "+ modelSupportingDocument.Name +" tidak boleh kosong.";
						numberDocumentFailed = numberDocumentFailed + 1;
					}
				}

			});

			if(numberDocumentSuccess === arrayModelSupportingDocument.length)
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
			else
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			}
		}

		return modelResponse;
	}

	//#endregion
}

//#endregion