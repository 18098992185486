//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { ProductAsriModel } from "../models/productasri.model";
import { ProductGeneralModel } from "../models/productgeneral.model";
import { PremiumModel } from "../models/premium.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class ProductasriService extends BaseService
{

	//#region CONSTRUCTOR

	public _modelProductAsri: Observable<ProductAsriModel>;
	public _modelProductGeneral: Observable<ProductGeneralModel>;
	public _modelPremium: Observable<PremiumModel>;

	public _boolanProduct: Observable<boolean>;

	private _behaviourSubjectProductAsriModel: BehaviorSubject<ProductAsriModel>;
	private _behaviourSubjectProductGeneralModel: BehaviorSubject<ProductGeneralModel>;
	private _behaviourSubjectPremiumModel: BehaviorSubject<PremiumModel>;

	private _behaviourBooleanProduct: BehaviorSubject<boolean>;

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);

		this._behaviourSubjectProductAsriModel = new BehaviorSubject(new ProductAsriModel());
		this._modelProductAsri = this._behaviourSubjectProductAsriModel.asObservable();

		this._behaviourSubjectProductGeneralModel = new BehaviorSubject(new ProductGeneralModel());
		this._modelProductGeneral = this._behaviourSubjectProductGeneralModel.asObservable();

		this._behaviourSubjectPremiumModel = new BehaviorSubject(new PremiumModel());
		this._modelPremium = this._behaviourSubjectPremiumModel.asObservable();

		this._behaviourBooleanProduct = new BehaviorSubject<boolean>(false);
		this._boolanProduct = this._behaviourBooleanProduct.asObservable();
	}

	setProductCode(modelProductAsri: ProductAsriModel)
	{
		this._behaviourSubjectProductAsriModel.next(modelProductAsri);
	}

	setBooleanProduct(booleanProduct: boolean)
	{
		this._behaviourBooleanProduct.next(booleanProduct);
	}

	setModelProductGeneral(modelProductGeneral: ProductGeneralModel)
	{
		this._behaviourSubjectProductGeneralModel.next(modelProductGeneral);
	}

	setModelProductGeneralPolicy(modelProductGeneral: ProductGeneralModel)
	{
		this._behaviourSubjectProductGeneralModel.next(modelProductGeneral);
	}

	setModelPremium(modelPemium: PremiumModel)
	{
		this._behaviourSubjectPremiumModel.next(modelPemium);
	}

	// setModeProductAsri(model)

	//#endregion


	//#region SELECT

	calculatePremiumAsriForSelfServiceMonitoring(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTASRI_SELECTCALCULATEPREMIUMASRIFORSELFSERVICEMONITORING);
	}

	insertProductAsriForSelfServiceMonitoring(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTASRI_INSERTPRODUCTASRIFORSELFSERVICEMONITORING);
	}

	updateInsuredForSelfServiceMonitoring(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTASRI_UPDATEINSUREDASRIFORSELFSERVICEMONITORING);
	}

	uploadProductAsriForSelfServiceMonitoring(interfaceGeneralService: GeneralServiceInterface, modelProductAsri: ProductAsriModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductAsri), WebAddressConstant.STRING_URL_PRODUCTASRI_UPLOADPRODUCTASRIFORSELFSERVICEMONITORING);
	}

	saveProductAsriForSelfServiceMonitoring(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTASRI_SAVEPRODUCTASRIFORSELFSERVICEMONITORING);
	}

	sendSPPAByEmailAsriForSelfServiceMonitoring(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTASRI_SENDSPPABYEMAILASRIFORSELFSERVICEMONITORING);
	}

	confirmPaymentForSelfServiceMonitoring(interfaceGeneralService: GeneralServiceInterface, modelProductAsri: ProductAsriModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductAsri), WebAddressConstant.STRING_URL_PRODUCTASRI_CONFIRMPAYMENTASRIFORSELFSERVICEMONITORING);
	}

	generatePaymentForSelfServiceMonitoring(interfaceGeneralService: GeneralServiceInterface, modelProducGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProducGeneral), WebAddressConstant.STRING_URL_PRODUCTASRI_GENERAPAYMENTBRIVA);
	}

	sharePayementForSelfServiceMonitoring(interfaceGeneralService: GeneralServiceInterface, modelProducGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProducGeneral), WebAddressConstant.STRING_URL_PRODUCTASRI_SHAREPAYMENTFORSELFSERVICEMONITORING);
	}

	selectProductByTokenForSelfServiceMonitoring(interfaceGeneralService: GeneralServiceInterface, modelProductAsri: ProductAsriModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductAsri), WebAddressConstant.STRING_URL_PRODUCTASRI_SELECTPRODUCTBYTOKENFORSELFSERVICEMONITORING);
	}

	confirmPayment(interfaceGeneralService: GeneralServiceInterface, modelProductAsri: ProductAsriModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductAsri), WebAddressConstant.STRING_URL_PRODUCTASRI_ASRICONFIRMPAYMENT);
	}

	//#endregion

}

//#endregion
