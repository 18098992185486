//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { ProductGeneralModel } from "../models/productgeneral.model";
import { ProductBicycleModel } from "../models/productbicycle.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class ProductbicycleService extends BaseService
{
	public _modelProductBicycle: Observable<ProductBicycleModel>;

	private _behaviourSubjectProductBicycleModel: BehaviorSubject<ProductBicycleModel>;


	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);

		this._behaviourSubjectProductBicycleModel = new BehaviorSubject(new ProductBicycleModel());
		this._modelProductBicycle = this._behaviourSubjectProductBicycleModel.asObservable();
	}

	//#endregion


	//#region SETTER

	setProductCode(modelProductBicycle: ProductBicycleModel)
	{
		this._behaviourSubjectProductBicycleModel.next(modelProductBicycle);
	}

	//#endregion


	//#region SELECT

	calculatePremiumBicycleForSelfServiceMonitoring(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTBICYCLE_SELECTCALCULATEPREMIUMBICYCLEFORSELFSERVICEMONITORING);
	}

	insertProductBicycleForSelfServiceMonitoring(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTBICYCLE_INSERTPRODUCTBICYCLEFORSELFSERVICEMONITORING);
	}

	updateInsuredForSelfServiceMonitoring(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTBICYCLE_UPDATEINSUREDBICYCLEFORSELFSERVICEMONITORING);
	}

	uploadProductBicycleForSelfServiceMonitoring(interfaceGeneralService: GeneralServiceInterface, modelProductBicyle: ProductBicycleModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductBicyle), WebAddressConstant.STRING_URL_PRODUCTBICYCLE_UPLOADPRODUCTBICYCLEFORSELFSERVICEMONITORING);
	}

	uplodaProductBicycleForWebsiteCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductAsri: ProductBicycleModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductAsri), WebAddressConstant.STRING_URL_PRODUCTBICYCLE_UPLOADPRODUCTBICYCLEFORSELFSERVICEMONITORING);
	}

	saveProductBicycleForSelfServiceMonitoring(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTBICYCLE_SAVEPRODUCTBICYCLEFORSELFSERVICEMONITORING);
	}

	sendSPPAByEmailBicycleForSelfServiceMonitoring(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTBICYCLE_SENDSPPABYEMAILBICYCLEFORSELFSERVICEMONITORING);
	}

	confirmPaymentForSelfServiceMonitoring(interfaceGeneralService: GeneralServiceInterface, modelProductBicycle: ProductBicycleModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductBicycle), WebAddressConstant.STRING_URL_PRODUCTBICYCLE_CONFIRMPAYMENTBICYCLEFORSELFSERVICEMONITORING);
	}

	generatePaymentForSelfServiceMonitoring(interfaceGeneralService: GeneralServiceInterface, modelProducGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProducGeneral), WebAddressConstant.STRING_URL_PRODUCTBICYCLE_GENERATEPAYMENTFORBRIVA);
	}

	sharePayementForSelfServiceMonitoring(interfaceGeneralService: GeneralServiceInterface, modelProducGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProducGeneral), WebAddressConstant.STRING_URL_PRODUCTBICYCLE_SHAREPAYMENTFORSELFSERVICEMONITORING);
	}

	selectProductByTokenForSelfServiceMonitoring(interfaceGeneralService: GeneralServiceInterface, modelProductBicycle: ProductBicycleModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductBicycle), WebAddressConstant.STRING_URL_PRODUCTBICYCLE_SELECTPRODUCTBYTOKENFORSELFSERVICEMONITORING);
	}

	//#endregion
}

//#endregion
