//#region IMPORT

import { ENUM_GENDER_TYPE, ENUM_IDENTIFICATION_TYPE, ENUM_PROJECT_TYPE, ENUM_RESPONSE_STATE, ENUM_SPPA_STATUS, ENUM_VIRTUALACCOUNT_TYPE } from "../constants/enum.constant";
import { RuleConstant } from "../constants/rule.constant";
import { StringConstant } from "../constants/string.constant";
import { NumberConstant } from "../constants/number.constant";
import { BaseModel } from "./bases/base.model";
import { LeadModel } from "./lead.model";
import { LetterHeaderModel } from "./letterheader.model";
import { PolicyAsriModel } from "./policyasri.model";
import { RcoverModel } from "./rcover.model";
import { PolicyPersonalAccidentModel } from "./policypersonalaccident.model";
import { ResponseModel } from "./response.model";
import { UserModel } from "./user.model";
import { AccclaModel } from "./acccla.model";
import { IcoverModel } from "./icover.model";

//#endregion


//#region CLASS

export class PolicyGeneralModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for policy general model, attributes are stamped, used for contain general information to cover specific policy.
		Author: Andri Septiawan and Ibrahim Aziz.
		Created on : Tuesday, 29 December 2020. 			Updated on : Wednesday, 6 January 2021.
		Created by : Andri Septiawan.						Updated by : Ibrahim Aziz.
		Version : 1.0:4.
	*/

	/* POLICY DETAIL - START */

	ID?: number;
	ProductGeneralID?: number;
	ProductName?: string;
	BranchCode?: string;
	BranchName?: string;
	CoverageCode?: string;
	CoverageName?: string;
	ReferenceNumber?: string;
	PolicyNumber?: string;
	PolicyID?: number;
	Status?: ENUM_SPPA_STATUS;
	PolicyStatus?: string;
	SupportingClause?: string;

	PolicyActiveStatus?: boolean;
	PolicyPeriodeStatus?: boolean;
	PolicyPaymentStatus?: boolean;
	PolicyGracePeriodeStatus?: boolean ;
	listModelRcover?: Array<RcoverModel>;
	listModelIcover?: Array<IcoverModel>;
	listModelAcccla?: Array<AccclaModel>;

	CustomerID?: number;
	Token?: string;
	Certificate?: boolean;
	CertificateNumber?: string;

	Information?: string;
	Disclaimer?: boolean;
	Note?: string;
	ProjectSource?: ENUM_PROJECT_TYPE;

	/* POLICY DETAIL - END */

	/* RELATIONSHIP MANAGER - START */

	RelationshipManagerID?: number;
	RelationshipManagerCoreID?: string;

	RelationshipManagerFirstName?: string;
	RelationshipManagerMiddleName?: string;
	RelationshipManagerLastName?: string;
	RelationshipManagerBirthDate?: Date;
	RelationshipManagerBirthPlace?: string;
	RelationshipManagerGender?: ENUM_GENDER_TYPE;
	RelationshipManagerTelephone?: string;
	RelationshipManagerMobilePhone?: string;
	RelationshipManagerEmail?: string;

	RelationshipManagerIdentificationNumber?: string;
	RelationshipManagerIdentificationType?: ENUM_IDENTIFICATION_TYPE;

	RelationshipManagerAddress?: string;
	RelationshipManagerCountryID?: number;
	RelationshipManagerCountryCode?: number;
	RelationshipManagerCountryName?: number;
	RelationshipManagerProvinceID?: number;
	RelationshipManagerProvinceCode?: number;
	RelationshipManagerProvinceName?: number;
	RelationshipManagerCityID?: number;
	RelationshipManagerCityCode?: number;
	RelationshipManagerCityName?: number;
	RelationshipManagerSubDistrictID?: number;
	RelationshipManagerVillageID?: number;
	RelationshipManagerHamletCode?: string;
	RelationshipManagerNeighbourhoodCode?: string;
	RelationshipManagerPostalID?: number;
	RelationshipManagerPostalCode?: number;
	RelationshipManagerPostalName?: number;
	RelationshipManagerLatitude?: number;
	RelationshipManagerLongitude?: number;

	/* RELATIONSHIP MANAGER - END */

	/* POLICYHOLDER - START */

	PolicyholderFirstName?: string;
	PolicyholderMiddleName?: string;
	PolicyholderLastName?: string;
	PolicyholderFullName?: string;
	PolicyholderBirthDate?: Date;
	PolicyholderBirthPlace?: string;
	PolicyholderGender?: ENUM_GENDER_TYPE;
	PolicyholderTelephone?: string;
	PolicyholderMobilePhone?: string;
	PolicyholderEmail?: string;

	PolicyholderIdentificationNumber?: string;
	PolicyholderIdentificationType?: ENUM_IDENTIFICATION_TYPE;

	PolicyholderAddress?: string;
	PolicyholderCountryID?: number;
	PolicyholderCountryCode?: number;
	PolicyholderCountryName?: number;
	PolicyholderProvinceID?: number;
	PolicyholderProvinceCode?: number;
	PolicyholderProvinceName?: number;
	PolicyholderCityID?: number;
	PolicyholderCityCode?: number;
	PolicyholderCityName?: number;
	PolicyholderSubDistrictID?: number;
	PolicyholderVillageID?: number;
	PolicyholderHamletCode?: string;
	PolicyholderNeighbourhoodCode?: string;
	PolicyholderPostalID?: number;
	PolicyholderPostalCode?: number;
	PolicyholderPostalName?: number;
	PolicyholderLatitude?: number;
	PolicyholderLongitude?: number;

	/* POLICYHOLDER - END */


	/* INSURED - START */

	InsuredLicensePlate?: string;
	InsuredVehicleChasisNumber?: string;
	InsuredVehicleEngineNumber?: string;

	/* INSURED - END */


	/* PAYMENT - START */

	PremiumCurrency?: string;

	DiscountCurrency?: string;
	DiscountPercent?: string;

	CommissionCode?: string;
	CommissionPercent?: number;
	CommissionCurrency?: string;
	CommmisionAmount?: number;

	StampAmount?: number;

	InvoiceURL?: string;
	InvoiceExpiredDate?: Date;
	InvoiceID?: string;
	PaymentAccountID?: number;

	/* PAYMENT - END */

	/* TRACKING - START */

	SubmittedBy?: number;
	SubmittedOn?: Date;
	SurveyedBy?: number;
	SurveyedOn?: Date;
	ReviewedBy?: number;
	ReviewedOn?: Date;
	PaidBy?: number;
	PaidOn?: Date;
	CompletedBy?: number;
	CompletedOn?: Date;
	PolicyStartDate?: Date;
	PolicyEndDate?: Date;
	InforceDate?: Date;
	BookingDate?: Date;
	EffectiveDate?: Date;
	PolicyDueDate?: Date;

	/* TRACKING - END */

	/* VIRTUAL ACCOUNT - START */

	VirtualAccountID?: number;
	VirtualAccountType?: ENUM_VIRTUALACCOUNT_TYPE;
	VirtualAccountName?: string;
	VirtualAccountExpiredDate?: Date;
	VirtualAccountActiveDate?: Date;
	VirtualAccountNumber?: string;
	BRIVANumber?: string;

	/* VIRTUAL ACCOUNT - END */

	/* BANK - START */

	BankAccountID?: number;
	BankAccountHolder?: string;
	BankID?: number;
	BankCode?: string;
	BankName?: string;
	BankAccountNumber?: string;

	/* BANK - END */

	PaidStatus?: boolean;

	/* CARE TECH - START */

	ANO?: string;
	INO?: string;
	OANO?: string;
	BusinessSourceID?: string;
	AgentID?: string;
	AcceptanceType?: string;
	Remarks?: string;
	DetailState?: string;
	ExclusivePeriodFlag?: string;
	ProfileID?: string;
	AStatus?: string;

	/* CARE TECH - END */

	/* POLICY BRI - START */

	BRIPolicy?: boolean;
	COB?: string;

	/* POLICY BRI - END */

	/* NOT MAPPED - START */

	modelUser?: UserModel;
	modelPolicyAsri?: PolicyAsriModel;
	modelPolicyPersonalAccident?: PolicyPersonalAccidentModel;
	modelLead?: LeadModel;
	RenewalAge?: number;
	OutstandingAge?: number;
	listModelLetterHeader?: Array<LetterHeaderModel>;

	/* NOT MAPPED - END */

	/* Attribute - END */


	//#region GETTER

	getPolicyholderName(): string
	{
		if (this.PolicyholderFullName !== null && this.PolicyholderFullName !== undefined && this.PolicyholderFullName !== "")
		{
			return this.PolicyholderFullName;
		}
		else
		{
			let stringName: string = "";

			if (this.PolicyholderFirstName == null || this.PolicyholderFirstName === undefined || this.PolicyholderFirstName === "")
			{
				stringName += StringConstant.STRING_CHARACTER_EMPTY;
			}
			else
			{
				stringName += StringConstant.STRING_CHARACTER_DASH + this.PolicyholderFirstName;
			}

			if (this.PolicyholderMiddleName == null || this.PolicyholderMiddleName === undefined || this.PolicyholderMiddleName === "")
			{

			}
			else
			{
				stringName += StringConstant.STRING_CHARACTER_DASH + this.PolicyholderMiddleName;
			}

			if (this.PolicyholderLastName == null || this.PolicyholderLastName === undefined || this.PolicyholderLastName === "")
			{

			}
			else
			{
				stringName += StringConstant.STRING_CHARACTER_DASH + this.PolicyholderLastName;
			}

			return stringName;
		}
	}

	getPolicyholderFirstName(): string
	{
		if(this.PolicyholderFirstName === null || this.PolicyholderFirstName === undefined || this.PolicyholderFirstName === "")
		{
			return "";
		}
		else
		{
			return this.PolicyholderFirstName;
		}
	}

	getPolicyholderMiddleName(): string
	{
		if(this.PolicyholderMiddleName === null || this.PolicyholderMiddleName === undefined || this.PolicyholderMiddleName === "")
		{
			return "";
		}
		else
		{
			return this.PolicyholderMiddleName;
		}
	}

	getPolicyholderLastName(): string
	{
		if(this.PolicyholderLastName === null || this.PolicyholderLastName === undefined || this.PolicyholderLastName === "")
		{
			return "";
		}
		else
		{
			return this.PolicyholderLastName;
		}
	}

	//#endregion

	//#region FUNCTION

	validatePolicyNumber(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		const regExpNumeric: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_NUMERIC);
		const regExpAphabetNumeric: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABETNUMERIC);
		modelResponse.setForValidation("Policy General");


		if ( this.PolicyNumber === null || this.PolicyNumber === undefined || this.PolicyNumber === "" )
		{
			modelResponse.MessageContent = "Policy number can’t be empty, please fill this field.";
		}
		else if ( this.Certificate )
		{
			if(this.InsuredVehicleEngineNumber === null || this.InsuredVehicleEngineNumber === undefined || this.InsuredVehicleEngineNumber === "")
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Warning ;
				modelResponse.MessageContent = "Engine number can’t be empty, please fill this field.";
			}
			else if (this.InsuredVehicleEngineNumber.length < RuleConstant.NUMBER_ENGINE_NUMBER_LENGTHMINIMUM)
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Warning ;
				modelResponse.MessageContent = "Engine number minimum contain "+RuleConstant.NUMBER_ENGINE_NUMBER_LENGTHMINIMUM+" digits number.";
			}
			else if (this.InsuredVehicleEngineNumber.length > RuleConstant.NUMBER_ENGINE_NUMBER_LENGTHMAXIMUM)
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Warning ;
				modelResponse.MessageContent = "Engine number maximum contain "+RuleConstant.NUMBER_ENGINE_NUMBER_LENGTHMAXIMUM+" digits number.";
			}
			else if (!regExpAphabetNumeric.test(this.InsuredVehicleEngineNumber))
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Warning ;
				modelResponse.MessageContent = "Engine number format incorrect.";
			}
			else
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success ;
			}

		}
		else
		{
			if (this.PolicyNumber.length !== RuleConstant.NUMBER_POLICY_NUMBER_LENGTH)
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Warning ;
				modelResponse.MessageContent = "Policynumber must be contain 16 digit numbers.";
			}
			else if (!regExpNumeric.test(this.PolicyNumber))
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Warning ;
				modelResponse.MessageContent = "Policy number format incorrect.";
			}
			else
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success ;
			}
		}
		return modelResponse;
	}

	validateAcceptanceNumber(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.MessageTitle = "Policy General";
		const regExpNumeric: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_NUMERIC);

		if (this.ANO === null || this.ANO === undefined || this.ANO === "")
		{
			modelResponse.MessageContent = "Acceptance Number Empty";
		}
		else
		{
			if (this.ANO.length < NumberConstant.NUMBER_POLICY_ACCEPTANCENUMBER_LENGTHMINIMUM)
			{
				modelResponse.MessageContent = "Acceptance Number length less than "+NumberConstant.NUMBER_POLICY_ACCEPTANCENUMBER_LENGTHMINIMUM;
			}
			else if (this.ANO.length > NumberConstant.NUMBER_POLICY_ACCEPTANCENUMBER_LENGTHMAXIMUM)
			{
				modelResponse.MessageContent = "Acceptance Number length more than "+NumberConstant.NUMBER_POLICY_ACCEPTANCENUMBER_LENGTHMINIMUM;
			}
			else if (!regExpNumeric.test(this.ANO))
			{
				modelResponse.MessageContent = "Acceptance Number format incorrect";
			}
			else
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}

		return modelResponse;
	}

	validateSearchPolicyNumberWithCertificate(): boolean
	{
		if( this.PolicyNumber === null || this.PolicyNumber === undefined || this.PolicyNumber === "" || this.InsuredVehicleEngineNumber === null || this.InsuredVehicleEngineNumber === undefined || this.InsuredVehicleEngineNumber === "")
		{
			return false;
		}
		else
		{
			return true;
		}
	}

	setBooleanToStringActive(booleanState): string
	{
		if(booleanState)
		{
			return "Active";
		}
		else
		{
			return "Non Active";
		}
	}

	setBooleanToStringPayment(booleanState): string
	{
		if(booleanState)
		{
			return "Paid";
		}
		else
		{
			return "Unpaid";
		}
	}

	setBooleanToStringPeriode(booleanState): string
	{
		if(booleanState)
		{
			return "On Periode";
		}
		else
		{
			return "Off Periode";
		}
	}

	setBooleanToStringGracePeriode(booleanState): string
	{
		if(booleanState)
		{
			return "Yes";
		}
		else
		{
			return "No";
		}
	}

	checkPolicyNumberWithCertificateNumber(): string
	{
		let stringPolicyNumberWithCertificateNumber: string = "";
		if(this.PolicyNumber === null || this.PolicyNumber === undefined || this.PolicyNumber === "")
		{

		}
		else if(this.CertificateNumber === null || this.CertificateNumber === undefined || this.CertificateNumber === "")
		{
			stringPolicyNumberWithCertificateNumber = this.PolicyNumber;
		}
		else
		{
			stringPolicyNumberWithCertificateNumber = this.PolicyNumber+" ("+this.CertificateNumber+")";
		}
		return stringPolicyNumberWithCertificateNumber;
	}

	checkCertificate(): boolean
	{
		if(this.CertificateNumber === null || this.CertificateNumber === undefined || this.CertificateNumber === "")
		{
			return false;
		}
		else
		{
			return true;
		}
	}

	setValidateCheckPolicyNumber(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Policy General");


		if(this.PolicyNumber == null || this.PolicyNumber === undefined || this.PolicyNumber === "")
		{
			modelResponse.MessageContent = "Policy number tidak boleh kosong";
		}
		else if(this.PolicyholderIdentificationNumber == null || this.PolicyholderIdentificationNumber === undefined || this.PolicyholderIdentificationNumber === "")
		{
			modelResponse.MessageContent = "NIK tidak boleh kosong";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	setInterfaceClaim(): boolean
	{
		//Start - Hotfix Production MV

		this.COB = this.ProductCode?.slice(0, 2);

		//Start - Hotfix Production MV

		if(this.COB == null || this.COB === undefined || this.COB === "")
		{
			return false;
		}
		else
		{
			if(this.COB !== StringConstant.STRING_COB_MOTOVEHICLE)
			{
				return false;
			}
			else
			{
				return true;
			}
		}
	}

	//#endregion
}

//#endregion
