//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ProductGeneralModel } from "../models/productgeneral.model";
import { ProductBaseGalleryModel } from "../models/bases/productbasegallery.model";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class ProductgeneralService extends BaseService
{
	public _modelProductGeneral: Observable<ProductGeneralModel>;
	public _modelProductBaseGallery: Observable<ProductBaseGalleryModel>;

	private _behaviourSubjectProductGeneralModel: BehaviorSubject<ProductGeneralModel>;
	private _behaviourSubjectProductBaseGallery: BehaviorSubject<ProductBaseGalleryModel>;


	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);

		this._behaviourSubjectProductGeneralModel = new BehaviorSubject(new ProductGeneralModel());
		this._modelProductGeneral = this._behaviourSubjectProductGeneralModel.asObservable();

		this._behaviourSubjectProductBaseGallery = new BehaviorSubject(new ProductBaseGalleryModel());
		this._modelProductBaseGallery = this._behaviourSubjectProductBaseGallery.asObservable();
	}

	//#endregion


	//#region SETTER

	setModelProductGeneral(modelProductGeneral: ProductGeneralModel)
	{
		this._behaviourSubjectProductGeneralModel.next(modelProductGeneral);
	}

	setModelProductBaseGallery(modelBaseGallery: ProductBaseGalleryModel)
	{
		this._behaviourSubjectProductBaseGallery.next(modelBaseGallery);
	}

	//#endregion


	//#region SERVICE

	selectProductGeneralReferenceNumberByToken(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTGENERAL_SELECTPRODUCTGENERALREFERANCENUMBERBYTOKEN);
	}

	//#endregion

}

//#endregion
