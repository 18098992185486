//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { ClaimProposalModel } from "../models/claimproposal.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//#endregion


//#region INJECTABLE
@Injectable
(
	{
		providedIn: "root"
	}
)

//#region CLASS

export class ClaimProposalService extends BaseService
{

	//#region CONSTRUCTOR

	public _modelClaimProposalForObserve: Observable<ClaimProposalModel>;
	private _behaviourSubjectClaimProposalModel: BehaviorSubject<ClaimProposalModel>;

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);

		this._behaviourSubjectClaimProposalModel = new BehaviorSubject(new ClaimProposalModel());
		this._modelClaimProposalForObserve = this._behaviourSubjectClaimProposalModel.asObservable();
	}

	setDamageType(modelClaimProposal: ClaimProposalModel)
	{
		this._behaviourSubjectClaimProposalModel.next(modelClaimProposal);
	}

	//#endregion


	//#region INSERT

	insertClaimProposalForCustomer(interfaceGeneralService: GeneralServiceInterface, modelClaimProposal: ClaimProposalModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelClaimProposal), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_INSERTCLAIMPROPOSALFORCUSTOMER);
	}

	insertClaimProposalNonMotorVehicleForCustomer(interfaceGeneralService: GeneralServiceInterface, modelClaimProposal: ClaimProposalModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelClaimProposal), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_INSERTCLAIMPROPOSALNONMOTORVEHICLEFORCUSTOMER);
	}

	//#endregion


	//#region UPLOAD

	uploadClaimProposalForCustomerPublic(interfaceGeneralService: GeneralServiceInterface, modelClaimProposal: ClaimProposalModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelClaimProposal), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_UPLOADCLAIMPROPOSALFORCUSTOMERPUBLIC);
	}

	//#endregion
}
