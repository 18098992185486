//#region IMPORT

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { StringConstant } from "../constants/string.constant";
import { PublicGuardService } from "../services/guards/public.guard.service";
import { PaymentComponent } from "./payment/payment.component";
import { ClaimComponent } from "./claim/claim/claim.component";

//#endregion


//#region DECLARATION

const arrayRoute: Routes =
[
	{
		path: "",
		redirectTo: "/",
		pathMatch: "full"
	},
	{
		path: "product",
		loadChildren:() => import("./product/product.module").then(pageModule => pageModule.ProductModule),
		canActivate: [PublicGuardService]
	},
	{
		path: "payment/:" + StringConstant.STRING_ROUTING_PRODUCT + "/:" + StringConstant.STRING_ROUTING_KEY_TOKEN_PRODUCT + "/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: PaymentComponent,
		canActivate: [PublicGuardService]
	},
	{
		path: "claim",
		component: ClaimComponent,
		canActivate: [PublicGuardService]
	},
];

//#endregion


//#region MODULE

@NgModule
(
	{
		imports: [RouterModule.forRoot(arrayRoute)],
		exports: [RouterModule]
	}
)

//#endregion


//#region CLASS

export class AppRoutingModule { }

//#endregion
