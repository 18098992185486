//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { ProductGeneralModel } from "../models/productgeneral.model";
import { ProductOtoModel } from "../models/productoto.model";
import { PremiumModel } from "../models/premium.model";
import { ProductOtoGalleryModel } from "../models/productotogallery.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class ProductotoService extends BaseService
{

	public _modelProductOto: Observable<ProductOtoModel>;
	public _modelProductGeneral: Observable<ProductGeneralModel>;
	public _modelPremium: Observable<PremiumModel>;
	public _modelProductOtoGallery: Observable<ProductOtoGalleryModel>;

	public _stringDataBase64: Observable<any>;

	private _behaviourSubjectProductOtoModel: BehaviorSubject<ProductOtoModel>;
	private _behaviourSubjectProductGeneralModel: BehaviorSubject<ProductGeneralModel>;
	private _behaviourSubjectPremiumModel: BehaviorSubject<PremiumModel>;
	private _behaviourSubjectProductOtoGalleryModel: BehaviorSubject<ProductOtoGalleryModel>;

	private _behaviourSubjectDataBase64: BehaviorSubject<any>;



	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);

		this._behaviourSubjectProductOtoModel = new BehaviorSubject(new ProductOtoModel());
		this._modelProductOto = this._behaviourSubjectProductOtoModel.asObservable();

		this._behaviourSubjectProductGeneralModel = new BehaviorSubject(new ProductGeneralModel());
		this._modelProductGeneral = this._behaviourSubjectProductGeneralModel.asObservable();

		this._behaviourSubjectPremiumModel = new BehaviorSubject(new PremiumModel());
		this._modelPremium = this._behaviourSubjectPremiumModel.asObservable();

		this._behaviourSubjectProductOtoGalleryModel = new BehaviorSubject(new ProductOtoGalleryModel());
		this._modelProductOtoGallery = this._behaviourSubjectProductOtoGalleryModel.asObservable();

		this._behaviourSubjectDataBase64 = new BehaviorSubject(null);
		this._stringDataBase64 = this._behaviourSubjectDataBase64.asObservable();
	}

	//#endregion


	//#region SETTER

	setProductCode(modelProductOto: ProductOtoModel)
	{
		this._behaviourSubjectProductOtoModel.next(modelProductOto);
	}

	setModelProductGeneral(modelProductGeneral: ProductGeneralModel)
	{
		this._behaviourSubjectProductGeneralModel.next(modelProductGeneral);
	}

	setModelProductGeneralPolicy(modelProductGeneral: ProductGeneralModel)
	{
		this._behaviourSubjectProductGeneralModel.next(modelProductGeneral);
	}

	setModelPremium(modelPemium: PremiumModel)
	{
		this._behaviourSubjectPremiumModel.next(modelPemium);
	}

	setDataBase64(stringData: any)
	{
		this._behaviourSubjectDataBase64.next(stringData);
	}

	setModelProductOtoGallery(modelProductOtoGallery: ProductOtoGalleryModel)
	{
		this._behaviourSubjectProductOtoGalleryModel.next(modelProductOtoGallery);
	}

	//#endregion


	//#region SELECT

	calculatePremiumOtoForSelfServiceMonitoring(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTOTO_SELECTCALCULATEPREMIUMOTOFORSELFSERVICEMONITORING);
	}

	insertProductOtoForSelfServiceMonitoring(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTOTO_INSERTPRODUCTOTOFORSELFSERVICEMONITORING);
	}

	updateInsuredForSelfServiceMonitoring(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTOTO_UPDATEINSUREDOTOFORSELFSERVICEMONITORING);
	}

	uploadProductOtoForSelfServiceMonitoring(interfaceGeneralService: GeneralServiceInterface, modelProductOto: ProductOtoModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductOto), WebAddressConstant.STRING_URL_PRODUCTOTO_UPLOADPRODUCTOTOFORSELFSERVICEMONITORING);
	}

	generatePaymentForSelfServiceMonitoring(interfaceGeneralService: GeneralServiceInterface, modelProducGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProducGeneral), WebAddressConstant.STRING_URL_PRODUCTOTO_GENERATEPAYMENTFORBRIVA);
	}

	sharePayementForSelfServiceMonitoring(interfaceGeneralService: GeneralServiceInterface, modelProducGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProducGeneral), WebAddressConstant.STRING_URL_PRODUCTOTO_SHAREPAYMENTFORSELFSERVICEMONITORING);
	}

	saveProductOtoForSelfServiceMonitoring(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTOTO_SAVEPRODUCTOTOFORSELFSERVICEMONITORING);
	}

	sendSPPAByEmailOtoForSelfServiceMonitoring(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTOTO_SENDSPPABYEMAILOTOFORSELFSERVICEMONITORING);
	}

	confirmPaymentForSelfServiceMonitoring(interfaceGeneralService: GeneralServiceInterface, modelProductOto: ProductOtoModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductOto), WebAddressConstant.STRING_URL_PRODUCTOTO_CONFIRMPAYMENTOTOFORSELFSERVICEMONITORING);
	}

	selectProductByTokenForSelfServiceMonitoring(interfaceGeneralService: GeneralServiceInterface, modelProductOto: ProductOtoModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductOto), WebAddressConstant.STRING_URL_PRODUCTOTO_SELECTPRODUCTBYTOKENFORSELFSERVICEMONITORING);
	}

	//#endregion

}

//#endregion
