//#region IMPORT

import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { StringConstant } from "../constants/string.constant";
import { BaseModel } from "../models/bases/base.model";
import { CustomerModel } from "../models/customer.model";
import { HandshakeModel } from "../models/handshake.model";
import { UserModel } from "../models/user.model";
import { UserSessionModel } from "../models/usersession.model";
//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class SessionService
{
	//#region DECLARATION

	public _modelUserSignIn: Observable<UserModel>;
	public _modelCustomerSignIn: Observable<CustomerModel>;
	public _modelHandshakeSignIn: Observable<HandshakeModel>;
	public _modelProductGenaral: Observable<BaseModel>;

	private _behaviourSubjectModelUser: BehaviorSubject<UserModel>;
	private _behaviourSubjectModelCustomer: BehaviorSubject<CustomerModel>;
	private _behaviourSubjectModelHandshake: BehaviorSubject<HandshakeModel>;
	private _behaviourSubjectModelBaseModel: BehaviorSubject<BaseModel>;

	//#endregion


	//#region CONSTRUCTOR

	constructor()
	{
		this._behaviourSubjectModelUser = new BehaviorSubject(new UserModel());
		this._behaviourSubjectModelCustomer = new BehaviorSubject(new CustomerModel());
		this._behaviourSubjectModelHandshake = new BehaviorSubject(new HandshakeModel());
		this._behaviourSubjectModelBaseModel = new BehaviorSubject(new BaseModel());

		this._modelUserSignIn = this._behaviourSubjectModelUser.asObservable();
		this._modelCustomerSignIn = this._behaviourSubjectModelCustomer.asObservable();
		this._modelHandshakeSignIn = this._behaviourSubjectModelHandshake.asObservable();
		this._modelProductGenaral = this._behaviourSubjectModelBaseModel.asObservable();
	}

	//#endregion


	//#region SETTER

	setModelUserSignIn(modelUser: UserModel): void
	{
		this._behaviourSubjectModelUser.next(modelUser);
	}

	setModelCustomerSignIn(modelCustomer: CustomerModel): void
	{
		this._behaviourSubjectModelCustomer.next(modelCustomer);
	}

	setModelHandshakeSignIn(modelHandshake: HandshakeModel): void
	{
		this._behaviourSubjectModelHandshake.next(modelHandshake);
	}

	setModelBasse(modelBase: BaseModel): void
	{
		this._behaviourSubjectModelBaseModel.next(modelBase);
	}

	setModelHandshakeSignInToLocalStorage(modelHandshake: HandshakeModel): void
	{
		this.setModelHandshakeSignIn(modelHandshake);

		const stringModelHandshake: string = btoa(JSON.stringify(modelHandshake));
		let arrayStringModelHandshake: Array<string> = stringModelHandshake.split("");
		arrayStringModelHandshake = arrayStringModelHandshake.reverse();
		localStorage.setItem(StringConstant.STRING_LOCALSTORAGE_KEY_HANDSHAKEMODEL, arrayStringModelHandshake.join(""));
	}

	private setUserSessionToLocalStorage(stringLocalStorageKey: string, modelUserSessionContent: UserSessionModel): void
	{
		const stringUserSessionContent: string = btoa(JSON.stringify(modelUserSessionContent));
		let arrayStringUserSessionContent: Array<string> = stringUserSessionContent.split("");
		arrayStringUserSessionContent = arrayStringUserSessionContent.reverse();
		localStorage.setItem(stringLocalStorageKey, arrayStringUserSessionContent.join(""));
	}

	setUserSession(modelUserSession: UserSessionModel): void
	{
		this.setUserSessionToLocalStorage(StringConstant.STRING_LOCALSTORAGE_KEY_USERSESSION, modelUserSession);
	}


	private setModelBaseToLocalStorage(stringLocalStorageKey: string, modelProductGeneral: BaseModel): void
	{
		this.setModelBasse(modelProductGeneral);

		const stringModelProductGeneral: string = btoa(JSON.stringify(modelProductGeneral));
		let arrayStringModelProductGeneral: Array<string> = stringModelProductGeneral.split("");
		arrayStringModelProductGeneral = arrayStringModelProductGeneral.reverse();
		localStorage.setItem(stringLocalStorageKey, arrayStringModelProductGeneral.join(""));
	}

	setModelBase(modelProductGeneral: BaseModel | any): void
	{
		this.setModelBaseToLocalStorage(StringConstant.STRING_LOCALSTORAGE_KEY_BASEMODEL, modelProductGeneral);
	}


	//#endregion


	//#region GETTER

	getModelHandshakeSignInFromLocalStorage(): HandshakeModel | null
	{
		try
		{
			const stringModelHandshake: string | null = localStorage.getItem(StringConstant.STRING_LOCALSTORAGE_KEY_HANDSHAKEMODEL);

			if (stringModelHandshake != null && stringModelHandshake.validateEmpty())
			{
				let arrayStringModelHandshake: Array<string> = stringModelHandshake.split("");
				arrayStringModelHandshake = arrayStringModelHandshake.reverse();

				const modelHandshake: HandshakeModel = new HandshakeModel();
				modelHandshake.setModelFromString(atob(arrayStringModelHandshake.join("")));

				return modelHandshake;
			}
			else
			{
				// jika sudah dapat token kenapa error null jadi descript di console
				return null;
			}
		}
		catch (error)
		{
			return null;
		}
	}


	private getUserSessionFromLocalStorage(stringLocalStorageKey: string): UserSessionModel | null
	{
		try
		{
			const stringUserSession: string | null = localStorage.getItem(stringLocalStorageKey);

			if (stringUserSession != null && stringUserSession.validateEmpty())
			{
				let arrayStringUserSession: Array<string> = stringUserSession.split("");
				arrayStringUserSession = arrayStringUserSession.reverse();

				const modelUserSession: UserSessionModel = new UserSessionModel();
				modelUserSession.setModelFromString(atob(arrayStringUserSession.join("")));

				return modelUserSession;
			}
			else
			{
				return null;
			}
		}
		catch (error)
		{
			return null;
		}
	}


	private getModelBaseFromLocalStorage(stringLocalStorageKey: string): BaseModel | null
	{
		try
		{
			const stringModelBase: string | null = localStorage.getItem(stringLocalStorageKey);

			if (stringModelBase != null && stringModelBase.validateEmpty())
			{
				let arrayStringModelProductGeneral: Array<string> = stringModelBase.split("");
				arrayStringModelProductGeneral = arrayStringModelProductGeneral.reverse();

				const modelBase: BaseModel = new BaseModel();
				modelBase.setModelFromString(atob(arrayStringModelProductGeneral.join("")));

				return modelBase;
			}
			else
			{
				return null;
			}
		}
		catch (error)
		{
			return null;
		}
	}

	getUserSession(): UserSessionModel | null
	{
		return this.getUserSessionFromLocalStorage(StringConstant.STRING_LOCALSTORAGE_KEY_USERSESSION);
	}

	getModelBase(): BaseModel | null
	{
		return this.getModelBaseFromLocalStorage(StringConstant.STRING_LOCALSTORAGE_KEY_BASEMODEL);
	}

	//#endregion


	//#region CLEAR

	clearSession(): void
	{
		this._behaviourSubjectModelUser.next(new UserModel());
		this._behaviourSubjectModelCustomer.next(new CustomerModel());
		this._behaviourSubjectModelHandshake.next(new HandshakeModel());
		this._behaviourSubjectModelBaseModel.next(new BaseModel());
		this.clearSessionQuery();
	}

	clearKey(): void
	{
		localStorage.removeItem(StringConstant.STRING_LOCALSTORAGE_KEY_HANDSHAKEMODEL);
		localStorage.removeItem(StringConstant.STRING_LOCALSTORAGE_KEY_USERSESSION);
		localStorage.removeItem(StringConstant.STRING_LOCALSTORAGE_KEY_BASEMODEL);
	}

	clearSessionQuery(): void
	{
		this.clearUserSession();
	}

	clearUserSession(): void
	{
		localStorage.removeItem(StringConstant.STRING_LOCALSTORAGE_KEY_USERSESSION);
	}


	//#endregion
}

//#endregion