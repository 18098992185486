//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { ProductGeneralModel } from "../models/productgeneral.model";
import { ProductPersonalAccidentModel } from "../models/productpersonalaccident.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class ProductpersonalaccidentService extends BaseService
{
	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);
	}

	//#endregion


	//#region SELECT

	calculatePremiumPersonalAccidentForSelfServiceMonitoring(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTPERSONALACCIDENT_SELECTCALCULATEPREMIUMPERSONALACCIDENTFORSELFSERVICEMONITORING);
	}

	insertProductPersonalAccidentForSelfServiceMonitoring(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTPERSONALACCIDENT_INSERTPRODUCTPERSONALACCIDENTFORSELFSERVICEMONITORING);
	}

	updateInsuredForSelfServiceMonitoring(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTPERSONALACCIDENT_UPDATEINSUREDPERSONALACCIDENTFORSELFSERVICEMONITORING);
	}

	updateBeneficiariesForSelfServiceMonitoring(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTPERSONALACCIDENT_UPDATEBENEFICIARIESPERSONALACCIDENTFORSELFSERVICEMONITORING);
	}

	updateQuestionnaireForSelfServiceMonitoring(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTPERSONALACCIDENT_UPDATEQUESTIONNAIREPERSONALACCIDENTFORSELFSERVICEMONITORING);
	}

	generatePaymentForSelfServiceMonitoring(interfaceGeneralService: GeneralServiceInterface, modelProducGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProducGeneral), WebAddressConstant.STRING_URL_PRODUCTPERSONALACCIDENT_GENERATEPAYMENTFORBRIVA);
	}

	sharePayementForSelfServiceMonitoring(interfaceGeneralService: GeneralServiceInterface, modelProducGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProducGeneral), WebAddressConstant.STRING_URL_PRODUCTPERSONALACCIDENT_SHAREPAYMENTFORSELFSERVICEMONITORING);
	}

	saveProductPersonalAccidentForSelfServiceMonitoring(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTPERSONALACCIDENT_SAVEPRODUCTPERSONALACCIDENTFORSELFSERVICEMONITORING);
	}

	sendSPPAByEmailPersonalAccidentForSelfServiceMonitoring(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTPERSONALACCIDENT_SENDSPPABYEMAILPERSONALACCIDENTFORSELFSERVICEMONITORING);
	}

	confirmPaymentForSelfServiceMonitoring(interfaceGeneralService: GeneralServiceInterface, modelProductPersonalAccident: ProductPersonalAccidentModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductPersonalAccident), WebAddressConstant.STRING_URL_PRODUCTPERSONALACCIDENT_CONFIRMPAYMENTPERSONALACCIDENTFORSELFSERVICEMONITORING);
	}

	selectProductByTokenForSelfServiceMonitoring(interfaceGeneralService: GeneralServiceInterface, modelProductPersonalAccident: ProductPersonalAccidentModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductPersonalAccident), WebAddressConstant.STRING_URL_PRODUCTPERSONALACCIDENT_SELECTPRODUCTBYTOKENFORSELFSERVICEMONITORING);
	}

	//#endregion
}

//#endregion
